import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { MediaService } from "../../../../../services";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../managers/utility";

const MediaModal = ({
  isMediaModalOpen,
  refetch,
  selectedItem,
  setIsMediaModalOpen,
  dataList,
}) => {
  const [inputData, setInputData] = useState({
    thumbnail: "",
    title: "",
    url: "",
  });

  const closeMediaModal = () => {
    setIsMediaModalOpen(false);
    // setInputData({
    //   thumbnail: "",
    //   title: "",
    //   url: "",
    // });
  };

  useEffect(() => {
    if (selectedItem) {
      const { categoryId, ...rest } = selectedItem;
      setInputData({ ...rest, categoryId: categoryId?._id });
    }
  }, [selectedItem]);

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const submitData = async () => {
    try {
      const requestData = { ...inputData, categoryId: dataList?.category?._id };
      const res = selectedItem
        ? await MediaService.updateMedia(requestData)
        : await MediaService.addMedia(requestData);

      if (res._id) {
        showSuccessMessage(selectedItem ? "Saved Successfully" : "Updated Successfully");
        closeMediaModal();
        refetch();
      }
    } catch (error) {
      showErrorMessage("Error occurred while saving data.");
    }
  };

  const deleteMedia = async () => {
    try {
      if (selectedItem) {
        const requestData = { ...inputData };
        const res = await MediaService.deleteMedia(requestData);

        if (res._id) {
          showSuccessMessage("Deleted Successfully");
          closeMediaModal();
          refetch();
        }
      }
    } catch (error) {
      showErrorMessage("Error occurred while deleting data.");
    }
  };

  return (
    <Modal
      open={isMediaModalOpen}
      onClose={closeMediaModal}
      aria-labelledby="modal-modal-title-lots"
      aria-describedby="modal-modal-description-lots"
    >
      <Box
        sx={{
          maxHeight: 500,
          overflow: "scroll",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 900,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "3px dotted cfcfcf",
          }}
        >
          <Typography variant="h6" component="h2">
            Add
          </Typography>

          <div>
            <Button onClick={submitData} style={{ marginRight: 10 }}>
              Save
            </Button>
            {selectedItem && (
              <Button onClick={deleteMedia}>Delete</Button>
            )}
          </div>
          <Button
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={closeMediaModal}
          >
            <CloseIcon />
          </Button>
        </div>

        <TextField
          label="Title"
          variant="outlined"
          value={inputData.title}
          onChange={handleChange}
          name="title"
          fullWidth
          margin="normal"
          sx={{ mt: 2 }}
        />
        <TextField
          label="URL"
          variant="outlined"
          value={inputData.url}
          onChange={handleChange}
          name="url"
          fullWidth
          margin="normal"
          sx={{ mt: 2 }}
        />
      </Box>
    </Modal>
  );
};

export default MediaModal;
