import React, { useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate, useParams } from "react-router-dom";
import { AuthUserService } from "../../services";
import { showErrorMessage, showSuccessMessage } from "../../managers/utility";
import { useAuth } from "../../AuthContext";
import CustomLoader from "../../common/customLoader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
export default function VerifiedUser() {
  const [emailVerified, setEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { userDetails } = useAuth();

  let params = useParams();

  const isVerifyEmail = async () => {
    try {
      const response = await AuthUserService.GetVerifyUser({
        emailVerificationCode: params.verificationcode,
      });
      if (response && response.user && response.user._id) {
        setTimeout(() => {
          navigate("/");
        }, 3000);
        setEmailVerified(true);
        showSuccessMessage("Your email has been verified");
      } else {
        showErrorMessage(response.message);
        setEmailVerified(false);
      }
      setIsLoading(false);
    } catch (e) {
      showErrorMessage(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isVerifyEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <div style={styles.container}>
      {isLoading && (
        <div style={styles.loaderContainer}>
          <CustomLoader />
        </div>
      )}
      {emailVerified && (
        <div style={styles.card}>
          <CheckCircleIcon style={styles.VerifiedIcon} />
          <div style={styles.message}>
            <span style={{ fontSize: 24, marginTop: 10, marginBottom: 10 }}>
              Done
            </span>
            <br />
            <br />
            Your Email is Successfully Verified
            <br />
            <span style={{ color: "green", fontSize: 24, marginTop: 26 }}>
              Congratulations
            </span>
          </div>
        </div>
      )}
      {!emailVerified && (
        <div style={styles.card}>
          <ErrorOutlineIcon style={styles.icon} />
          <div style={styles.message}>
            <span style={{ fontSize: 24, marginTop: 10, marginBottom: 10 }}>
              Oops!
            </span>
            <br /> It seems like you've clicked on an invalid link. Please
            double-check the URL or try navigating back to the previous page.
            <br />
            If you believe this is an error, feel free to contact us for
            assistance. <br />
            <span style={{ color: "green", fontSize: 24, marginTop: 26 }}>
              Thank you!
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
  loaderContainer: {
    textAlign: "center",
  },
  card: {
    width: "50%",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    textAlign: "center",
  },
  icon: {
    fontSize: "48px",
    color: "#f44336",
  },
  message: {
    color: "#333",
    marginTop: 20,
    marginBottom: 20,
  },
  VerifiedIcon: {
    color: "green",
    fontSize: "48px",
  },
};
