import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Button, MenuItem, TextField } from "@mui/material";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../managers/utility";
import { PlanService } from "../../../../services";
import { Card, CardActions, CardContent } from "@mui/material";
import { MuiColorInput } from "mui-color-input";

export default function PlanModal({
  refetch,
  open,
  closePlanModal,
  plan,
  isEditable,
  handleEditPlan,
  createMode,
}) {
  const [data, setData] = useState({ ...plan });
  const [planDetails, setPlanDetails] = useState(data?.plansDetails || []);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setData({ ...plan });
    setPlanDetails(data?.plansDetails || []);
    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, open]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const closePlanModalData = () => {
    closePlanModal();
  };

  const handlePlanDetailsChange = (index, value) => {
    setPlanDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = value;
      return updatedDetails;
    });
  };

  const validateInputs = () => {
    const errors = {};

    if (!data.plansType) {
      errors.plansType = "Plan Type is required";
    }
    if (!data.sessionCount) {
      errors.sessionCount = "Number of Sessions is required";
    } else if (!/^\d+$/.test(data.sessionCount)) {
      errors.sessionCount = "Duration should contain only numbers";
    }
    if (!data.duration) {
      errors.duration = "Duration is required";
    } else if (!/^\d+$/.test(data.duration)) {
      errors.duration = "Duration should contain only numbers";
    }
    if (!data.durationType) {
      errors.durationType = "Duration Type is required";
    }
    if (!data.price) {
      errors.price = "Price is required";
    } else if (!/^\d+$/.test(data.price)) {
      errors.price = "Price should contain only numbers";
    }
    if (!data.plansTitle) {
      errors.plansTitle = "Plan Title is required";
    }
    if (!data.messageCount) {
      errors.messageCount = "Message Count is required";
    } else if (!/^\d+$/.test(data.messageCount)) {
      errors.messageCount = "Message Count should contain only numbers";
    }

    return errors;
  };

  const addRemoveRow = (action, index) => {
    if (action === "ADD") {
      setPlanDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails.splice(index + 1, 0, "");
        return updatedDetails;
      });
    } else if (action === "REMOVE") {
      if (planDetails.length > 1) {
        setPlanDetails((prevDetails) => {
          const updatedDetails = [...prevDetails];
          updatedDetails.splice(index, 1);
          return updatedDetails;
        });
      }
    }
  };

  const addPlans = async () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    try {
      const requestData = { ...data, plansDetails: planDetails };
      const response = await PlanService.addPlans(requestData);

      if (response && response._id) {
        refetch();
        showSuccessMessage("Plan Added Successfully");
        closePlanModal();
      } else {
        showErrorMessage("Unexpected error adding plan");
      }
    } catch (error) {
      throw new Error("Unexpected error. Please try again later.");
    }
  };

  const handleColorChange = (newValue) => {
    setData({ ...data, color: newValue });
  };

  const editPlans = async () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const requestData = { ...data, plansDetails: planDetails };
    const response = await PlanService.updatePlans(requestData);

    if (response && response._id) {
      refetch();
      showSuccessMessage("Plan Updated Successfully");
      closePlanModal();
    } else {
      showErrorMessage("Unexpected error Updating plan");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closePlanModalData}
        aria-labelledby="modal-modal-title-plans"
        aria-describedby="modal-modal-description-plans"
      >
        <Box
          sx={{
            display: "flex",
            maxHeight: 700,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            borderRadius: "8px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              width: "60%",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "50%",
                position: "fixed",
                justifyContent: "space-between",
                borderBottom: "3px dotted cfcfcf",
                backgroundColor: "rgba(255, 255, 255, 0.9)", // Opacity set to 90%
                backdropFilter: "blur(10px)", // Optional: adds a blur effect behind the transparent background
              }}
            >
              <Typography
                id="modal-modal-title-lots"
                variant="h6"
                component="h2"
              >
                Plans
              </Typography>
              <Button
                onClick={
                  isEditable
                    ? createMode
                      ? addPlans
                      : editPlans
                    : handleEditPlan
                }
              >
                {isEditable ? "Save" : "Edit"}
              </Button>
              <Button
                edge="end"
                color="inherit"
                aria-label="close"
                onClick={closePlanModalData}
              >
                <CloseIcon />
              </Button>
            </div>

            <div style={{ marginTop: "36px" }}>
              <div style={{ display: "flex" }}>
                <TextField
                  label="Plan Type"
                  variant="outlined"
                  disabled={!isEditable}
                  onChange={handleOnChange}
                  name="plansType"
                  fullWidth
                  margin="normal"
                  sx={{ mt: 2, mr: 1 }}
                  value={data?.plansType}
                  error={!!errors.plansType}
                  helperText={errors.plansType}
                />
                <MuiColorInput
                  sx={{ mt: 2, mr: 1 }}
                  value={data.color}
                  onChange={handleColorChange}
                  error={!!errors.color}
                  helperText={errors.color}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  label="Plan Title"
                  variant="outlined"
                  disabled={!isEditable}
                  onChange={handleOnChange}
                  name="plansTitle"
                  fullWidth
                  margin="normal"
                  sx={{ mt: 2, mr: 1 }}
                  value={data?.plansTitle}
                  error={!!errors.plansTitle}
                  helperText={errors.plansTitle}
                />
                <TextField
                  label="Number of Sessions"
                  variant="outlined"
                  disabled={!isEditable}
                  onChange={(e) => {
                    handleOnChange(e);
                    validateInputs(); // Trigger validation
                  }}
                  name="sessionCount"
                  fullWidth
                  margin="normal"
                  value={data?.sessionCount}
                  error={!!errors.sessionCount}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      { display: "none" },
                    "& input[type=number]": { MozAppearance: "textfield" },
                    mt: 2,
                    mr: 1,
                  }} // Set inputProps and type
                  helperText={errors.sessionCount}
                />
                <TextField
                  label="Price"
                  variant="outlined"
                  disabled={!isEditable}
                  onChange={(e) => {
                    handleOnChange(e);
                    validateInputs(); // Trigger validation
                  }}
                  name="price"
                  fullWidth
                  margin="normal"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      { display: "none" },
                    "& input[type=number]": { MozAppearance: "textfield" },
                    mt: 2,
                    mr: 1,
                  }} // Set inputProps and type
                  value={data?.price}
                  error={!!errors.price}
                  helperText={errors.price}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  label="Duration"
                  variant="outlined"
                  disabled={!isEditable}
                  onChange={(e) => {
                    handleOnChange(e);
                    validateInputs(); // Trigger validation
                  }}
                  name="duration"
                  fullWidth
                  margin="normal"
                  sx={{ mt: 2, mr: 1 }}
                  value={data?.duration}
                  error={!!errors.duration}
                  helperText={errors.duration}
                  InputProps={{
                    inputProps: { min: "0", style: { textAlign: "left" } },
                    type: "number",
                  }} // Set inputProps and type
                />
                <TextField
                  id="outlined-basic-gender"
                  label="Duration Type"
                  variant="outlined"
                  select
                  name="durationType"
                  error={!!errors.durationType}
                  helperText={errors.durationType}
                  value={data?.durationType || ""}
                  onChange={handleOnChange}
                  fullWidth
                  margin="normal"
                  sx={{ mt: 2, mr: 1 }}
                >
                  <MenuItem value="DAY">Day</MenuItem>
                  <MenuItem value="MONTH">Month</MenuItem>
                  <MenuItem value="YEAR">Year</MenuItem>
                </TextField>
              </div>

              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "5px",
                  marginTop: "5px",
                  boxShadow: "0px 2px 4px rgba(1, 1 ,1, 0.6)", // Added shadow
                  marginRight: "8px",
                }}
              >
                Only Backend Purpose
                <TextField
                  label="Message Count"
                  variant="outlined"
                  disabled={!isEditable}
                  onChange={(e) => {
                    handleOnChange(e);
                    validateInputs(); // Trigger validation
                  }}
                  name="messageCount"
                  fullWidth
                  margin="normal"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      { display: "none" },
                    "& input[type=number]": { MozAppearance: "textfield" },
                    mt: 2,
                  }} // Set inputProps and type
                  value={data?.messageCount}
                  error={!!errors.messageCount}
                  helperText={errors.messageCount}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  marginTop: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label="Plan Details"
                    variant="outlined"
                    disabled={!isEditable}
                    onChange={(e) => handlePlanDetailsChange(0, e.target.value)}
                    name="plansDetails"
                    fullWidth
                    margin="normal"
                    sx={{ mt: 2 }}
                    value={planDetails[0]}
                  />
                  <Button
                    onClick={() => addRemoveRow("ADD", planDetails.length - 1)}
                  >
                    +
                  </Button>
                </div>

                {planDetails.slice(1).map((detail, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TextField
                      label={`Plan Detail ${index + 1}`}
                      variant="outlined"
                      disabled={!isEditable}
                      onChange={(e) =>
                        handlePlanDetailsChange(index + 1, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      sx={{ mt: 2 }}
                      value={detail}
                    />
                    <Button onClick={() => addRemoveRow("REMOVE", index + 1)}>
                      -
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </Box>
          <div
            style={{
              margin: 10,
              width: "40%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                borderRadius: 2,
                // minHeight: "100%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0.1, 0.3)",
                display: "flex",
                flexDirection: "column",
                minHeight: 300,
                minWidth: 300,
              }}
            >
              <div style={{ backgroundColor: data.color, height: "8px" }}></div>
              <CardContent
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "10px 20px",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "4px",
                }}
              >
                <div style={{ marginBottom: "8px", marginTop: "10px" }}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{ fontWeight: "bold" }}
                  >
                    {data.plansType}
                  </Typography>
                </div>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ marginBottom: "8px" }}
                >
                  {data.duration} {data.durationType}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ fontWeight: "bold", marginBottom: "8px" }}
                >
                  ₹ {data.price}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ fontWeight: "bold", marginBottom: "8px" }}
                >
                  {data.sessionCount}
                </Typography>
                <Typography
                  style={{ fontSize: 12, marginBottom: "8px" }}
                  color="text.secondary"
                >
                  {data.plansTitle}
                </Typography>
                {planDetails.map((listItem, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <div style={{ fontSize: "12px", marginRight: "8px" }}>
                      •
                    </div>
                    <div style={{ fontSize: "12px" }}>{listItem}</div>
                  </div>
                ))}
                {/* <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{ fontWeight: "bold", marginTop: "8px" }}
                >
                  {data.messageCount} Conversations/Day
                </Typography> */}
              </CardContent>

              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  className="btn btn-outline-primary text-primary text-uppercase"
                  type="button"
                  id="switchButton"
                  size="medium"
                  sx={{
                    fontWeight: "700",
                    fontSize: 12,
                    width: "100%",
                    backgroundColor: "white",
                    color: "#157feb",
                    border: "1px solid blue",
                    borderRadius: 0.8,
                    "&:hover": {
                      backgroundColor: "white",
                      color: "white",
                      boxShadow: "2px 4px 4px rgba(0, 0, 0.1, 0.3)",
                    },
                  }}
                >
                  BUY NOW
                </Button>
              </CardActions>
            </Card>
          </div>
        </Box>
      </Modal>
    </>
  );
}
