import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthUserService } from './services';
import { showErrorMessage } from './managers/utility';
import settingsModule from './services/settings';


export const SESSION_MODE = {
    SPEECH_MODE: "SPEECH_MODE",
    TEXT_MODE: "TEXT_MODE"
}

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState("NOT-SET");
    const [inputMode, setInputMode] = useState("VOICE");
    const [sessionStarted, setSessionStarted] = useState(false);
    const [sessionMode, setSessionMode] = useState(SESSION_MODE.SPEECH_MODE);
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const [zoomLevel, setZoomLevel] = useState(85)
    const [viewLoginModal, setViewLoginModal] = useState(false);

    function toggleZoomScreen() {
        console.log("Zooom Logic Executing")
        document.body.style.zoom = `${zoomLevel}%`;
    } 

    const getCurrentValue = async () => {
        try {
          const setting = await settingsModule.getSettings();
          if (setting && setting._id) {
            setZoomLevel(setting.zoomLevel ? setting.zoomLevel : 85);
          }
        } catch (error) {
            if(error.error !== "Invalid token")
          showErrorMessage("Error occurred while fetching timer value.");
        }
      };

    useEffect(() => {
        toggleZoomScreen()
    }, [zoomLevel])

    useEffect(() => {
        getCurrentValue()
        if (userDetails === "NOT-SET")
            getUserDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserDetails = async () => {

        let mode = localStorage.getItem('sessionMode');
        if (mode) {
            setSessionMode(mode)
        }

        let a = localStorage.getItem('userDetails');
        a = a ? JSON.parse(a) : null;
        if (a && a._id) {
            let user = await AuthUserService.getUserById(a._id, a.role || a.type);
            if (user && user._id) {
                setUserDetails(user);
                if (user.sessionId)
                    localStorage.setItem('sessionId', user.sessionId)
            }
            else {
                setUserDetails("LOGGED_OUT");
                localStorage.clear()
            }
        } else
            setUserDetails("LOGGED_OUT");
    };

    const refetchUserDetails = async () => {
        let user = await AuthUserService.getUserById(userDetails._id, "USER");
        if (user)
            setUserDetails(user);
        else
            localStorage.clear()
        return user
    }

    return (
        <AuthContext.Provider value={{
            setResetPasswordModal,resetPasswordModal,
            userDetails, getUserDetails, refetchUserDetails,
            sessionStarted, setSessionStarted, viewLoginModal,
            setViewLoginModal, setUserDetails,
            setInputMode, inputMode,
            sessionMode, setSessionMode
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
