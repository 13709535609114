import { Box, Button, CircularProgress, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { QuestionsService, ReportRemarkService, SettingsService } from '../../../services';
import SessionReportTable from '../FreeSessionReport.js/SessionReportTable';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { calculateAgeFromDate } from '../../../managers/utility';
import generatePDF, { Margin } from 'react-to-pdf';
import { useState, useEffect } from 'react';
import DisorderModalComponent from './DisorderModalComponent';
import { reportMessages, sessionTypes } from '../../../common/constants';
import ReportDetailedData from './reportDetailedData';
import ParticipantAnswer from './ParticiapantAnswer';
import ShareIcon from '@mui/icons-material/Share';
import AddComment from './AddComment';
import { useAuth } from '../../../AuthContext';

export default function PaidSessionReport({ final, selectedSession, showReport, showDirectlyDetailed }) {
  const [loading, setLoading] = useState(true);

  const { userDetails } = useAuth();

  const [sessionData, setSessionData] = useState({});
  const [showDetailedReport, setShowDetailedReport] = useState(false);

  const [filteredDisorderTendency, setFilteredDisorderTendency] = useState([]);
  const [remarks, setRemarks] = useState([]);

  useEffect(() => {
    getReportRemarks();
  }, [sessionData]);

  const getReportRemarks = async () => {
    const remarksAPI = await ReportRemarkService.getReportRemark({ remarkProviderId: userDetails._id, sessionId: sessionData.sessionId });
    if(remarks) setRemarks(remarksAPI)
  };

  useEffect(() => {
    getSessionData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSession]);

  useEffect(() => {
    setShowDetailedReport(showDirectlyDetailed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDirectlyDetailed]);

  const getSessionData = async () => {
    if (selectedSession && selectedSession.sessionId) {
      setSessionData(selectedSession);
      filterTendencies(selectedSession);
      setLoading(false);
    } else {
      let sessionId = localStorage.getItem('sessionId');
      if (sessionId && sessionId.length) {
        const res = await QuestionsService.getSessionById(sessionId);
        setSessionData(res);
        filterTendencies(res);
        setLoading(false);
      } else {
        window.location.href = '/dashboard/reports';
      }
    }
  };

  const filterTendencies = sessionData => {
    if (sessionData) {
      let indicators = sessionData.aipsychiReport?.disordersTendencies;
      if (!indicators) return;
      const mergedArray = [];

      for (const item of indicators) {
        let name = item.name.replace(' SEC', '');
        let existing = mergedArray.find(element => element.name === name);

        if (existing) {
          // Merge tendencies if disorder already exists
          for (const tendency of item.tendencies) {
            if (!existing.tendencies.includes(tendency)) {
              existing.tendencies.push(tendency);
            }
          }
        } else {
          // Add new disorder if not already in the merged array
          mergedArray.push({ name, tendencies: item.tendencies });
        }
      }

      console.log(mergedArray);
      setFilteredDisorderTendency(mergedArray);
    }
  };

  const isTrialModeOn = name => {
    if (sessionData && sessionData.sessionType && sessionData.sessionType === sessionTypes.TRIAL) return true;
  };

  const navigate = useNavigate();

  const age = calculateAgeFromDate(sessionData?.userId?.dateOfBirth);

  const options = {
    page: {
      margin: Margin.MEDIUM,
    },
  };

  const getTargetElement = () => document.getElementById('area-to-print');
  const getTargetElementBriefReport = () => document.getElementById('brief-report-area-to-print');

  const [selectedDisorder, setSelectedDisorder] = useState(null);
  const [description, setDescription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [setting, setSettings] = useState({});

  useEffect(() => {
    getSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettings = async () => {
    const settingLocal = await SettingsService.getAllDisorderInfoCategoriesAndDetailsAPI();
    if (settingLocal && settingLocal.length) setSettings(settingLocal);
  };

  function removeIndicator(name) {
    return name.replace('Indicator ', ''); // Replace "Indicator " with an empty string
  }

  const handleDisorderClick = async disorderName => {
    const cleanedName = removeIndicator(disorderName);
    console.log(setting);
    let foundDisorder = null;
    setting.find(disorderGroup => {
      let found = disorderGroup.details.find(specificDisorder => {
        return specificDisorder.name === cleanedName;
      });
      foundDisorder = found;
      return found;
    });

    console.log(foundDisorder);

    if (foundDisorder) {
      setDescription(foundDisorder.description);
      setSelectedDisorder(cleanedName);
      setIsModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const sessionDataDisorderDetails = () => {
    return sessionData && sessionData.final && sessionData.final.length ? (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          onClick={() => generatePDF(getTargetElementBriefReport, options)}
          style={{ marginRight: '26px', backgroundColor: '#ffcf00', color: 'black' }}
        >
          Download Brief Report
        </Button>
      </div>
    ) : (
      ''
    );
  };

  const GadResult = () => {
    const totalPoints = sessionData.totalPoints;
    console.log('VAlie of Total Point is', totalPoints);
    let message = '';
    if (totalPoints === 0) return;
    if (totalPoints > 0 && totalPoints <= 4) message = 'No Anxiety';
    if (totalPoints >= 5 && totalPoints <= 9) message = 'Mild Anxiety';
    if (totalPoints >= 10 && totalPoints <= 14) message = 'Moderate Anxiety';
    if (totalPoints >= 15 && totalPoints <= 21) message = 'Severe Anxiety';
    return (
      <>
        <div style={{ display: 'flex', fontSize: '20px' }}>
          <div style={{ fontWeight: '700' }}>GAD Assessment Result &nbsp;</div> <div>: {message}</div>
        </div>
        <div style={{ marginTop: '20px' }}>You may take advance assessment for knowing about other concurrent disorders</div>
      </>
    );
  };

  const detailedReportData = () => {
    return (
      <>
        {sessionData && sessionData.final && sessionData.final.length ? (
          <ReportDetailedData
            description={description}
            selectedDisorder={selectedDisorder}
            sessionData={sessionData}
            selectedSession={selectedSession}
            isModalOpen={isModalOpen}
            isTrialModeOn={isTrialModeOn}
            handleDisorderClick={handleDisorderClick}
            DisorderModalComponent={DisorderModalComponent}
            setIsModalOpen={setIsModalOpen}
            setDescription={setDescription}
            setSelectedDisorder={setSelectedDisorder}
          />
        ) : (
          <>
            <div>{reportMessages.congratulations}</div>
            <div style={{ marginTop: 16 }}>
              <b>Disclaimer</b>: AI-Based Mental Health Assessment
              <br />
              <br />
              {reportMessages.disclaimer}&nbsp;
              <a
                style={{
                  color: 'blue',
                  cursor: 'pointer',
                  marginLeft: '2px',
                }}
                onClick={() => navigate('/dashboard/reports')}
                href="/dashboard/reports"
              >
                Here
              </a>
              <br />
              <br />
              You are entitled to our self-help app, available at no cost for the specified duration in your plan. Download the app from
              the&nbsp;
              <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.aipsychi">
                Play Store
              </a>
              &nbsp; or&nbsp;
              <a target="_blank" rel="noreferrer" href="https://apps.apple.com/in/app/aipsychi/id6478376096">
                App Store
              </a>
              &nbsp; for a healthier life.
            </div>
          </>
        )}
      </>
    );
  };

  const trialReportTable = () => {
    return (
      <>
        {sessionData.isFreeSession && !showDetailedReport && (
          <SessionReportTable selectedSession={selectedSession} detectedDisorders={final} />
        )}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '25%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ paddingLeft: '15%', paddingRight: '15%' }} className="col gx-1 flex-nowrap overflow-auto pb-2 issuebox">
          {trialReportTable()}

          <br />
          {!showDetailedReport && (
            <div>
              <GadResult />
              {sessionData.sessionType != sessionTypes.GAD && (
                <>
                  {sessionDataDisorderDetails()}
                  {detailedReportData()}
                </>
              )}
            </div>
          )}

          {/* ----------------- This is Detailed Report ----------------- */}
          {showDetailedReport ? (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <AddComment selectedSession={selectedSession} getReportRemarks={getReportRemarks}/>
                <Button variant="contained" onClick={() => generatePDF(getTargetElement, options)} style={{ marginLeft: '26px' }}>
                  Download PDF
                </Button>
                {/* <Button sx={{width:"200px"}}>Add Comment</Button> */}
              </div>
              <div style={{ display: 'flex', marginTop: '20px' }}>
                <ParticipantAnswer selectedSession={selectedSession} />
              </div>
              <div id="area-to-print">
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <img
                    alt="filler"
                    src="/images/aipsychi.png"
                    className="img-fluid"
                    style={{
                      // width: '20px',
                      height: '38px',
                      marginTop: '20px',
                    }}
                  />
                </div>
                {(showReport || !window.location.href.includes('report')) && (
                  <>
                    <div style={{ marginTop: '10px' }}>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        Participant Information :
                      </Typography>
                      <div style={{ paddingLeft: '20px' }}>
                        <Typography>Name : {sessionData?.userId?.name}</Typography>
                        <Typography>
                          Date of Birth :
                          {sessionData.userId && sessionData.userId?.dateOfBirth
                            ? moment(sessionData.userId?.dateOfBirth).format('DD-MM-YYYY')
                            : 'NA'}
                        </Typography>
                        <Typography>Age :{sessionData.userId && sessionData.userId.dateOfBirth ? ` ${age} Years` : 'NA'}</Typography>
                        <Typography>Gender : {sessionData?.userId?.gender}</Typography>
                        <Typography>Date of Interaction :{moment(sessionData.createdAt).format('DD-MM-YYYY')}</Typography>
                      </div>
                    </div>
                    {sessionData && sessionData.answerWithString && sessionData.answerWithString['2061'] ? (
                      <div style={{ marginTop: '10px' }}>
                        <Typography sx={{ fontWeight: 600 }} variant="h6">
                          Chief Complaint :
                        </Typography>
                        <div style={{ paddingLeft: '20px' }}>
                          <Typography>{sessionData.answerWithString['2061']}</Typography>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {sessionData &&
                    sessionData.aipsychiReport &&
                    sessionData.aipsychiReport.assessedCategories &&
                    sessionData.aipsychiReport.assessedCategories.length ? (
                      <div style={{ marginTop: '10px' }}>
                        <Typography sx={{ fontWeight: 600 }} variant="h6">
                          Assessed tendencies of the Disorder :
                        </Typography>
                        <div style={{ paddingLeft: '20px' }}>
                          {sessionData.aipsychiReport.assessedCategories.map(item => (
                            <Typography>
                              {item}
                              {console.log(item)}
                            </Typography>
                          ))}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {sessionData && sessionData.aipsychiReport && sessionData.aipsychiReport.filteredSymptomsData
                      ? sessionData.aipsychiReport.filteredSymptomsData.map(entity => (
                          <>
                            <Typography sx={{ fontWeight: 600 }} variant="h6">
                              {entity.category}
                            </Typography>
                            {entity.data.map(category => {
                              return (
                                <>
                                  <Typography variant="h6">&nbsp;&nbsp;&nbsp;{`${category.name}`}</Typography>
                                  {Object.values(category.symptoms).map(symptom => (
                                    <Typography>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {symptom}
                                    </Typography>
                                  ))}
                                </>
                              );
                            })}
                          </>
                        ))
                      : ''}

                    {filteredDisorderTendency && filteredDisorderTendency.length ? (
                      <div style={{ marginTop: '10px' }}>
                        <Typography variant="h6" sx={{ fontWeight: '600' }}>
                          Found Tendencies of the Disorders :
                        </Typography>
                        <div
                          style={{
                            color: 'green',
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                        >
                          Note: There may be instances where your answers were inconclusive, or a question was skipped. In such cases,
                          informed inferences were made with prudence.
                        </div>
                        {filteredDisorderTendency.map(disorder => (
                          <>
                            {disorder.tendencies.length && sessionData.final.includes(disorder.name) ? (
                              <div key={disorder.name} style={{ paddingLeft: '20px' }}>
                                <Typography variant="h6">{disorder.name}</Typography>
                                {disorder &&
                                  disorder.tendencies &&
                                  disorder.tendencies.map(tendency => (
                                    <div key={tendency} style={{ paddingLeft: '20px' }}>
                                      <Typography>‣ {tendency}</Typography>
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                    {sessionData && sessionData.final && sessionData.final.length ? (
                      ''
                    ) : (
                      <>
                        <Typography variant="h6" sx={{ fontWeight: '600' }}>
                          Found Tendencies of the Disorders :
                        </Typography>
                        <div>Congratulations, we could not find any noticeable tendencies in your case and that is very creditable.</div>
                      </>
                    )}

                    <div>
                      <Typography variant="h5" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        Information
                      </Typography>

                      <Typography align="justify">
                        You're entitled to our self-help app, available at no cost for the duration specified in your plan. Download the app
                        from the Play Store [provide link] or App Store [provide link] for a healthier life.
                      </Typography>
                      <Typography align="justify">
                        This assessment of tendencies is powered by artificial intelligence (AI) and is intended for informational purposes
                        only. It is not a substitute for professional medical advice, diagnosis, or treatment. The results provided by this
                        AI tool should not be considered a definitive diagnosis, and individuals are encouraged to consult with qualified
                        healthcare professionals for personalized evaluation and guidance. For Limitations and User responsibilities please
                        read the report.
                      </Typography>
                      <Typography variant="h5" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        Limitations:
                      </Typography>
                      <Typography align="justify">
                        No Replacement for Professional Evaluation: The AI assessment is not a replacement for a thorough evaluation by a
                        licensed mental health professional. It is a tool designed to provide insights and general information.
                      </Typography>
                      <Typography align="justify">
                        Data Confidentiality: While efforts are made to maintain the confidentiality of user data, online assessments
                        inherently involve privacy risks. Users should exercise caution and avoid sharing sensitive information.
                      </Typography>
                      <Typography align="justify">
                        Not Emergency Services: In case of an emergency or urgent mental health concern, please seek immediate assistance
                        from emergency services or a qualified mental health professional.
                      </Typography>
                      <Typography variant="h5" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        Accuracy and Reliability:
                      </Typography>
                      <Typography align="justify">
                        AI Limitations: AI algorithms have limitations, and results may be influenced by the quality and accuracy of input
                        data. The assessment should be interpreted with an understanding of these limitations. Evolution of Science: Mental
                        health science is continually evolving. The AI model may not capture the latest advancements, and users are
                        encouraged to stay informed about updates in mental health research.
                      </Typography>
                      <Typography variant="h5" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        User Responsibility:
                      </Typography>
                      <Typography align="justify">
                        Informed Decision-Making: Users are encouraged to make informed decisions based on the AI assessment results. The
                        tool is not a substitute for professional judgment, and users should use their discretion in interpreting the
                        findings. Feedback and Improvement: User feedback on the assessment tool is welcomed. Continuous improvement efforts
                        are ongoing to enhance the accuracy and usability of the AI model. By using this mental health assessment, you
                        acknowledge that you have read and understood this disclaimer. If you have concerns about your mental health, please
                        consult with a qualified healthcare professional.
                      </Typography>
                      <Typography variant="h5" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        Remarks
                      </Typography>
                      <>
                      {remarks.map((item) => (
                      <Typography align="justify">
                        {moment(item.createdAt).format("DD-MMM-YY")} - {item.remark}
                      </Typography>
                      ))}
                      </>
                    </div>
                  </>
                )}
              </div>
              <Button sx={{ mt: 10 }} variant="contained" onClick={() => setShowDetailedReport(false)}>
                Show Brief Report
              </Button>
            </>
          ) : (
            <div style={{ marginTop: '30px' }}>
              {sessionData.sessionType != sessionTypes.GAD && (
                <>
                  <Button
                    style={{ backgroundColor: '#ffcf00', color: 'black' }}
                    variant="contained"
                    onClick={() => setShowDetailedReport(true)}
                  >
                    Show Detailed Report
                  </Button>
                  <span> This may be shared with any mental health professional.</span>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
