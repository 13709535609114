import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const QuillViewer = ({ content }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const quill = new Quill(containerRef.current, {
      readOnly: true,
    });
    quill.root.innerHTML = content; // Set the content

    return () => {
      quill.off("text-change");
      quill.disable();
      quill.container.remove();
    };
  }, [content]);

  return <div ref={containerRef} />;
};

export default QuillViewer;
