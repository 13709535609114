import React, { useEffect, useState, useRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { QuestionsService } from "../../services";
import { useTimer } from "react-timer-hook";
import { showErrorMessage, showSuccessMessage } from "../../managers/utility";
import "../../App.css";
import { SESSION_MODE, useAuth } from "../../AuthContext";
import "../../App.css";
import { AudioPlayer } from "react-audio-play";
import SessionUI from "./SessionComponents/SessionUI";

export default function Session({
  useLLM,
  inputMode,
  recievedSettings,
  globalSettings,
  sessionType,
  setSessionStarted,
  setAbleToResumeSession,
  setSessionType,
  setLoadingSessionDetails,
  setShowMessage,
  setOpen,
}) {

  const {
    seconds,
    restart,
    isRunning: isTimerRunning,
  } = MyTimer({ expiryTimestamp: Date.now() + 0 * 1000 });

  const { userDetails, sessionMode } = useAuth();
  const [question, setQuestion] = useState("");
  const [instructions, setInstructions] = useState("");
  const [apiData, setAPIData] = useState({});
  const [currentLot, setCurrentLot] = useState(0);
  const [answers, setAnswers] = useState(null);
  const [submitInProcess, setSubmitInProcess] = useState(false);
  const [listenerState, setListenerState] = useState("NOT_STARTED");
  const [controlsVisible, setControlsVisible] = useState(false);
  const [final, setFinal] = useState([]);
  const [sessionPaused, setSessionPaused] = useState(false);
  const [noFinalData, setNofianlData] = useState(false);
  const [patientAnswer, setPatientAnswer] = useState("");
  const [patientAnswerBox, setPatientAnswerBox] = useState(false);
  const inputRef = useRef(null);
  const [progressBar, setProgressBar] = useState(0);

  //Timer Duration

  // eslint-disable-next-line no-unused-vars
  const [timerDuration, setTimerDuration] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [audioElement, setAudioElement] = useState("");
  // eslint-disable-next-line no-unused-vars



  const [completed, setCompleted] = useState(false);

  // eslint-disable-next-line no-unused-vars
  useEffect(() => {
    let audioElement = document.getElementsByTagName("audio")[0];
    setAudioElement(audioElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (userDetails._id && question === "" && recievedSettings) getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, recievedSettings]);


  useEffect(() => {
    if (!isTimerRunning && listenerState === "STARTED") {
      setListenerState("ENDED");
      SpeechRecognition.stopListening();
      submitQuestion();
      resetTranscript();
      if (!sessionPaused) {
        setQuestion("");
        setPatientAnswer("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerRunning]);

  // Remove this after reviewing  


  const readInstructions = async (question) => {
    let englishText = question.langText.find(item => item.languageCode === 'en');
  
    englishText = (englishText && englishText.text) ? englishText.text : "";
  
    let restartDuration = 5000;
    restart(Date.now() + restartDuration);
  
    setTimeout(() => {
      setQuestion(englishText);
      nowReadQuestion();
    }, restartDuration + 1000);
  };
  

  const nowReadQuestion = async () => {
    let currentQuestionDuration = 1;
    if (sessionMode === SESSION_MODE.SPEECH_MODE) {
      setAudioElement((prev) => {
        console.log("Coming Till Here")
        const player = document.getElementsByTagName("audio")[0];
        console.log("Coming Till Here",player)
        console.log("Coming Till Here",prev)
        console.log("Coming Till Here",player.src)

        player.src = prev;


        const eventTimeout = setTimeout(() => {
          console.error("Failed to load metadata within the expected time frame");
          // Handle the failure case here, e.g., display an error message or retry logic

          const duration = (player && player.duration) ? player.duration : 0;

          currentQuestionDuration = duration;
          currentQuestionDuration = currentQuestionDuration * 1000;
          resetTranscript()
          setTimerDuration((prevTimer) => {
            let gapAfterQuestionSpeaks = 300;
            // Gap after question
            let restartDuration =
              Date.now() +
              gapAfterQuestionSpeaks +
              currentQuestionDuration +
              prevTimer * 1000;

            setTimeout(() => {
              setSessionPaused((prev) => {
                if (!prev) {
                  restart(restartDuration);
                  startListening();
                }
                return prev;
              });
            }, gapAfterQuestionSpeaks + currentQuestionDuration);
            return prevTimer;
          });
        }, 40000); 


        player.addEventListener("loadedmetadata", () => {
          console.log("Load Success - Clearing Timeout")
          clearTimeout(eventTimeout)
          // Access the duration property
          const duration = (player && player.duration) ? player.duration : 0;

          currentQuestionDuration = duration;
          currentQuestionDuration = currentQuestionDuration * 1000;
          resetTranscript()
          setTimerDuration((prevTimer) => {
            let gapAfterQuestionSpeaks = 300;
            // Gap after question
            let restartDuration =
              Date.now() +
              gapAfterQuestionSpeaks +
              currentQuestionDuration +
              prevTimer * 1000;

            setTimeout(() => {
              setSessionPaused((prev) => {
                if (!prev) {
                  restart(restartDuration);
                  startListening();
                }
                return prev;
              });
            }, gapAfterQuestionSpeaks + currentQuestionDuration);
            return prevTimer;
          });
          if (sessionMode === SESSION_MODE.SPEECH_MODE)
            player.play();
        });
      });
      // console.log(currentQuestionDuration,"currentQuestionDuration");
      // if(currentQuestionDuration === 1){
      //   console.log("Code should not come here")
      //   // This would be fallback if the question media does not exist
      //   currentQuestionDuration = currentQuestionDuration * 1000;
      //   resetTranscript()
      //   setTimerDuration((prevTimer) => {
      //     let gapAfterQuestionSpeaks = 300;
      //     // Gap after question
      //     let restartDuration =
      //       Date.now() +
      //       gapAfterQuestionSpeaks +
      //       currentQuestionDuration +
      //       prevTimer * 1000;

      //     setTimeout(() => {
      //       setSessionPaused((prev) => {
      //         if (!prev) {
      //           restart(restartDuration);
      //           startListening();
      //         }
      //         return prev;
      //       });
      //     }, gapAfterQuestionSpeaks + currentQuestionDuration);
      //     return prevTimer;
      //   });
      //   // This would be fallback if the question media does not exist
      // }
    } else {
      setPatientAnswerBox(true)
      enableFieldAndFocus()
    }
  };

  // Simple Focus on Field
  useEffect(() => {
    enableFieldAndFocus();
  }, [patientAnswerBox]);

  const startListening = () => {
    setControlsVisible(true);
    setListenerState("STARTED");
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  const {
    transcript,
    listening,
    resetTranscript,
  } = useSpeechRecognition();

  useEffect(() => {
    setPatientAnswer(transcript);
  }, [transcript]);

  const getQuestions = async () => {
    let sId = localStorage.getItem("sessionId");
    const data = await QuestionsService.getQuestions({
      sessionId: sId,
      refetch: true,
      userId: userDetails._id,
      userType: userDetails.role || "USER",
      sessionType: sessionType,
    });

    if (data.question) {
      try {
        setAudioElement(
          `https://demoplayground4093662547.z20.web.core.windows.net/${(userDetails.webSelectedLangauge && userDetails.webSelectedLangauge === "EN_IN") ? 'in-en' : 'us-en'}/Code${data.question.code}.mp3`
        );
      } catch (e) {
        showErrorMessage(e)
      }

      let timerValues = globalSettings.timerValue;
      if (!timerValues) timerValues = { "HIGH": 35, "HIGHEST": 45, "LOW": 21, "MEDIUM": 20 };
      let pickedUpValue = timerValues[data.question.timer];
      if (typeof pickedUpValue !== 'number') {
        pickedUpValue = 10;
      }
      setTimerDuration(pickedUpValue);
      setInstructions(data.question.instructions);
      readInstructions(data.question);
      setAPIData(data);
    } else {
      if (data.message) showErrorMessage(data.message);
      if(data.type && data.type === "NO_SESSION") {
        setAbleToResumeSession("NONE")
        setSessionType('')
        setLoadingSessionDetails(false)
        setShowMessage("")
        setOpen(false);
        setSessionStarted(false)
      }
    }
    if (data.sessionId) {
      localStorage.setItem("sessionId", data.sessionId);
    }
  };

  const submitQuestion = async (skip) => {
    if (sessionPaused) return;
    setSubmitInProcess(true);
    resetTranscript();
    let sId = localStorage.getItem("sessionId");
    if (!sId) {
    }

    let translatedText;
    const reqData = {
      currentQuestionCode: apiData.question?.code,
      textResponse: translatedText ? translatedText : patientAnswer,
      sessionId: sId,
      answers: answers,
      lot: currentLot,
      useLLM: useLLM,
      userId: userDetails._id,
      userType: userDetails.role || "USER",
      sessionType: sessionType,
    };
    setPatientAnswerBox(false);

    // API CALL is made here
    const data = await QuestionsService.getQuestions(reqData);
    if (data.question) {
      resetTranscript();
      setControlsVisible(false);
      setPatientAnswer("");
      try {
        setAudioElement(
          `https://demoplayground4093662547.z20.web.core.windows.net/${(userDetails.webSelectedLangauge && userDetails.webSelectedLangauge === "EN_IN") ? 'in-en' : 'us-en'}/Code${data.question.code}.mp3`
        );
      } catch (e) {
        showErrorMessage(e)
      }


      let timerValues = globalSettings.timerValue;
      if (!timerValues) timerValues = { "HIGH": 35, "HIGHEST": 45, "LOW": 21, "MEDIUM": 20 };
      let pickedUpValue = timerValues[data.question.timer];
      if (typeof pickedUpValue !== 'number') {
        pickedUpValue = 10;
      }
      setTimerDuration(pickedUpValue);
      setInstructions(data.question.instructions);
      setAPIData(data);
      setAnswers(data.answers);
      setSubmitInProcess(false);
      setCurrentLot(data.lot);
      readInstructions(data.question);
    } else {
      if (data.final) {
        setFinal(data.final);
        setSubmitInProcess(false);
        setNofianlData(false);
      } else {
        setNofianlData(true);
      }
      if (data.message) {
        if(data.type && data.type === "NO_SESSION") {
          setSessionStarted(false)
          setAbleToResumeSession("NONE")
          setSessionType('')
          setLoadingSessionDetails(false)
          setShowMessage("")
          setOpen(false);
        }
        showErrorMessage(data.message);
      }
    }

    if (data.totalCount && data.totalCountRemaining) {
      const progressBarValue = ((data.totalCount - data.totalCountRemaining) / data.totalCount) * 100;
      setProgressBar(progressBarValue);
    }
    if (data.message === "All Lots are completed") {
      restart(Date.now());
      setCompleted(true);
      setInstructions("");
      showSuccessMessage("Thank you");
    }
  };


  const enableFieldAndFocus = () => {
    setTimeout(() => {
      try{
        inputRef.current.focus();
    }catch(e) {
      console.log(e)
    }
    }, 500);
  };

  const editButtonHandler = () => {
    setPatientAnswerBox(!patientAnswerBox);
    restart(Date.now + 1);
    SpeechRecognition.stopListening();
    setListenerState("ENDED");
  };

  const skipButtonHandler = () => {
    SpeechRecognition.stopListening();
    setListenerState("ENDED");
    setQuestion("");
    resetTranscript();
    setPatientAnswer("");
    submitQuestion(true);
    restart(Date.now + 1);
  };

  const submitQuestionForcefully = () => {
    SpeechRecognition.stopListening();
    setListenerState("ENDED");
    submitQuestion();
    setQuestion("");
    resetTranscript();
    setPatientAnswer("");
  };


  const sessionPauseHandler = () => {
    setSessionPaused(true);
    restart(Date.now());
    SpeechRecognition.stopListening();
  };

  return (
    <SessionUI
      AudioPlayer={AudioPlayer}
      completed={completed}
      submitQuestionForcefully={submitQuestionForcefully}
      sessionPauseHandler={sessionPauseHandler}
      question={question}
      setControlsVisibl={setControlsVisible}
      setSessionPaused={setSessionPaused}
      instructions={instructions}
      createBoldText={createBoldText}
      setPatientAnswerBox={setPatientAnswerBox}
      patientAnswerBox={patientAnswerBox}
      sessionPaused={sessionPaused}
      listening={listening}
      setPatientAnswer={setPatientAnswer}
      inputMode={inputMode}
      inputRef={inputRef}
      patientAnswer={patientAnswer}
      submitInProcess={submitInProcess}
      controlsVisible={controlsVisible}
      editButtonHandler={editButtonHandler}
      skipButtonHandler={skipButtonHandler}
      seconds={seconds}
      listenerState={listenerState}
      noFinalData={noFinalData}
      final={final}
      setControlsVisible={setControlsVisible}
      enableFieldAndFocus={enableFieldAndFocus}
      progressBar={progressBar}
    />
  );
}

function MyTimer({ expiryTimestamp }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("Timer Ended"),
  });

  return {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  };
}


function createBoldText(instructions) {
  return <div dangerouslySetInnerHTML={{ __html: instructions }} />;
}
