import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";



const mediaModule = {
    getAllUsersProfile,
    AddUserProfile,
    updateUserPro,
    deleteUserProfile,
    changePassword,
    updateUserLanguage,
    updateUserLanguageWeb
};

export default mediaModule;

async function getAllUsersProfile(requestData) {
    let url = process.env.REACT_APP_API_URI + "/api/private/get-users-profile"
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function AddUserProfile(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/create-user-profile`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateUserPro(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-user-profile/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function deleteUserProfile(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-user-profile/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.DELETE, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function changePassword(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/change-password`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateUserLanguage(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-user-language`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateUserLanguageWeb(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-user-language-web`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

