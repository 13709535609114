import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { SettingsService } from "../../../../services";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../managers/utility";

export default function EditCategoryModal({
  openAddCategoryModal,
  handleCloseAddCategoryModal,
  getDisorderData,
  modalTitle,
}) {
  const [disorderName, setDisorderName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setDisorderName("");
  }, []);

  const handleDeleteCategory = async () => {
    try {
      const res = await SettingsService.deleteDisorderInfoCategoriesAPI({
        categoryId: modalTitle._id,
      });

      if (res && res._id) {
        getDisorderData();
        setDisorderName("");
        showSuccessMessage("Deleted Disorder Categories");
        handleCloseAddCategoryModal();
      } else {
        showErrorMessage(res.message);
      }
    } catch (error) {
      showErrorMessage("An error occurred while deleting category");
    }
  };
  const handleSaveCategory = async () => {
    try {
      const payload = {
        name: disorderName,
        categoryId: modalTitle._id,
      };
      const res = await SettingsService.updateDisorderInfoCategoriesAPI(
        payload
      );
      if (res && res._id) {
        getDisorderData();
        setDisorderName("");
        showSuccessMessage(res.message);
        handleCloseAddCategoryModal();
      } else {
        showErrorMessage(res.message);
      }
    } catch (error) {
      showErrorMessage("An error occurred while adding category");
    }
  };

  const handleInputChange = (e) => {
    setDisorderName(e.target.value);
    setError(""); // Clear error message when input changes
  };

  return (
    <Modal open={openAddCategoryModal} onClose={handleCloseAddCategoryModal}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          borderRadius: "8px",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxWidth: "700px",
          backgroundColor: "white",
          padding: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography variant="h5">Edit/Delete Category</Typography>
          <IconButton onClick={handleCloseAddCategoryModal}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <textarea
          rows={4} // Adjust the number of rows as needed
          placeholder="Enter Category name"
          value={disorderName || modalTitle.name}
          onChange={handleInputChange}
          style={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 4,
            padding: 8,
            // marginBottom: 8
          }}
        />
        {error && (
          <Typography
            variant="body5"
            color="error"
            style={{ marginBottom: "20px" }}
          >
            {error}
          </Typography>
        )}
        <div style={{ display: "flex" }}>
          {" "}
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleSaveCategory}
            style={{ marginRight: "10px" }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            onClick={handleDeleteCategory}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
