import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Chip, TablePagination } from "@mui/material";
import moment from "moment/moment";

export default function BasicTable({
  page,
  setPage,
  data,
  totalCount,
  setTotalCount,
  setSelectedSession,
  setOpenReport,
  rowsPerPage,
  setRowsPerPage
}) {
    // const [page, setPage] = useState(2);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPage = Math.floor(page * rowsPerPage / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
      };
      
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Created at</TableCell>
            <TableCell align="right">Question Count</TableCell>
            <TableCell align="right">Final Disorders</TableCell>
            <TableCell align="right">Report</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              onClick={() => setSelectedSession(row)}
              key={row.userName}
              sx={{
                cursor: "pointer",
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {row.userName}
              </TableCell>
              {/* <TableCell align="right">{row.createdAt}</TableCell> */}
              <TableCell align="right">
                
                {moment(row.createdAt).format("Do MMMM YYYY")}
              </TableCell>
              <TableCell align="right">{row.questionsCount}</TableCell>
              <TableCell align="right">
                {row.final.map((item) => (
                  <Chip
                    sx={{ margin: 0.5 }}
                    size="small"
                    label={item}
                    color="primary"
                  />
                ))}
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setSelectedSession(row);
                    setOpenReport(true);
                  }}
                >
                  Report
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </TableContainer>
  );
}
