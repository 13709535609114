import React, { useState, useEffect } from "react";
import { MediaService } from "../../../../services";
import ThumbnailTableView from "./TableViewMediaCategory";
import CustomLoader from "../../../../common/customLoader";
import { Typography } from "@mui/material";

const MediaCategory = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const fetchData = async () => {
    try {
      const mediaCategories = await MediaService.getAllMediaCategories();
      setData(mediaCategories);
      setIsLoading(false);
      setNoDataFound(mediaCategories.length === 0);
    } catch (error) {
      console.error("Error fetching media categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ marginTop: "60px", textAlign: "center" }}>
      {isLoading ? (
        <CustomLoader />
      ) : noDataFound ? (
        <Typography>No data found</Typography>
      ) : (
        <ThumbnailTableView       
          data={data}
          setIsLoading={setIsLoading}
          refetch={fetchData}
        />
      )}
    </div>
  );
};

export default MediaCategory;
