import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import SettingsService from "../../../../services/settings";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../managers/utility";

export default function UndefindQuestions() {
  const [undefindQuestionsAnswers, setUndefindQuestionsAnswers] =
    useState([{ question: "", answers: [""] }]);

  useEffect(() => {
    getQuestionAnswer();
  }, []);

  const getQuestionAnswer = async () => {
    const res = await SettingsService.getQuestionAnswer();
    if (res && res[0] && res[0].undefindQuestionsAnswers) {
      setUndefindQuestionsAnswers(res[0].undefindQuestionsAnswers);
    }
  };

  const handleQuestionChange = (index, e) => {
    const updatedUndefindQuestionsAnswers = [...undefindQuestionsAnswers];
    updatedUndefindQuestionsAnswers[index].question = e.target.value.replace(
      "$",
      ""
    );
    setUndefindQuestionsAnswers(updatedUndefindQuestionsAnswers);
  };

  const handleAnswersChange = (event, index, answerIndex) => {
    let tempQA = [...undefindQuestionsAnswers];
    const selectedIndex = tempQA[index];
    const newAnswers = selectedIndex.answers;
    newAnswers[answerIndex] = event.target.value;

    selectedIndex.answers = newAnswers;
    tempQA[index] = selectedIndex;
    setUndefindQuestionsAnswers(tempQA);
  };

  const addAnswerField = (index) => {
    const updatedUndefindQuestionsAnswers = [...undefindQuestionsAnswers];
    updatedUndefindQuestionsAnswers[index].answers.push("");
    setUndefindQuestionsAnswers(updatedUndefindQuestionsAnswers);
  };

  const removeAnswerField = (index, answerIndex) => {
    const updatedUndefindQuestionsAnswers = [...undefindQuestionsAnswers];
    updatedUndefindQuestionsAnswers[index].answers.splice(answerIndex, 1);
    setUndefindQuestionsAnswers(updatedUndefindQuestionsAnswers);
  };

  // const addRemoveRow = (action, index) => {
  //   if (action === "ADD") {
  //     setQuestionsAnswers((prevState) => [
  //       ...prevState,
  //       { question: "", answers: [""] },
  //     ]);
  //   } else if (action === "REMOVE") {
  //     const updatedQuestionsAnswers = [...questionsAnswers];
  //     updatedQuestionsAnswers.splice(index, 1);
  //     setQuestionsAnswers(updatedQuestionsAnswers);
  //   }
  // };

  const addRemoveRow = (action, index) => {
    if (action === "ADD") {
      setUndefindQuestionsAnswers((prevState) => {
        const updatedUndefindQuestionsAnswers = [...prevState];
        updatedUndefindQuestionsAnswers.splice(index + 1, 0, {
          question: "",
          answers: [""],
        });
        return updatedUndefindQuestionsAnswers;
      });
    } else if (action === "REMOVE") {
      if (undefindQuestionsAnswers.length > 1) {
        setUndefindQuestionsAnswers((prevState) => {
          const updatedUndefindQuestionsAnswers = [...prevState];
          updatedUndefindQuestionsAnswers.splice(index, 1);
          return updatedUndefindQuestionsAnswers;
        });
      }
    }
  };

  const handleAddQuestion = async () => {
    const newUndefindQuestionData = {
      _id: {
        $oid: "6524aee1f523e3c3be4ba56f",
      },
      useLLM: false,
      undefindQuestionsAnswers: undefindQuestionsAnswers,
    };
    try {
      const response = await SettingsService.addQuestionAnswer(
        newUndefindQuestionData
      );
      if (response._id) {
        showSuccessMessage("Saved");
        // Clear form fields or perform any other necessary actions
      } else {
        showErrorMessage("Failed to add the question");
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  return (
    <div>
      {undefindQuestionsAnswers.map((qa, index) => (
        <div key={index} style={{ display: "flex", marginTop: "10px" }}>
          <TextField
            label="Question"
            variant="outlined"
            value={qa.question}
            onChange={(e) => handleQuestionChange(index, e)}
          />
          {qa.answers.map((answer, answerIndex) => (
            <div
              key={answerIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                sx={{ ml: 1 }}
                label="Answer"
                variant="outlined"
                value={answer}
                onChange={(e) => handleAnswersChange(e, index, answerIndex)}
              />
              {answerIndex > 0 && (
                <Button
                  variant="outlined"
                  sx={{ mt: 1 }}
                  onClick={() => removeAnswerField(index, answerIndex)}
                >
                  <RemoveIcon />
                </Button>
              )}
            </div>
          ))}
          <Button
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={() => addAnswerField(index)}
          >
            <AddIcon />
          </Button>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              fullWidth
              size="small"
              variant="outlined"
              onClick={() => addRemoveRow("ADD", index)}
            >{`Add Row`}</Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => addRemoveRow("REMOVE", index)}
            >
              Remove Row
            </Button>
          </div>
        </div>
      ))}

      <Button sx={{ mt: 3 }} variant="contained" onClick={handleAddQuestion}>
        Save <SaveIcon />
      </Button>
    </div>
  );
}
