import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField } from '@mui/material';
import { PaymentService, CouponService } from '../../../../services';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { showErrorMessage, showSuccessMessage } from '../../../../managers/utility';
import { useAuth } from '../../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import '../../../../custom.css';

export default function PromoCode({ couponData, setOpenCheckOut, openCheckOut }) {
  const [promoCode, setPromoCode] = useState('');
  const [total, setTotal] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const { userDetails } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (couponData && couponData.price) {
      if (!discount) setTotal(couponData.price);
    }
  }, [couponData, discount]);

  const handleCloseCoupon = () => {
    setOpenCheckOut(false);
  };
  const handleApplyPromoCode = async () => {
    // Add logic here to apply the promo code
    const response = await CouponService.getVerifyoupons({
      couponCode: promoCode,
      userId: userDetails._id,
    });
    if (response && response._id) {
      setAppliedCoupon(response);
      showSuccessMessage('Coupon Applied Successfully');
      setTotal(calculateDiscountedAmount(couponData.price, response.discount));
      setDiscount(calculateDiscountAmount(couponData.price, response.discount));
    } else {
      showErrorMessage(response.message);
    }
  };

  function calculateDiscountedAmount(originalAmount, discountPercentage) {
    const discount = originalAmount * (discountPercentage / 100);
    const discountedAmount = originalAmount - discount;
    return discountedAmount;
  }

  function calculateDiscountAmount(originalAmount, discountPercentage) {
    return originalAmount * (discountPercentage / 100);
  }

  const initiatePayment = async (plan, amount) => {
    if (amount <= 0) {
      showErrorMessage('Minimum amount needs to be ₹1');
      return;
    }
    try {
      const data = await PaymentService.createOrderForPayment({
        amount: amount || amount === 0 ? amount : plan.price,
        planId: plan._id,
        userId: userDetails._id,
        couponId: appliedCoupon && appliedCoupon._id ? appliedCoupon._id : null,
        currency: 'INR',
      });
      if (data && data.id) {
        showSuccessMessage('Select Payment Method');
      } else {
        showErrorMessage('Something Went wrong');
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: data.amount,
        currency: data.currency,
        name: 'AIPsychi',
        description: 'Payment for your subscription',
        order_id: data.id,
        handler: function (response) {
          showSuccessMessage('Subscription Successful');
          // navigate('/dashboard/subscriptions');
          window.location.href = "/dashboard/subscriptions"
          // document.body.style.zoom = document.body.style.zoom;
        },
        prefill: {
          name: 'Your Name',
          email: 'your.email@example.com',
          contact: '1234567890',
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);

      rzp.open();
      document.body.style.zoom = '60%';
    } catch (error) {
      console.error('Error creating order:', error.message);
      showErrorMessage(error.message);
    }
  };
  return (
    <div>
      <Modal
        open={openCheckOut} // Use openCheckOut state to control the modal
        onClose={handleCloseCoupon}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '900px',
            backgroundColor: 'white',
            padding: '50px',
            borderRadius: '8px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" gutterBottom>
                Checkout
              </Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton onClick={handleCloseCoupon}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ margin: 10, width: '34%' }}>
              <Card
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  minHeight: '100%',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0.1, 0.3)',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ backgroundColor: couponData.color, height: '8px' }}></div>
                <CardContent sx={{ flex: 1 }}>
                  <Typography gutterBottom variant="h9" component="div" sx={{ fontWeight: 'bold' }}>
                    {couponData.plansType}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {couponData.duration} Month
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                    ₹{couponData.price} Plan
                  </Typography>
                  <Typography style={{ fontSize: 12 }} color="text.secondary">
                    {couponData.plansTitle}
                  </Typography>
                  {couponData.plansDetails.map((listItem, i) => (
                    <div style={{ display: 'flex' }}>
                      <div>•</div>
                      <div
                        style={{
                          textAlign: 'left',
                          paddingLeft: '10px',
                        }}
                      >
                        {listItem}
                      </div>
                    </div>
                  ))}
                  <Typography align="center" sx={{ marginTop: 1, fontSize: 12 }}>
                    *Conversation words limit is 50 words / conversation
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '20px',
                width: '66%',
              }}
            >
              <div style={{ margin: 10, width: '100%' }}>
                <div style={{ display: 'flex' }}>
                  <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', width: '280px' }}>
                    Plan Price
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: 'bold',
                      width: '180px',
                      textAlign: 'end',
                    }}
                  >
                    {couponData.price}
                  </Typography>
                </div>

                {!discount && (
                  <>
                    <Typography style={{ fontSize: 14, marginBottom: 16 }} color="text.secondary">
                      You can use a promo code for get more discount
                    </Typography>

                    <div style={{ display: 'flex' }}>
                      <TextField
                        label="Promo Code"
                        variant="outlined"
                        value={promoCode}
                        onChange={e => setPromoCode(e.target.value)}
                        style={{ marginRight: '10px', width: '400px' }}
                      />
                      <Button variant="contained" color="primary" onClick={handleApplyPromoCode}>
                        Apply
                      </Button>
                    </div>
                  </>
                )}

                {discount && (
                  <div style={{ display: 'flex', marginTop: 20 }}>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', width: '280px' }}>
                      Promo Code Discount :
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        color: 'green',
                        fontWeight: 'bold',
                        width: '180px',
                        textAlign: 'end',
                        paddingRight: '1px',
                      }}
                    >
                      - {discount}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setDiscount(null);
                        setAppliedCoupon(null);
                      }}
                      aria-label="Example"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
                <hr></hr>
                <div style={{ display: 'flex', marginTop: 20 }}>
                  <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', width: '300px' }}>
                    Total :
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: 'bold',
                      width: '160px',
                      textAlign: 'end',
                    }}
                  >
                    {total}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginTop: 10,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      initiatePayment(couponData, total);
                    }}
                  >
                    <KeyboardDoubleArrowRightIcon sx={{ fontSize: 26 }} />
                    Continue Payment
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Typography variant="h6" fontWeight={600} align="center" sx={{ marginTop: 1 }}>
            Please contact us at care@aipsychi.org for a custom quote.
          </Typography>
        </div>
      </Modal>
    </div>
  );
}
