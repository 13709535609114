import React from "react";
import Disorder from "./Order/Disorder";
export default function LandingPage() {


  return (
    <>
      <Disorder />
    </>
  );
}
