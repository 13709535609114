import { Card, Paper, Typography } from "@mui/material";
import React from "react";

export default function CouponList({ couponList }) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "left", // Center cards horizontally
      }}
    >
      {couponList.length > 0 ? (
        couponList.map(({ _id, couponCode, discount, assigneeId }) => (
          <Card
            component={Paper}
            style={{
              width: "calc(50% - 10px)", // Set width of each card to 50% of the container with a gap of 20px
              padding: "20px 10px",
              backgroundColor: assigneeId ? "green" : "inherit", // Conditionally set background color
              color: assigneeId ? "white" : "inherit", // Conditionally set color
            }}
            key={_id}
          >
            <Typography variant="h6" fontWeight="bold">
              Coupon Details:
            </Typography>
            <div>
              <b>Coupon Code :</b> {couponCode}
            </div>
            <div>
              <b> Discount :</b> {discount}
            </div>
            <div>
               <b>Assigned To :</b> {assigneeId ? assigneeId.name : ""}
            </div>
          </Card>
        ))
      ) : (
        <div style={{ textAlign: "center", margin: "10px 0" }}>
          No Coupons Found
        </div>
      )}
    </div>
  );
}
