import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EntityService, PlanService } from "../../../../services";
import { showSuccessMessage } from "../../../../managers/utility";

const AddPlansForCorporateModal = ({
  isOpen,
  onClose,
  selectedEntity,
  getCorporateData
}) => {

  const [formData, setFormData] = useState({
    plans: [],
    noOfLicense: null,
    selectedPlan: null
  })



  const confirmAddLicense = async () => {
    try {

      if (isNaN(formData.noOfLicense)) {
        return;
      }
      const saved = await EntityService.addEntityLicense({
        numberOfLicense: parseInt(formData.noOfLicense),
        entityId: selectedEntity._id,
        planId: formData.selectedPlan
      });
      getCorporateData();
      if (saved && saved._id) {
        showSuccessMessage("Updates successfully");
      }

    }
    catch {
      console.log("Error")
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    const plans = await PlanService.getAllPlans({ isDeleted: false });
    setFormData((prevData) => ({ ...prevData, plans }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };




  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            borderRadius: "8px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            maxWidth: "900px",
            backgroundColor: "white",
            padding: "30px",
            maxHeight: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography variant="h5" gutterBottom>
                Add Plan
              </Typography>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabel>Plan</InputLabel>
            <Select
              name="selectedPlan"
              value={formData.selectedPlan}
              label="Type"
              onChange={handleInputChange}
            >
              {formData.plans.map((plan) => (
                <MenuItem key={plan._id} value={plan._id}>
                  {plan.plansType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Numbers of License"
            value={formData.noOfLicense}
            name="noOfLicense"
            onChange={(e) => handleInputChange(e)}
            fullWidth
            margin="normal"
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <Button
            style={{ marginTop: 14 }}
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => {
              confirmAddLicense();
              onClose();
              getCorporateData()
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddPlansForCorporateModal;
