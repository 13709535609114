import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function ParticipantAnswer({ selectedSession }) {
  console.log(selectedSession);
  return (
    <div>
      <Accordion sx={{ mt: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          User's Answers
        </AccordionSummary>
        <AccordionDetails>{selectedSession && selectedSession.userResponses ? <div>
            {selectedSession.userResponses.map ((eachResponse) => (
                <div style={{marginBottom:"10px"}}>
                    <span>
                    Q. - {eachResponse.questionText}
                    </span>
                    <div>
                    A. - {(eachResponse.response === "0001") ? "No Response" : eachResponse.response}
                    </div>
                </div>
            ))}
        </div> : <>No Responses</>}</AccordionDetails>
      </Accordion>
    </div>
  );
}
