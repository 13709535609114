import React, { useEffect, useState } from "react";
import { QuestionsService } from "../../../services";
import DetailsView from "./DetailsView";
import TableView from "./TableView";
import PaidSessionReport from "../../Session/PaidSessionReport/PaidSessionReport";
import { usePDF } from "react-to-pdf";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomLoader from "../../../common/customLoader";
import { useAuth } from "../../../AuthContext";

export default function SessionDash() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedSession, setSelectedSession] = useState(null);
  const [openReport, setOpenReport] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userDetails, getUserDetails } = useAuth();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount,setTotalCount] = useState(0)

  const { targetRef } = usePDF({ filename: "page.pdf" });
  
  useEffect(() => {
    if(userDetails === "NOT-SET")
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    getSessionData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]); // Include rowsPerPage in the dependency array

  const getSessionData = async () => {
    setLoading(true);
    try {
      // const skipItem = page * rowsPerPage;
      const response = await QuestionsService.getAllSessions(10, page);
      if (response && response.data && response.data.length > 0) {
        setData(response.data);
        setTotalCount(response.totalCount)
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div style={{ marginTop: 0, position: "relative",  }}>
      {selectedSession ? (
        openReport ? (
          <>
            <div
              style={{
                position: "fixed",
                top: 5,
                marginTop: 60,
                zIndex: 100,
                backgroundColor: "white",
                padding: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "-webkit-fill-available",
              }}
            >
              <Button
                onClick={() => {
                  setSelectedSession(null);
                  setOpenReport(false);
                }}
                variant="contained"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </div>

            <div ref={targetRef} style={{ padding: "30px" }}>
              <div style={{ pageBreakAfter: "always" }}>
                <PaidSessionReport
                  selectedSession={selectedSession}
                  showReport={true}
                  // reportValue={reportValue}
                />
              </div>
            </div>
          </>
        ) : (
          <DetailsView
            setSelectedSession={setSelectedSession}
            selectedSession={selectedSession}
          />
        )
      ) : (
        <>
          {loading ? (
            <CustomLoader />
          ) : (
            <TableView
              page={page}
              setPage={setPage}
              data={data}
              setSelectedSession={setSelectedSession}
              openReport={openReport}
              setOpenReport={setOpenReport}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalCount={totalCount}
              setTotalCount={setTotalCount}
              // setReportValue={setReportValue}
              // reportValue={reportValue}
            />
          )}
        </>
      )}
    </div>
  );
}
