import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { decodeToken } from "react-jwt";
import Employees from "./CorporateDashboard/ListTable";
import { useNavigate } from "react-router-dom";
import { EntityService } from "../../services";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";


const drawerWidth = 240;

export default function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("EMPLOYEES");
  const [userData, setUserData] = useState("");


  const verifyJWT = async () => {
    let token = localStorage.getItem("loginToken") || "";
    if (token) {
      let decodedToken = decodeToken(token);
      let userData = await EntityService.getCorporateById(
        decodedToken.email
      );
      setUserData(userData);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    // verifyJWT();
    if (!localStorage.getItem("hasReloadLayout")) {
      localStorage.setItem('hasReloadLayout', 'true')
      window.location.reload();
    }
  }, []);

  let component;
  switch (activeTab) {
    case "EMPLOYEES":
      component = <Employees userData={userData} reload={verifyJWT} />;
      break;
    case "CRUD":
      break;
    default:
      break;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, backgroundColor: "#f3f3f3", borderRight: "2px solid #e8e8e8" }}
        aria-label="mailbox folders"
      >
        <div>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ backgroundColor: activeTab === "CRUD" ? "#cfcfcf" : "" }}
                onClick={() => setActiveTab("EMPLOYEES")}
              >
                <BusinessCenterIcon sx={{ marginRight: 1 }} />
                <ListItemText primary="Users" />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Box>
      <Box
        // component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          marginTop: 8,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {component}
      </Box>
    </Box>
  );
}
