import { sessionManager } from "../managers/sessionManager";
import { cookiesConstants } from "../constants";
import { httpConstants } from "../constants";

export function getHeaders() {
  return { "Content-Type": httpConstants.CONTENT_TYPE.APPLICATION_JSON, skip: true, "Access-Control-Allow-Origin": "*", Authorization: `${getAccessToken()}` };
}

export function getAccessToken() {
  let token = localStorage.getItem('token')
  return token
}
export function getUserId() {
  return sessionManager.getDataFromCookies(cookiesConstants.USER_ID);
}
