import { Button, Chip, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Profile from "../Session/Models/ProfilePopper";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../custom.css";
import { useAuth } from "../../AuthContext";
import HomeIcon from "@mui/icons-material/Home";
import { useState } from "react";
import LoginForm from "../Session/Models/AuthModal";
import ChangePasswordModal from "./ChangePassword";
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import StreamIcon from '@mui/icons-material/Stream';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import { UserProfileService } from "../../services";
import { showErrorMessage } from "../../managers/utility";

export default function Header() {


  let environment = process.env.REACT_APP_ENVIRONMENT;

  const {
    userDetails,
    viewLoginModal,
    setViewLoginModal,
    setUserDetails
  } = useAuth();

  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData(userDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const ShowLabelOfEntity = (type) => {
    return ["PSYCHOLOGIST", "CORPORATE", "HOSPITAL",].includes(type)
  }


  const changeLanguage = async (e) => {
    if (userDetails && userDetails._id) {
      const updatedUserValue = await UserProfileService.updateUserLanguageWeb({ selectedLanguage: e.target.value, userId: userDetails._id })
      if (updatedUserValue && updatedUserValue._id) setUserDetails(updatedUserValue)
    } else {
      console.log(userDetails)
      showErrorMessage("Please Log in to select Language")
    }
  }

  return (
    <header style={{
      position: "sticky",
      top: 0,
      zIndex: 1000
    }}>

      {userDetails && userDetails.isVerified === false && (
        <div style={{ backgroundColor: '#157feb', textAlign: 'center' }}>
          <Typography style={{ color: "white", cursor: "pointer" }}>Your email address has not been verified. A verification email has been dispatched to your provided email address. Please check your email to complete the verification process. If you have not received the email, you may request a new verification email here</Typography>
        </div>
      )}
      <nav
        className="navbar navbar-light bg-white shadow-sm"
        style={{ display: "flex" }}
      >
        <ChangePasswordModal />
        <div className="container" style={{}}>
          <div className="sessiondrawer">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "10px" }}>
                <Chip sx={{ padding: 1 }} icon={environment === "STAGING" ? <DeveloperModeIcon /> : environment === "PRODUCTION" ? <StreamIcon /> : <DeviceUnknownIcon />}
                  label={environment === "STAGING" ? "Demo 1.0.0" : environment === "PRODUCTION" ? "Live 1.0.0" : "Localhost"} variant="outlined" />
              </div>
              <Button
                onClick={() => (window.location.href = "/")}
                style={{ color: "#ffcf00", fontSize: 20 }}
                startIcon={<HomeIcon sx={{ fontSize: 40 }} />}>

                Home
              </Button>
            </div>

          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a className="navbar-brand" href="/">
              <img
                alt="filler"
                src="/images/aipsychi_without_logo.png"
                className="img-fluid"
              />
            </a>
            {ShowLabelOfEntity(userDetails.type || "") ? (
              <div style={{ fontWeight: "600", fontSize: "25px", color: "#1876d2" }}>
                •  &nbsp;
                {userDetails.name}
              </div>
            ) : ""}
          </div>
          <div className="d-flex">
            <div className="align-self-start" style={{ marginRight: "5px" }}>
              <FormControl size="small" sx={{ width: "150px" }}>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={(userDetails && userDetails.webSelectedLangauge) ? userDetails.webSelectedLangauge : "EN_US"}
                  label="Language"
                  onChange={changeLanguage}
                >
                  <MenuItem value={"EN_US"}>{`English (US)`}</MenuItem>
                  <MenuItem value={"EN_IN"}>{`English (India)`}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Profile
              className="align-self-start"
              userData={userData}
              setViewLoginModal={setViewLoginModal}
              viewLoginModal={viewLoginModal}
            ></Profile>

            {viewLoginModal && (
              <LoginForm
                open={viewLoginModal}
                onClose={() => {
                  setViewLoginModal(false);
                }}
              />
            )}
          </div>
        </div>
      </nav>
    </header >
  );
}
