import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomLoader from "../../../../common/customLoader";
import moment from "moment/moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import UpdateVersionModal from "./UpdateVersionModal";

export default function Backend({
  arrayName,
  dataTable,
  getTable,
  isLoading,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to hold the selected item for update

  useEffect(() => {
    getTable("BACKEND");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}

      {dataTable && (
        <div>
          {arrayName.map((item) => (
            <Accordion
              key={item._id}
              style={{
                margin: "20px 10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginRight: 10

                  }}
                >
                  <div style={{ width: "18%" }}>
                    <Typography>{item.name}</Typography>
                  </div>
                  <div>
                    <strong>Version :</strong> {item.version}
                  </div>
                  <div>
                    <strong>Type :</strong> {item.type}
                  </div>
                  <div>
                    <strong>Release Date & Time : </strong>
                    {item?.createdAt
                      ? moment(item.createdAt).format("DD MMM YYYY")
                      : "NA"}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <strong>Release Note :</strong> {item.releaseNote}
                </div>
                <div style={{ textAlign: 'end' }}>
                  <Button
                    sx={{ mt: 2, flex: 1 }}
                    variant="contained"
                    onClick={() => handleOpenModal(item)}
                  >
                    Update Version
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}

      {/* Modal for updating version */}
      <UpdateVersionModal
        open={openModal}
        onClose={handleCloseModal}
        title="Update Backend Version"
        selectedItem={selectedItem}
        getTable={() => getTable("BACKEND")}
      />
    </>
  );
}
