import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MessageIcon from "@mui/icons-material/Message";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EventIcon from "@mui/icons-material/Event";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import couponImg from "../../../assets/couponImg.svg";
import moment from "moment";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DiscountIcon from "@mui/icons-material/Discount";


// let primaryColor = "#4059AD";
// let secondaryColor = "#6B9AC4";
let primaryColor = "#white";



const MySubscription = ({ subscription }) => {
  // const [todayMessageCount, setTodayMessageCount] = useState(0);

  const plansDetails = subscription?.planId?.plansDetails;
  // const getChats = async () => {
  //   try {
  //     if (userDetails && userDetails._id) {
  //       const response = await settingsModule.getChatWithLLM({
  //         userId: userDetails._id,
  //       });
  //       if (response && response._id) {
  //         let totalCount = response.totalCount[0]?.todayMessageCount || 0;
  //         setTodayMessageCount(totalCount);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching chat count:", error);
  //   }
  // };

  // useEffect(() => {
  //   getChats();
  // }, []);

  const renderBlocks = [
    {
      name: "Session Count",
      icon: <AssessmentIcon sx={{ fontSize: 38, marginRight: "10px", color: '#ffcf00' }} />,

      value: (subscription.planId && subscription.planId.sessionCount) ? subscription.planId.sessionCount : 0,
      inCircle: true
    },
    {
      name: "Message Count",
      icon: <MessageIcon sx={{ fontSize: 38, marginRight: "10px", color: '#ffcf00' }} />,

      value: (subscription.planId && subscription.planId.messageCount) ? subscription.planId.messageCount : 0,
      inCircle: true
    },
    {
      name: "Expiry Date",
      value:
        subscription && subscription.renewalDate
          ? moment(subscription.renewalDate).format("DD MMM YYYY")
          : "N/A",
      icon: <EventIcon sx={{ fontSize: 38, marginRight: "10px", color: '#ffcf00' }} />,
      inCircle: false,
    },
    {
      name: "Price",
      value: subscription && subscription.price ? subscription.price : "N/A",
      icon: <CurrencyRupeeIcon sx={{ fontSize: 38, marginRight: "10px", color: '#ffcf00' }} />,
      inCircle: true,
    },
    ...(subscription?.couponId?.name &&
      subscription?.couponId?.discount &&
      subscription?.discountedPrice
      ? [
        {
          name: "Coupon",
          value: subscription?.couponId?.name,
          icon: <DiscountIcon sx={{ fontSize: 38, marginRight: "10px", color: '#ffcf00' }} />,
          inCircle: false,
        },
        {
          name: "Discounted Price",
          value: subscription?.discountedPrice,
          icon: <LoyaltyIcon sx={{ fontSize: 38, marginRight: "10px", color: '#ffcf00' }} />,
          inCircle: true,
        },
        {
          name: "Discount",
          value: subscription?.couponId?.discount,
          icon: <BeenhereIcon sx={{ fontSize: 38, marginRight: "10px", color: '#ffcf00' }} />,
          inCircle: true,
        },
      ]
      : []),
  ];

  return (
    <div style={{
      position: "relative",
      background: subscription.isDiscounted
        ? "radial-gradient(circle at 10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%)"
        : "white",
      boxShadow: "0px 2px 4px rgba(1, 1 ,1, 0.6)", margin: "20px 10px"
    }}>
      {subscription.isDiscounted && (
        <img
          src={couponImg}
          alt="Coupon"
          style={{
            position: "absolute",
            zIndex: 1,
            height: "50px",
          }}
        />
      )}
      {subscription.planId ? (
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{
                fontSize: "40px",
                color: '#252525'
              }} />
            }
            aria-controls="panel1-content"
            id="panel1-header"
            style={{ borderBottom: "1px solid #ffcf00", }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", }}>
              <div style={{ width: "100%", display: "flex", }}>
                <div style={{ width: "40%", marginLeft: 30 }}>
                  <Typography variant="h6">
                    <b> Subscription : </b> {subscription.name}
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1">
                    <b> Status : </b>
                    <Button
                      disabled
                      variant="contained"
                      size="small"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: "#6EC531",
                        color: "white",
                      }}
                    >
                      Active
                    </Button>
                  </Typography>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{ width: "100%" }}
          >
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Grid container spacing={2}>
                  {renderBlocks.map((subItem) => (
                    <Grid item lg={6}>
                      <Paper
                        elevation={3}
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          height: "120px",
                          backgroundColor: primaryColor,
                          justifyContent: "space-between",
                          padding: "20px",
                          margin: "10px",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <div style={{ display: "flex" }}>
                            {subItem.icon}
                            <div style={{ fontSize: "20px", flex: 1, display: "flex", justifyContent: "flex-end" }}>
                              <Typography fontWeight={600} variant="h5">
                                {subItem.value}
                              </Typography>
                            </div>
                          </div>
                          <div style={{ display: "flex", height: "60px", width: "100%", alignItems: "flex-end" }}>
                            <Typography variant="h5">
                              <b>{subItem.name} </b>
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item lg={6}>
                <Paper
                  elevation={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // background: "rgb(0, 140, 242)",
                    background: "linear-gradient(63deg, rgba(0, 140, 242, 1) 0%, rgba(255, 255, 255, 0) 0%, rgba(241, 241, 241, 1) 33%, rgba(255, 207, 86, 1) 100%)",
                    justifyContent: "space-between",
                    padding: "20px",
                    margin: "10px",
                  }}
                >
                  <Typography sx={{ padding: 3 }} variant="h6">
                    Features you got with subscriptions
                  </Typography>
                  {plansDetails.map((detail, index) => (
                    <Typography variant="h6" style={{ marginBottom: "10px" }}>
                      💛 &nbsp;{detail}
                    </Typography>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography variant="subtitle1">
          Subscription information not available
        </Typography>
      )
      }
    </div >
  );
};

export default MySubscription;


