import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import settingsModule from "../../../services/settings";
import { showErrorMessage } from "../../../managers/utility";
import MobileLLMPrompt from "./MobileLLMPrompt";
import { Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

export default function TimerValue() {
  const [timerValues, setTimerValues] = useState({
    LOW: null,
    MEDIUM: null,
    HIGH: null,
    HIGHEST: null,
  });
  const [emailState, SetEmailState] = useState(false);
  const [applePay, SetApplePayState] = useState(false);
  const [cacheState, setCacheState] = useState(false);

  const handleTimerChange = (event, label) => {
    setTimerValues((prevValues) => ({
      ...prevValues,
      [label]: parseInt(event.target.value),
    }));
  };
  const getCurrentValue = async () => {
    try {
      const setting = await settingsModule.getSettings();
      if (setting && setting._id) {
        setTimerValues(setting.timerValue);
        SetEmailState(setting.useEmailService);
        setCacheState(setting.cacheProcessor);
        SetApplePayState(setting.useApplePay);
      }
    } catch (error) {
      showErrorMessage("Error occurred while fetching timer value.");
    }
  };

  useEffect(() => {
    getCurrentValue();
  }, []);

  const getPAYSetting = async (timerValues) => {
    try {
      await settingsModule.updateSettings({
        timerValue:timerValues
      });
      getCurrentValue();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const getEmailService = async (event) => {
    SetEmailState(event.target.checked);
    try {
      await settingsModule.updateSettings({
        useEmailService: event.target.checked,
      });
      getCurrentValue();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const getApplePayService = async (event) => {
    SetApplePayState(event.target.checked);
    try {
      await settingsModule.updateSettings({
        useApplePay: event.target.checked,
      });
      getCurrentValue();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const getCacheProcessor = async (event) => {
    setCacheState(event.target.checked);
    try {
      // await settingsModule.updateSettings({
      //   useCache: event.target.checked,
      // });
      getCurrentValue();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <>
      <div style={{ marginTop: "50px" }}>
        <div style={{ margin: "30px 0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Email Service</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch checked={emailState} onChange={getEmailService} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Apply Pay on iOS</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch checked={applePay} onChange={getApplePayService} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Cache Processor</Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch checked={cacheState} onChange={getCacheProcessor} />
            </Grid>
          </Grid>
        </div>

        <Typography>Timer Value</Typography>
        <TextField
          sx={{ mt: 2, mr: 2 }}
          id="outlined-basic"
          type="number"
          label="LOW"
          variant="outlined"
          value={timerValues.LOW}
          InputLabelProps={{
            shrink: timerValues.LOW !== undefined && timerValues.LOW !== null, // Conditionally apply shrink based on timerValues.HIGHEST
          }}
          onChange={(e) => handleTimerChange(e, "LOW")}
        />
        <TextField
          sx={{ mt: 2, mr: 2 }}
          id="outlined-basic"
          type="number"
          label="MEDIUM"
          variant="outlined"
          InputLabelProps={{
            shrink:
              timerValues.MEDIUM !== undefined && timerValues.MEDIUM !== null, // Conditionally apply shrink based on timerValues.HIGHEST
          }}
          value={timerValues.MEDIUM}
          onChange={(e) => handleTimerChange(e, "MEDIUM")}
        />
        <TextField
          sx={{ mt: 2, mr: 2 }}
          id="outlined-basic"
          label="HIGH"
          type="number"
          variant="outlined"
          InputLabelProps={{
            shrink: timerValues.HIGH !== undefined && timerValues.HIGH !== null, // Conditionally apply shrink based on timerValues.HIGHEST
          }}
          value={timerValues.HIGH}
          onChange={(e) => handleTimerChange(e, "HIGH")}
        />
        <TextField
          sx={{ mt: 2, mr: 2 }}
          id="outlined-basic"
          type="number"
          label="HIGHEST"
          variant="outlined"
          value={timerValues.HIGHEST}
          InputLabelProps={{
            shrink:
              timerValues.HIGHEST !== undefined && timerValues.HIGHEST !== null, // Conditionally apply shrink based on timerValues.HIGHEST
          }}
          onChange={(e) => handleTimerChange(e, "HIGHEST")}
        />
        <Button
          variant="contained"
          style={{ marginTop: 26 }}
          onClick={(e) => getPAYSetting(timerValues)}
        >
          Update
        </Button>
      </div>

      <MobileLLMPrompt />
    </>
  );
}
