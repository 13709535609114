import { Modal } from "@mui/material";
import React from "react";
import QuillViewer from "../../Dashboard/Events/Order/viewer";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DisorderModalComponent = ({ disorder, isOpen, description, onClose }) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        BackdropProps={{
          onClick: onClose, // Disable backdrop click
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            borderRadius: "8px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            maxWidth: "900px",
            backgroundColor: "white",
            padding: "50px",
            overflow: "scroll",
            maxHeight: "70%"
          }}
        >
          {(disorder && description) ?
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="h5" gutterBottom>
                    {disorder}
                  </Typography>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <QuillViewer content={description} />
            </div> :
            "No Information Found On This Disorder"}
        </div>
      </Modal>
    </div>
  );
};

export default DisorderModalComponent;
