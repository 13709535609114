import React, { useState, useEffect } from "react";
import { EntityService } from "../../../services";
import CorporateModal from "./AddEmployees";
import { useAuth } from "../../../AuthContext";
import {
  Button,
  Card,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { showErrorMessage, showSuccessMessage } from "../../../managers/utility";
import ReportListModal from "./ReportListModal";

const DataTable = ({
  couponList,
  data,
  setCouponList,
  getCouponbyEntity,
  getCorporateData,
}) => {
  const { userDetails } = useAuth();
  const [openReportsModal, setOpenReportsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleCouponSelect = async (event, row, value) => {
    const selectedCouponCode = value;

    if (selectedCouponCode) {
      const payload = {
        assigneeId: row._id,
        entityId: selectedCouponCode._id,
      };
      try {
        const response = await EntityService.assignedCouponForEntity(payload);
        if (response && response.assigneeId) {
          showSuccessMessage("Coupon Assigned Successfully");
          getCouponbyEntity();
          getCorporateData();
        }
      } catch (error) {
        console.error("Error assigning coupon:", error);
      }
    }
  };

  const filterData = (text) => {
    const filtered = data.filter(
      (row) =>
        row.name.toLowerCase().includes(text.toLowerCase()) ||
        row.email.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSearchChange = (event) => {
    const text = event.target.value;
    setSearchText(text);
    filterData(text);
  };

  useEffect(() => {
    filterData(searchText);



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleRemoveDisabledCoupon = async (couponCodeToRemove, row) => {
    if (couponCodeToRemove) {
      const payload = {
        assigneeId: couponCodeToRemove.assigneeId,
        couponId: couponCodeToRemove._id,
      };
      try {
        const response = await EntityService.deleteAssignedCouponForEntity(
          payload
        );
        if (response && response.assigneeId === null) {
          getCouponbyEntity();
          getCorporateData();
          showSuccessMessage("Assigned ID Successfully Deleted");
          const updatedCouponList = couponList.filter(
            (coupon) => coupon.couponCode !== couponCodeToRemove
          );
          setCouponList(updatedCouponList);
        }
      } catch (error) {
        console.error("Error deleting assigned ID:", error);
      }
    }
  };

  const getCurrentValue = (row) => {
    if (couponList) {
      let selected = couponList.find((ele) => row._id === ele.assigneeId?._id);
      return selected;
    }
  };

  const getOptionDisabled = (option, row) =>
    couponList.some((coupon) => {
      return (
        coupon.couponCode === option.couponCode &&
        (coupon.assigneeId ||
          coupon.assigneeId?._id === row._id ||
          coupon.isUsed)
      );
    });

  const resetPasswordForEntityUser = async (row) => {
    try {
      const response = await EntityService.resetEntityUserPassword({ userId: row._id });
      if (response)
        showSuccessMessage("Password Reset Mail Sent.")
    } catch (error) {
      showErrorMessage("Error, Please Try Again")
    }
  }


  const sendEmailToUsers = async () => {
    let requestData = filteredData.filter((item) => (item.isSelectedForEmail)).map((item) => ({ id: item._id }))
    if (!requestData.length) {
      showErrorMessage("Please select users")
      return
    }
    const response = await EntityService.sendCustomEmailFromEntity({ userIdList: requestData });
    if (response.message === "Emails have been sent") {
      showSuccessMessage("Emails have been sent")
    }

  }

  const selectEntitiesForEmail = (e, row) => {
    let tempData = [...filteredData];
    console.log(tempData)
    let resultArray = tempData.map((item) => {
      if (item._id === row._id) {
        let updatedItem = { ...item, isSelectedForEmail: e.target.checked }
        return updatedItem
      } else return item
    })
    setFilteredData(resultArray)
  }


  return (
    <div>
      <div
        style={{
          display: "flex",
          paddingTop: 4,
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <>
          <Button variant="contained" onClick={() => sendEmailToUsers()}>
            Send Email
          </Button>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
            style={{ marginBottom: "16px" }}
          />
        </>
      </div>
      {filteredData.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Name
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", fontSize: "20px" }}
                    align="center"
                  >
                    Email
                  </TableCell>
                  {userDetails.type !== "CORPORATE" && (
                    <>
                      <TableCell
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                        align="right"
                      >
                        Selected Coupon
                      </TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                  {userDetails.showReports && (
                    <TableCell align="center">
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        View Report
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell
                    style={{ fontWeight: "bold", fontSize: "20px" }}
                    align="center"
                  >
                    Reset Password
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell><Checkbox checked={row.isSelectedForEmail} onChange={(e) => selectEntitiesForEmail(e, row)} /></TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    {userDetails.type !== "CORPORATE" && (
                      <>
                        {couponList.length > 0 &&
                          userDetails.type !== "CORPORATE" ? (
                          <>
                            <TableCell sx={{ minWidth: "220px" }} align="right">
                              {couponList.length > 0 && (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  options={couponList.map((coupon) => coupon)}
                                  onChange={(event, value) =>
                                    handleCouponSelect(event, row, value)
                                  }
                                  getOptionDisabled={(option) =>
                                    getOptionDisabled(option, row)
                                  }
                                  disabled={couponList.some(
                                    (item) => item.assigneeId?._id === row._id
                                  )}
                                  value={getCurrentValue(row)}
                                  getOptionLabel={(option) => {
                                    return `${option.name} - ${option.discount
                                      }% ${option?.isUsed
                                        ? "Consumed"
                                        : option.assigneeId
                                          ? "Assigned"
                                          : ""
                                      }`;
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Coupon" />
                                  )}
                                />
                              )}
                            </TableCell>
                            {getCurrentValue(row) ?
                              <TableCell align="left">
                                <span
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: 5,
                                    width: "100%",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoveDisabledCoupon(
                                      getCurrentValue(row),
                                      row
                                    );
                                  }}
                                >
                                  &#x2715;
                                </span>
                              </TableCell> :
                              <TableCell>

                              </TableCell>
                            }
                          </>
                        ) : (
                          <>
                            <TableCell align="right">
                              No Coupons To Select
                            </TableCell>
                          </>
                        )}
                      </>
                    )}
                    {userDetails.showReports && (
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setOpenReportsModal(true);
                            setSelectedUser(row);
                          }}
                        >
                          View Reports
                        </Button>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Button onClick={() => resetPasswordForEntityUser(row)} variant="contained">Reset Password</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <ReportListModal
              selectedUser={selectedUser}
              open={openReportsModal}
              close={() => setOpenReportsModal(false)}
            />
          </TableContainer>
        </>
      ) : (
        <Paper style={{ padding: "10px 0" }}>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            No Data Found
          </Typography>
        </Paper>
      )}
    </div>
  );
};

const App = ({ reload }) => {
  const [apiData, setApiData] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const { userDetails } = useAuth();

  useEffect(() => {
    if (userDetails._id) {
      getCorporateData();
      getCouponbyEntity();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const getCorporateData = async () => {
    try {
      const response = await EntityService.getEntityEmployees(
        userDetails._id || ""
      );
      setApiData(response);
    } catch (error) {
      console.error("Error fetching corporate data:", error);
    }
  };

  const getCouponbyEntity = async () => {
    const payload = {
      entityId: userDetails._id || "",
    };
    try {
      const response = await EntityService.getCouponbyEntity(payload);
      setCouponList(response);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  const openAddModal = (type) => {
    setIsModalOpen(true);
    setType(type);
  };

  const closeAddModal = () => {
    reload();
    getCorporateData();
    setIsModalOpen(false);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Card style={{ padding: "10px", backgroundColor: "#ffcf03" }}>
          <div>Name : {userDetails.name}</div>
          <div>Email : {userDetails.email}</div>
          {userDetails.type === "CORPORATE" && (
            <div>Remaining License : {userDetails.remainingLicense}</div>
          )}
        </Card>
        <div>
          <Button
            variant="contained"
            sx={{ mt: 1, mb: 2, width: 100 }}
            onClick={() => {
              openAddModal(userDetails.type);
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <DataTable
        couponList={couponList}
        data={apiData}
        setCouponList={setCouponList}
        getCouponbyEntity={getCouponbyEntity}
        getCorporateData={getCorporateData}
      />
      <CorporateModal
        getCorporateData={getCorporateData}
        isOpen={isModalOpen}
        onClose={closeAddModal}
        corporateId={userDetails._id}
        type={type}
      />
    </div>
  );
};

export default App;
