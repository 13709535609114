import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../custom.css";
import Session from "./Session";
import React, { useEffect } from "react";
import { SettingsService } from "../../services";
import LoginForm from "./Models/AuthModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import HomeCards from "../Dashboard/Events/HomeCards";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../custom.css";
import ReadyForSessionModal from "./ReadyForSessionModal";

const App = () => {
  const navigate = useNavigate();
  const {
    userDetails,
    getUserDetails,
    inputMode,
    viewLoginModal,
    setViewLoginModal,
    sessionStarted,
    setSessionStarted,
  } = useAuth();
  const [useLLM] = useState(false);
  const [recievedSettings, setRecievedSettings] = useState(false);
  const [useTrial, setUseTrial] = useState(false);
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);
  const [didClickedOnTrial] = useState(false);
  const [loadingSessionDetails] = useState(true);
  const [showMessage] = useState(true);
  const [ableToResumeSession] = useState("NONE");

  useEffect(() => {
    setUserData(userDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleClose = () => {
    setUseTrial(false);
    setOpen(false);
  };

  const verifyJWT = async () => {
    let details = localStorage.getItem("userDetails") || "";
    if (details) {
      if (details.type && details.type === "CORPORATE") navigate("/corporate");
    }
  };

  useEffect(() => {
    getUserDetails();
    getCurrentSetting();
    verifyJWT();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentSetting = async () => {
    const setting = await SettingsService.getSettings();
    if (setting && setting._id) {
      // setUseLLM(setting.useLLM);
      // setUseTrial(setting.useTrial);
      setRecievedSettings(true);
    }
  };

  const checkLoginBeforeSession = async (clickedOnTrial) => {
    setSessionStarted(true);
    return;
  };

  return (
    <>
      {viewLoginModal && (
        <LoginForm
          open={viewLoginModal}
          onClose={() => {
            setViewLoginModal(false);
          }}
        />
      )}

      {!sessionStarted ? (
        <div id="launchContent">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-7">
                <div className="bnrheading">
                  <h2>
                    Welcome
                    <b className="fw-bold text-warning">{userData?.name}</b> to
                    the amazing world of
                    <b className="fw-bold text-primary">Maya</b>
                  </h2>
                  <h6 className="text-secondary lead fs-5">
                    Maya is here to helping you uncover personalized trends and
                    tendencies, mapping them to potential mental health
                    disorders to enhance your well-being and empower you towards
                    a more fulfilling life.
                  </h6>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <HomeCards
                      checkLoginBeforeSession={checkLoginBeforeSession}
                    />
                  </div>
                  <ReadyForSessionModal
                    setUseTrial={setUseTrial}
                    didClickedOnTrial={didClickedOnTrial}
                    userDetails={userDetails}
                    ableToResumeSession={ableToResumeSession}
                    s
                    showMessage={showMessage}
                    open={open}
                    handleClose={handleClose}
                    useTrial={useTrial}
                    setSessionStarted={setSessionStarted}
                    loadingSessionDetails={loadingSessionDetails}
                  />
                </div>
              </div>
              <div className="col-md-5 ms-md-auto d-flex align-items-end">
                <img
                  alt="filler"
                  src="/images/maya.png"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Session
          useLLM={useLLM}
          useTrial={useTrial}
          inputMode={inputMode}
          recievedSettings={recievedSettings}
        />
      )}
    </>
  );
};

export default App;