import React from 'react'
import Typewriter from "typewriter-effect";
import "../../../App.css";
import { Button, Typography } from "@mui/material";
import PauseModal from "./PauseModal";
import PaidSessionReport from "../PaidSessionReport/PaidSessionReport";
import { showErrorMessage } from '../../../managers/utility';
import { SESSION_MODE, useAuth } from '../../../AuthContext';
import { grey } from '@mui/material/colors';


export default function SessionUI({
    AudioPlayer,
    completed,
    submitQuestionForcefully,
    sessionPauseHandler,
    question,
    setControlsVisible,
    setSessionPaused,
    instructions,
    createBoldText,
    setPatientAnswerBox,
    patientAnswerBox,
    sessionPaused,
    listening,
    setPatientAnswer,
    inputMode,
    inputRef,
    patientAnswer,
    submitInProcess,
    controlsVisible,
    editButtonHandler,
    skipButtonHandler,
    seconds,
    listenerState,
    noFinalData,
    final,
    enableFieldAndFocus,
    progressBar
}) {

    const { sessionMode } = useAuth()
    return (
        <>
            <div id="startSession1">
                <AudioPlayer
                    style={{ display: "none" }}
                    src={
                        "https://demoplayground4093662547.z20.web.core.windows.net/us-en/Code001.mp3"
                    }
                />
                <div className="container my-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card shadow-sm ">
                                <div className="card-body p-4" id="submit-form">
                                    {!completed ? (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                }}
                                            >
                                                {/* For Testing In Live Mode  */}
                                                {localStorage.getItem("TESTSUBMITBUTTON") ?
                                                    <button
                                                        style={{ marginRight: 3 }}
                                                        onClick={() => submitQuestionForcefully()}
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        id="submit-button"
                                                    >
                                                        Submit
                                                    </button> : ""
                                                }
                                                <Button
                                                    onClick={sessionPauseHandler}
                                                    variant="contained"
                                                    sx={{ width: 100 }}
                                                >
                                                    Pause
                                                </Button>
                                                <Button
                                                    sx={{ marginLeft: 2, width: 100 }}
                                                    onClick={() => window.location.href = "/"}
                                                    variant="outlined"
                                                >
                                                    Exit
                                                </Button>
                                                <PauseModal
                                                    closeModal={() => {
                                                        setControlsVisible(true);
                                                        setSessionPaused(false);
                                                        setPatientAnswerBox(true);
                                                        showErrorMessage("Please write your response and submit")
                                                        enableFieldAndFocus()
                                                    }}
                                                    showModal={sessionPaused}
                                                />
                                            </div>
                                            <div className="" style={{ minHeight: "76px" }}>
                                                {instructions === "NULL" ? (
                                                    ""
                                                ) : (
                                                    <>
                                                        <h5 className="mb-2 text-info">Instruction</h5>
                                                        <p
                                                            className="border border-info p-2 rounded"
                                                            id="chat1"
                                                        >
                                                            <div>{createBoldText(instructions)}</div>
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="">
                                                <h5 className="mb-2 text-warning">Question</h5>
                                                <div id="typewriter">
                                                    <p
                                                        id="text"
                                                        className="border border-warning p-2 rounded"
                                                    >
                                                        <Typewriter
                                                            onInit={(typewriter) => {
                                                                if (question !== "") {
                                                                    typewriter.typeString(question).start().callFunction(() => {
                                                                        if (sessionMode === SESSION_MODE.TEXT_MODE) {
                                                                            showErrorMessage("Please type your response")
                                                                            setControlsVisible(true);
                                                                            inputRef.current.focus()
                                                                        }
                                                                    }
                                                                    );
                                                                }
                                                            }}
                                                            key={question}
                                                            options={{
                                                                delay: 33,
                                                                cursor: "",
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <div className="">
                                                        <h5 className="mb-2 text-primary">
                                                            Answer
                                                            <small>
                                                                <span id="speakshow">
                                                                    (Try to Speak out your response)
                                                                    {listening ? (
                                                                        <span>
                                                                            <i class="fa fa-circle text-danger Blink"></i>
                                                                            Listening
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </span>

                                                                <span id="textshow" style={{ display: "none" }}>
                                                                    (Type your response below)
                                                                </span>
                                                            </small>
                                                        </h5>
                                                        <div
                                                            style={{ minHeight: "50px" }}
                                                            className="form-control border-primary p-2 rounded mb-0 bg-white"
                                                            id="chat3"
                                                            readOnly
                                                        >
                                                            <textarea
                                                                onChange={(e) =>
                                                                    setPatientAnswer(e.target.value)
                                                                }
                                                                disabled={
                                                                    inputMode === "VOICE" && !patientAnswerBox
                                                                }
                                                                ref={inputRef}
                                                                cols="120"
                                                                style={{ maxWidth: "100%" }}
                                                                rows={3}
                                                                className="patient-answer-box"
                                                                value={patientAnswer}
                                                            />
                                                        </div>
                                                        <div style={{ color: grey }}>
                                                            <span className='text-danger Blink'>Wait for listening indicator to light up before you start speaking.</span>
                                                            &nbsp; Do not user any Bluetooth device. In case your response is not correctly captured or not captured at all, please repeat or press edit to manually enter. If it has captured the key word, no need to repeat.
                                                        </div>
                                                        <div className="py-2 text-end">
                                                            <button
                                                                type="button"
                                                                className={`btn btn-success btn-sm`}
                                                                id="loader"
                                                                style={{
                                                                    display: submitInProcess ? "block" : "none",
                                                                }}
                                                            >
                                                                <span
                                                                    className="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                Processing...
                                                            </button>
                                                            <div
                                                                className={`animated ${controlsVisible ? "expanded" : ""
                                                                    }`}
                                                            >
                                                                {sessionMode === SESSION_MODE.SPEECH_MODE &&
                                                                    <button
                                                                        type="button"
                                                                        style={{ marginRight: 3, width: "100px" }}
                                                                        className="btn btn-primary btn-sm"
                                                                        id="editButton"
                                                                        onClick={editButtonHandler}
                                                                    >
                                                                        Edit / Text
                                                                    </button>}
                                                                <button
                                                                    style={{ marginRight: 3, width: "100px" }}
                                                                    onClick={() => submitQuestionForcefully()}
                                                                    type="button"
                                                                    className="btn btn-success btn-sm"
                                                                    id="submit-button"
                                                                >
                                                                    Submit
                                                                </button>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={skipButtonHandler}
                                                                    style={{ marginRight: 3, width: "100px" }}
                                                                >
                                                                    Skip
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <h5 className="mb-2 text-dark">Timer</h5>
                                                    <div
                                                        className="text-center shadow-sm pb-2 border-dark border rounded"
                                                        style={{ minHeight: "96px" }}
                                                    >
                                                        <div id="timer" className="fw-bold lead">
                                                            {seconds}
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-evenly pt-1">
                                                            <button
                                                                id="startButton"
                                                                style={{
                                                                    display:
                                                                        listenerState === "ENDED" &&
                                                                            patientAnswer === ""
                                                                            ? "block"
                                                                            : "none",
                                                                }}
                                                                className="py-0 btn btn-sm btn-primary"
                                                            >
                                                                <i className="fa-solid fa-play"></i>
                                                            </button>
                                                            <button
                                                                id="stopButton"
                                                                className="py-0 btn btn-sm btn-danger"
                                                                style={{
                                                                    display:
                                                                        listenerState === "STARTED"
                                                                            ? "block"
                                                                            : "none",
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-stop"></i>
                                                            </button>
                                                            <button
                                                                id="addTimeButton"
                                                                className="py-0 btn btn-sm btn-dark"
                                                                style={{ display: "none" }}
                                                            >
                                                                <i className="fa-solid fa-stopwatch-20"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Typography align="center">
                                                Your Session has been completed. Thank you
                                            </Typography>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="card shadow-sm ">
                                <div className="card-body">
                                    <div className="row d-flex justify-content-between align-items-center">
                                        <div className="col-md-12">
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    {(!completed  && progressBar > 0)&& (
                                                        <>
                                                        Session Progress {!isNaN(progressBar) ? `${parseInt(progressBar)}` : ""}%
                                                        <div className="progress" style={{ height: "20px" }}>
                                                            <div
                                                                className="progress-bar progress-bar-striped progress-bar-animated"
                                                                role="progressbar"
                                                                aria-valuenow="16"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                                style={{ width: `${progressBar}%` }}
                                                            ></div>
                                                        </div>
                                                        <div>Progress is indicative only and it will jump couple of times based on your answers</div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {
                                                (completed) ? (
                                                    <PaidSessionReport noFinalData={noFinalData} final={final} />
                                                ) : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
