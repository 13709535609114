import React, { useState } from "react";
import { Button, TextField, Typography, IconButton, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AuthUserService from "../../../services/authUser";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../managers/utility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function ForgetPassword({
  onClose,
  changeState,
  isOtp,
  setIsOtp,
  otp,
  setOtp,
  newPassword,
  setNewPassword,
  reEnterPassword,
  setReEnterPassword,
  emailForgot,
}) {
  const [showNewPassPin, setShowNewPassPin] = useState(false);
  const [showReEnterPassPin, setShowReEnterPassPin] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState("");

  const handleToggleVisibility = (field) => {
    if (field === "newPassword") {
      setShowNewPassPin((prevShowNewPassPin) => !prevShowNewPassPin);
    } else if (field === "reEnterPassword") {
      setShowReEnterPassPin(
        (prevShowReEnterPassPin) => !prevShowReEnterPassPin
      );
    }
  };

  const getOtp = async () => {
    const payload = {
      email: emailForgot,
      verificationCode: otp,
    };
    try {
      const response = await AuthUserService.GetConfirmOtp(payload);
      if (response && response.data.verificationCode) {
        setVerifyOtp(response.data.verificationCode);
        showSuccessMessage(response.message);
        setIsOtp(true);
        setOtp("");
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      console.error("Error fetching OTP:", error);
      showErrorMessage(error.message);
    }
  };

  const getResetPasswword = async () => {
    if (newPassword !== reEnterPassword) {
      showErrorMessage("Passwords do not match in Both Field");
      return; // Stop execution if passwords do not match
    }

    const payload = {
      email: emailForgot,
      verificationCode: verifyOtp,
      newPassword: newPassword,
    };
    try {
      const response = await AuthUserService.GetResetPassword(payload);
      if (response) {
        setIsOtp(true);
        setNewPassword("");
        setReEnterPassword("");
        showSuccessMessage(response.message);
        onClose();
      }
    } catch (error) {
      console.error("Error fetching OTP:", error);
      showErrorMessage(error.message);
    }
  };
  return (
    <div
      style={{
        background: "#fff",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "400px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            color: "#1877f2",
            textAlign: "center",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          Forgot Password
        </h3>
        <ClearIcon color="primary" onClick={onClose} />
      </Box>
      <Typography variant="h5" gutterBottom>
        <IconButton
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={onClose}
        ></IconButton>
      </Typography>
      {!isOtp && (
        <>
          <div style={{ textAlign: "-webkit-center", margin: "10px" }}>
            <Typography style={{ fontSize: 14 }} align="center">
              We've sent you an SMS code
            </Typography>
            {/* <br /> */}
            <Typography style={{ fontSize: 15 }} align="center">
              To complete the verification process please enter the 4 digit code
              below
            </Typography>
          </div>
          <TextField
            label="OTP"
            type="text"
            fullWidth
            margin="normal"
            value={otp}
            onChange={(e) => setOtp(e.target.value.toLowerCase())}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={getOtp}
            style={{ width: "100%", height: "50px", fontSize: "15px" }}
          >
            Verify OTP
          </Button>
        </>
      )}
      {isOtp && (
        <>
          <TextField
            sx={{ mb: 3 }}
            label="New Password"
            type={showNewPassPin ? "text" : "password"}
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleToggleVisibility("newPassword")}
                    edge="end"
                  >
                    {showNewPassPin ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              pattern:
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
              title:
                "Invalid password. It must have at least 8 characters, including 1 capital letter, 1 small letter, 1 number, and 1 special character.",
            }}
            helperText={
              newPassword &&
              !newPassword.match(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
              )
                ? "Password must have at least 8 characters, including 1 capital letter, 1 small letter, 1 number, and 1 special character."
                : ""
            }
          />

          <TextField
            sx={{ mb: 3 }}
            label="Re-enter Password"
            type={showReEnterPassPin ? "text" : "password"}
            fullWidth
            margin="normal"
            value={reEnterPassword}
            onChange={(e) => setReEnterPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleToggleVisibility("reEnterPassword")}
                    edge="end"
                  >
                    {showReEnterPassPin ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              pattern:
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
              title:
                "Invalid password. It must have at least 8 characters, including 1 capital letter, 1 small letter, 1 number, and 1 special character.",
            }}
            helperText={
              reEnterPassword && newPassword !== reEnterPassword
                ? "Passwords do not match"
                : ""
            }
          />

          <Button
            variant="contained"
            color="primary"
            onClick={getResetPasswword}
            style={{ width: "100%", height: "50px", fontSize: "15px" }}
          >
            Change Password
          </Button>
        </>
      )}

      <div style={{}}>
        <div
          style={{
            color: "#1877f2",
            fontSize: "18px",
            fontWeight: "500",
            cursor: "pointer",
            textDecoration: "none",
            textAlign: "right",
          }}
        >
          <hr></hr>
          <div style={{ textAlign: "center" }} onClick={changeState}>
            Go Back
          </div>
        </div>
      </div>
    </div>
  );
}
