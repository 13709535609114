import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useAuth } from "../../../AuthContext";
import settingsModule from "../../../services/settings";
import { useState } from "react";
import { Grid } from "@mui/material";

export default function AllSessions({ totalMessageCount }) {
  const { userDetails } = useAuth();
  const [todayMessageCount, setTodayMessageCount] = useState(0);

  React.useEffect(() => {
    getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const getChats = async () => {
    if (userDetails && userDetails._id) {
      const response = await settingsModule.getChatWithLLM({
        userId: userDetails._id,
      });
      if (response && response?._id) {
        let totalCount = response?.totalCount[0]?.todayMessageCount
          ? response?.totalCount[0]?.todayMessageCount
          : 0;
        setTodayMessageCount(totalCount);
      }
    }
  };


  let renderObject = [
    {
      "name": "Remaining Trial Sessions",
      value: userDetails.remainingTrialSession
    },
    {
      "name": "Remaining Paid Sessions",
      value: userDetails.remainingSession
    },
    {
      "name": "Total Consumed",
      value: todayMessageCount
    },
    {
      "name": "Total Allowed Message:",
      value: totalMessageCount
    }
  ]
  return (
    <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
      <Grid container>
        {renderObject.map((item) => (
          <Grid item lg={3}>
            <Card
              sx={{
                m: 1,
                textAlign: "center",
                backgroundColor: "#ffcf00",
                boxShadow: "0px 2px 4px rgba(1, 1 ,1, 0.6)", // Added shadow
              }}
            >
              <CardContent>
                {item.name}: {item.value}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
