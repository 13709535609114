import { Accordion, AccordionSummary, Modal, AccordionDetails, Box, Divider } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function WhatToDoModal({ open, handleClose }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            For users registering on their own
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                &#8594;  Thanks for visiting this page. <br />
                                &#8594; As a next step kindly Log In/ register.<br />
                                &#8594; The site is currently meant for web browsers accessible through Desktop/Laptop and Tablets.<br />
                            </div>
                            <Divider sx={{ margin: 2 }} />
                            <div>
                                You will get a link to verify your email on completion of the registration process.
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            For users registered by your MHP or Corporate
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                &#8594; Thanks for visiting this page. <br />
                                &#8594; As a next step kindly Log In/ register.<br />
                                &#8594; The site is currently meant for web browsers accessible through Desktop/Laptop and Tablets.<br />
                            </div>
                            <Divider sx={{ margin: 2 }} />
                            <div>
                                You  would have already got a mail and you need to update missing profile fields and change the password before the next sign in. The new password should be 8 characters or more, with 1 capital, 1 numeral and 1 symbol.
                            </div>

                        </AccordionDetails>
                    </Accordion>
                    <div style={{marginTop:30}}>
                        Once signed in,<br />
                        &#8594; You can take the free trial & get you early indicators (<b>these are not disorders or diagnosis but are merely possibilities</b> those need to be ruled out or established further),<br />
                        &#8594; Subscribe to a plan and take assessment to get tendencies those can be mapped to disorder by a mental health professional. <b>These do not form the diagnosis for disorders by themselves</b>.<br />
                        &#8594; Read/download your own brief report. You can download detailed report to forward to your mental health professional. Do not try to decipher your detailed report yourself.

                        Wish you a disease-free health.
                    </div>

                </Box>
            </Modal>

        </>
    )
}
