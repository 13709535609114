import React, { useState } from "react";
import { TextField, Button, Typography, Card } from "@mui/material";
import authModule from "../../../services/authUser";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../managers/utility";
import { AuthUserService } from "../../../services";

export default function ProfileDetails() {
  const [email, setEmail] = useState("");
  const [data, setData] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isUserDetails, setIsUserDetails] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const fetchData = async () => {
    if (email.trim() !== "") {
      try {
        const response = await authModule.getUserDetailsByEmail({ email });
        if (response && response.data && response.data.userDetails?._id) {
          setData(response.data); // Set data from response
          showSuccessMessage(response.message);
          setNoDataFound(false);
          setIsUserDetails(true); // Reset noDataFound state
          setEmailError(false); // Reset email error state
          
          // Fetch user subscriptions here
          getUserSubscriptions(response.data.userDetails);
        } else {
          setNoDataFound(true);
          showErrorMessage("User details not found for this email");
          setIsUserDetails(false); // Reset noDataFound state
          setEmailError(false); // Reset email error state
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    } else {
      showErrorMessage("Please Enter Email");
      setEmailError(true);
    }
  };
  

  const getUserSubscriptions = async (userDetails) => {
    if (userDetails && userDetails._id) {
      const subscriptions = await AuthUserService.getUserSubscriptions(
        userDetails._id
      );
      setUserSubscriptions(subscriptions);
    }
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <div style={{ margin: "20px", width: 600, display: "flex" }}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError}
          helperText={emailError ? "Please enter an email" : ""}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={fetchData}
          style={{ marginLeft: "10px", height: 56, width: 200 }}
        >
          Submit
        </Button>
      </div>
      {isUserDetails && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "5px",
              margin: "20px",
              width: "50%",
            }}
          >
            <Typography
              variant="h5"
              style={{ color: "#3f51b5", marginBottom: "10px" }}
            >
              User Details
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Name:</strong> {data.userDetails?.name}
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Email:</strong> {data.userDetails?.email}
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Mobile: </strong>
              {data.userDetails?.mobile || "Not Available"}
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Date Of Birth: </strong>
              {Boolean(data.userDetails?.dateOfBirth) ?
                new Date(data.userDetails.dateOfBirth).toLocaleDateString() : "Not Available"} 
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Gender: </strong>
              {data.userDetails?.gender}
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Remaining Session:</strong>
              {data.userDetails?.remainingSession}
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Remaining Trial Session: </strong>
              {data.userDetails?.remainingTrialSession}
            </Typography>
            <Typography style={{ marginBottom: "5px" }}>
              <strong>Is Verified: </strong>
              {data.userDetails?.isVerified ? "Yes" : "No"}
            </Typography>
          </div>

          <Typography
            variant="h5"
            style={{ color: "#3f51b5", marginTop: "10px", textAlign: "center" }}
          >
            User Subscriptions
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "left",
            }}
          >
            {userSubscriptions?.length > 0 ? (
              userSubscriptions.map((subscription, index) => (
                <div key={index} style={{ padding: 20 }}>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    <h3>Subscription {index + 1}</h3>
                    <p>Name: {subscription.name}</p>
                    <p>Status: {subscription.status}</p>
                    <p>Price: {subscription.price}</p>
                    <p>
                      Renewal Date:{" "}
                      {new Date(subscription.renewalDate).toLocaleDateString()}
                    </p>
                    <p>Plan Details:</p>
                    <ul>
                      {subscription.planId.plansDetails.map((detail, i) => (
                        <li key={i}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))
            ) : (
              <Typography>No subscriptions found</Typography>
            )}
          </div>
        </div>
      )}

      {noDataFound && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            style={{
              padding: "20px",
              margin: "20px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">No User Details Found</Typography>
          </Card>
        </div>
      )}
    </div>
  );
}
