import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";

const authModule = {
  getCoupons,
  updateCoupons,
  deleteoupons,
  addCoupons,
  getVerifyoupons,
  getRegenerateLink,
};

export default authModule;

async function getCoupons(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/get-coupons`;
  return httpService(
    httpConstants.METHOD_TYPE.GET,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
async function addCoupons(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/add-coupons`;
  return httpService(
    httpConstants.METHOD_TYPE.POST,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
async function updateCoupons(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/update-coupons`;
  return httpService(
    httpConstants.METHOD_TYPE.PUT,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
async function deleteoupons(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/delete-coupons`;
  return httpService(
    httpConstants.METHOD_TYPE.PUT,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
async function getVerifyoupons(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/verify-coupons`;
  return httpService(
    httpConstants.METHOD_TYPE.POST,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
async function getRegenerateLink(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/not-verify`;
  return httpService(
    httpConstants.METHOD_TYPE.POST,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
