import React, { useEffect, useState } from 'react';
import DataTable from './dataTable';
import SetQuestions from './SetQuestions/setQuestion';
import LlmAnswer from './LLMAnswers/llMAnswer';
import FreeUsers from './freeUsers/freeUsers';
import { EntityService, QuestionsService } from '../../../services';
import ChiefComplaint from './Models/ChiefComplaint';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HealingIcon from '@mui/icons-material/Healing';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Autocomplete,
  BottomNavigation,
  BottomNavigationAction,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import SkippedQuestions from './SkippedQuestion/SkippedQuestions';
import UndefindQuestions from './UndefindQuestion/UndefindQuestions';
import CustomLoader from '../../../common/customLoader';

export default function CrudIndex() {
  const [allQuestionsData, setAllQuestionsData] = useState([]);
  const [allLots, setAllLots] = useState([]);
  const [allDisorderData, setAllDisorderData] = useState([]);
  const [activeTab, setActiveTab] = useState('Questions'); // Initial active tab
  const [isLoading, setIsLoading] = useState(true);
  const [entities, setEntities] = useState([]);
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);

  useEffect(() => {
    setAutoCompleteData(
      entities.map(e => {
        return { label: e.name, collectionType: e.collectionType };
      })
    );
  }, [entities]);

  useEffect(() => {
    getEntitiesList();
  }, []);

  const getEntitiesList = async () => {
    try {
      const response = await EntityService.getEntityData();
      if (response && response.length) {
        setEntities([...response, { collectionType: 'BASELINE', name: 'BASELINE' }]);
      } else {
        setEntities([{ collectionType: 'BASELINE', name: 'BASELINE' }]);
      }
    } catch (error) {
      console.error('Error fetching corporate data:', error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let response;
      switch (activeTab) {
        case 'Questions':
          const allQuestionsDataRes = await QuestionsService.getAllQuestionsData({ collectionType: selectedCollection.collectionType });
          response = allQuestionsDataRes;
          break;
        case 'LOTS':
          const allLotsRes = await QuestionsService.getAllLots({ collectionType: selectedCollection.collectionType });
          response = allLotsRes;
          break;
        case 'Disorder':
          const allDisorderRes = await QuestionsService.getAllDisorderData({ collectionType: selectedCollection.collectionType });
          response = allDisorderRes;
          break;
        default:
          response = null;
          setIsLoading(false);
      }

      if (response) {
        const data = await response;
        switch (activeTab) {
          case 'Questions':
            setAllQuestionsData(data);
            break;
          case 'LOTS':
            setAllLots(data);
            break;
          case 'Disorder':
            let newData = data.sort((a, b) => a.lotId - b.lotId);
            setAllDisorderData(newData);
            break;
          default:
            break;
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  let content;

  switch (activeTab) {
    case 'SetQuestions':
      content = <SetQuestions />;
      break;
    case 'FreeUsers':
      content = <FreeUsers />;
      break;
    case 'Chief Complaint':
      content = <ChiefComplaint />;
      break;
    case 'LlmAnswer':
      content = <LlmAnswer />;
      break;
    case 'Skippedquestions':
      content = <SkippedQuestions />;
      break;
    case 'UndefindQuestions':
      content = <UndefindQuestions />;
      break;
    default:
      content = (
        <>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <CustomLoader />
            </div>
          ) : (
            <DataTable activeTab={activeTab} allQuestionsData={allQuestionsData} allLots={allLots} allDisorderData={allDisorderData} />
          )}
        </>
      );
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, selectedCollection]);

  return (
    <>
      <BottomNavigation
        sx={{ mb: 3, borderBottom: '1px solid grey', flex: '0 0 auto' }}
        showLabels
        value={activeTab}
        onChange={(event, newValue) => {
          setActiveTab(newValue);
        }}
      >
        <BottomNavigationAction value="Disorder" label="Disorders" icon={<HealingIcon />} />
        <BottomNavigationAction value="Questions" label="Questions" icon={<QuestionMarkIcon />} />
        <BottomNavigationAction value="LOTS" label="LOTS" icon={<ListAltIcon />} />
        <BottomNavigationAction value="Skippedquestions" label="Skipped Questions" icon={<PublishedWithChangesIcon />} />
        <BottomNavigationAction value="UndefindQuestions" label="Unspecified" icon={<ErrorOutlineIcon />} />
      </BottomNavigation>
      <Autocomplete
        disablePortal
        disableClearable
        options={autoCompleteData}
        value={selectedCollection}
        sx={{ width: 300 , marginBottom: 2}}
        onChange={(event, newValue) => {
          console.log(newValue)
          setSelectedCollection(newValue );
        }}
        renderInput={params => (
          <TextField {...params} label="Collection Type" />
        )}
      />
      <div style={{ overflowY: 'auto', flex: 1 }}>{content}</div>
    </>
  );
}
