import React, { useEffect, useState } from "react";
import { Button, MenuItem, TextField, Card } from "@mui/material";
import { useAuth } from "../../AuthContext";
import { showErrorMessage, showSuccessMessage } from "../../managers/utility";
import { UserProfileService } from "../../services";
import CustomDatePicker from "../../common/DatePicker";

const Profile = () => {
  const { userDetails, getUserDetails } = useAuth();

  useEffect(() => {
    setFormData({
      ...userDetails,
      dateOfBirth: userDetails.dateOfBirth
        ? new Date(userDetails?.dateOfBirth).toISOString().split("T")[0]
        : "",
    });
  }, [userDetails]);

  const [formData, setFormData] = useState({
    name: userDetails.name,
    email: userDetails.email,
    age: "",
    gender: "",
    isPregnant: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(formData).some((value) => value === "");

    if (isEmptyField) {
      showErrorMessage("Please fill in all fields");
    } else {
      try {
        const response = await addUserProfile();

        if (response && response._id) {
          showSuccessMessage("Profile Created Successfully");
          getUserDetails();
          setFormData({
            ...response,
            dateOfBirth: response.dateOfBirth
              ? new Date(response?.dateOfBirth).toISOString().split("T")[0]
              : "",
          });
        } else {
          showErrorMessage("Unexpected error creating a user profile.");
        }
      } catch (error) {
        console.error("Error creating user profile:", error);
        showErrorMessage(error.message);
      }
    }
  };

  const addUserProfile = async () => {
    try {
      const requestData = formData;
      const response = await UserProfileService.AddUserProfile({
        userId: userDetails._id,
        ...requestData,
      });

      return response;
    } catch (error) {
      console.error("Error in addUserProfile:", error);
      throw error;
    }
  };

  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
      <Card style={{ width: "100%", padding: "20px" }}>
        <h4 style={{ fontWeight: "bold", textAlign: 'center' }}>My Profile</h4>
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-basic-email"
            label="Email"
            variant="outlined"
            type="text"
            name="email"
            InputLabelProps={{ shrink: true }}
            value={formData?.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            id="outlined-basic-name"
            label="Name"
            variant="outlined"
            type="text"
            name="name"
            InputLabelProps={{ shrink: true }}
            value={formData?.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <CustomDatePicker
            label="Date Of Birth"
            type="date"
            name="dateOfBirth"
            fullWidth
            value={formData?.dateOfBirth ? formData?.dateOfBirth : ""}
            onChange={(v) => { setFormData({ ...formData, dateOfBirth: v }) }}
            style={{ marginTop: "14px" }}
          />

          <div style={{ marginTop: "10px" }}>
            {formData?.dateOfBirth
              ? `Age - ${calculateAge(formData?.dateOfBirth)} Years old`
              : ""}
          </div>

          <TextField
            id="outlined-basic-gender"
            label="Gender"
            variant="outlined"
            select
            name="gender"
            value={formData?.gender || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>

          <Button
            onClick={handleSubmit}
            variant="contained"
            type="submit"
            style={{ marginTop: "16px" }}
            fullWidth
          >
            Save
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default Profile;
