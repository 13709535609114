import React from "react";

const MyAssessment = () => {
  return (
    <div>
      <button
        className="btn  text-uppercase m-2 py-2 px-4 shadow-sm"
        type="button"
        id="switchButton"
        style={{
          backgroundColor: "orange",
          transition: "background-color 0.7s",
          ":hover": { backgroundColor: "darkorange" },
        }}
      >
        <span className="d-none d-sm-inline-block">My Assessment</span>
      </button>
    </div>
  );
};
export default MyAssessment;