import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";


const settingsModule=  {
    getSettings,
    updateSettings,
    addQuestionAnswer,
    getQuestionAnswer,
    getFreeUsers,
    addFreeUsers,
    setComplaint,
    getComplaint,
    addLLMQuestionAnswer,
    getLLMQuestionAnswer,
    addDisorderInfoCateroryAPI,
    addDisorderDetailsAPI,
    getChatWithLLM,
    getAllDisorderInfoCategoriesAPI,
    getAllDisorderDetailsAPI,
    updateDisorderDetailsAPI,
    deleteDisorderDetailsAPI,
    deleteDisorderInfoCategoriesAPI,
    updateDisorderInfoCategoriesAPI,
    getAllDisorderInfoCategoriesAndDetailsAPI
};
export default settingsModule

async function getLLMQuestionAnswer(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/llm-ans`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addLLMQuestionAnswer(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/add-llm-ans`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function getSettings(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/settings`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateSettings(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/settings`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addQuestionAnswer(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/add-question-answer`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addFreeUsers(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/add-free-users`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getQuestionAnswer(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/get-question-answer`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getComplaint(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/get-complaint`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function setComplaint(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/set-complaint`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getFreeUsers(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/get-free-users`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getChatWithLLM(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/get-chat`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function addDisorderInfoCateroryAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/create-disorder-info-category`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  addDisorderDetailsAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/create-disorder-details`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  getAllDisorderInfoCategoriesAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/get-all-disorder-info-category`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  getAllDisorderInfoCategoriesAndDetailsAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/get-disorder-info-category-and-details`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  getAllDisorderDetailsAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/user/get-all-disorder-details?categoryId=${requestData.categoryId}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  updateDisorderDetailsAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-disorder-details/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  deleteDisorderDetailsAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-disorder-details/${requestData.categoryId}`
    return httpService(httpConstants.METHOD_TYPE.DELETE, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  deleteDisorderInfoCategoriesAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-disorder-info-category/${requestData.categoryId}`
    return httpService(httpConstants.METHOD_TYPE.DELETE, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function  updateDisorderInfoCategoriesAPI(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-disorder-info-category/${requestData.categoryId}`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}