import React, { useEffect, useState } from "react";
import { Card, Typography, Grid, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalDisorder from "./ModalDisorder";
import { SettingsService } from "../../../../services";
import CustomLoader from "../../../../common/customLoader";
import AddDisorderInfoModal from "./addDisorderInfoModal";
import AddCategoryModal from "./addCategoryModal";
import EditIcon from "@mui/icons-material/Edit";
import EditCategoryModal from "./EditCategoryModal";

const Disorder = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allDisorderInformation, setAllDisorderInformation] = useState([]);
  const [showDisorders, setShowDisorders] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [selectedDisorderData, setSelectedDisorderData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [informationNoDataFound, setInformationNoDataFound] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [editDeleteCategoryModal, setEditDeleteCategoryModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    getDisorderData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisorderData = async () => {
    const res = await SettingsService.getAllDisorderInfoCategoriesAPI();
    if (res && res.length) {
      setAllDisorderInformation(res);
    }
    if (res && res.length === 0) {
      setNoDataFound(true);
    }
    setIsLoading(false);
  };

  const openDisorderArray = (data) => {
    setShowDisorders(false);
    setIsLoading(false);
    getCategoryInfo(data);
    setCategoryName(data);
  };

  const getCategoryInfo = async (data) => {
    const res = await SettingsService.getAllDisorderDetailsAPI({
      categoryId: data._id,
    });
    setSelectedCategoryId(data)
    if (res && res.length > 0) {
      setSelectedCategory(res);
      setInformationNoDataFound(false)
    }
    if (res && res.length === 0) {
      setInformationNoDataFound(true);
    }
    setIsLoading(false);
  };

  const goBack = () => {
    setShowDisorders(true);
    setIsLoading(false);
    setInformationNoDataFound(false)
    setSelectedCategory([]);
  };

  const openDisorderDescription = (item) => {
    setSelectedDisorderData(item);
    setIsModalOpen(true);
    setModalTitle(item.name);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const addDisorder = () => {
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
  };

  const addDisorderCategory = () => {
    setAddCategoryModalOpen(true);
  };

  const closeDisorderCategory = () => {
    setAddCategoryModalOpen(false);
    setEditDeleteCategoryModal(false);
  };

  const handleEditIconClick = (item) => {
    setEditDeleteCategoryModal(true);
    setModalTitle(item);
  };

  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}
      {noDataFound && (
        <div style={{ textAlign: "center" }}>
          <Typography>No data found</Typography>
        </div>
      )}
      {informationNoDataFound && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" onClick={goBack}>
              <ArrowBackIcon
                style={{ fontSize: 30, color: "white", marginRight: 5 }}
              />
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                addDisorder(categoryName);
              }}
            >
              ADD
            </Button>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography>No data found</Typography>
          </div>
        </>
      )}
      {selectedCategory && selectedCategory.length > 0 && (
        <div
          style={{
            display: "flex",
            marginTop: 40,
            flexDirection: "column",
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" onClick={goBack}>
              <ArrowBackIcon
                style={{ fontSize: 30, color: "white", marginRight: 5 }}
              />
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                addDisorder(categoryName);
              }}
            >
              ADD
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              gap: "22px",
              width: "100%",
              margin: "auto",
              paddingTop: 40,
            }}
          >
            <Grid container spacing={2}>
              {selectedCategory.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      height: "100px",
                      borderRadius: 5,
                      backgroundColor: "#157feb",
                      color: "white",
                      boxShadow: "4px 10px 8px 4px rgba(0, 0, 0.1, 0.3)",
                      overflow: "hidden",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => openDisorderDescription(item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontSize: 16,
                          padding: 10,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}

      {showDisorders && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <div className="mb-5 d-flex  justify-content-end">
            <Button
              className="p-2"
              variant="contained"
              onClick={addDisorderCategory}
            >
              ADD
            </Button>
          </div>
          <Grid container spacing={2}>
            {allDisorderInformation.map((item) => (
              <Grid key={item.id} item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    height: "100px",
                    borderRadius: 5,
                    backgroundColor: "#157feb",
                    color: "white",
                    boxShadow: "4px 10px 8px 4px rgba(0, 0, 0.1, 0.3)",
                    overflow: "hidden",
                    transition: "transform 0.3s ease-in-out",
                    position: "relative", // Add position relative to the card
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => openDisorderArray(item)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: 16,
                        padding: 10,
                      }}
                    >
                      {item.name}
                    </Typography>
                    {/* Edit icon */}
                    <EditIcon
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the card click event
                        handleEditIconClick(item);
                      }}
                    />
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <ModalDisorder
        openDescription={isModalOpen}
        handleCloseDemo={handleCloseModal}
        selectedDisorderData={selectedDisorderData}
        title={modalTitle}
        setAllDisorderInformation={setAllDisorderInformation}
        allDisorderInformation={allDisorderInformation}
        selectedCategory={selectedCategory}
        setIsModalOpen={setIsModalOpen}
        setModalTitle={setModalTitle}
        categoryName={categoryName}
        getCategoryInfo={getCategoryInfo}
        selectedCategoryId={selectedCategoryId}
      />

      <AddDisorderInfoModal
        openAddModal={addModalOpen}
        handleCloseAddModal={handleCloseAddModal}
        selectedCategoryInfo={categoryName}
        getCategoryInfo={getCategoryInfo}
        selectedCategoryId={selectedCategoryId}
      />

      <AddCategoryModal
        openAddCategoryModal={addCategoryModalOpen}
        handleCloseAddCategoryModal={closeDisorderCategory}
        getDisorderData={getDisorderData}
      />
      <EditCategoryModal
        openAddCategoryModal={editDeleteCategoryModal}
        handleCloseAddCategoryModal={closeDisorderCategory}
        getDisorderData={getDisorderData}
        modalTitle={modalTitle}
      />
    </>
  );
};

export default Disorder;
