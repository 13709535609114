import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment/moment";
import { useAuth } from "../../../AuthContext";
import { AnswerSessionService, QuestionsService } from "../../../services";
import { Button, Checkbox } from "@mui/material";
import { usePDF } from "react-to-pdf";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaidSessionReport from "../../Session/PaidSessionReport/PaidSessionReport";
import CustomLoader from "../../../common/customLoader";
import { useState } from "react";
import { showSuccessMessage } from "../../../managers/utility";
import { sessionTypes } from "../../../common/constants";


const MyReport = ({ setSelectedSession, selectedSession }) => {
  const { userDetails } = useAuth();
  const [data, setData] = useState([]);
  const [openPaidReport, setOpenPaidReport] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const { targetRef } = usePDF({ filename: "page.pdf" });

  const [openDetailsReport, setDetailedReport] = useState(false)


  const getSessionData = async () => {
    if (userDetails._id) {
      let res = await QuestionsService.getAllSessions(0, 0, userDetails._id);
      if (res && res?.data.length > 0) {
        setData(res?.data);
        setTableView(true);
      } else {
        setData(null);
        setNoDataFound(true);
      }
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    getSessionData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);


  const handleUserConsent = async (e, row) => {
    const res = await AnswerSessionService.updateUserConsent({ sessionId: row.sessionId, consentValue: e.target.checked })
    if (res && res._id) {
      showSuccessMessage("Updated");
      getSessionData()
    }
  }


  return (
    <div>
      {data && tableView && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Time</TableCell>
                  <TableCell align="center">Report</TableCell>
                  <TableCell align="center">Detailed Report</TableCell>
                  <TableCell align="center">Share</TableCell>
                  {/* <TableCell align="right">Final Disorders</TableCell> */}
                  {userDetails.entityId ? <TableCell align="center">Allow Share</TableCell> : ""}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) =>
                (
                  <>
                    {row.isCompleted && <TableRow
                      onClick={() => setSelectedSession(row)}
                      key={row._id}
                      sx={{
                        cursor: "pointer",
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {moment(row.createdAt).format("Do MMMM YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.createdAt).format("h:mm:ss a")}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setSelectedSession(row);
                            setOpenPaidReport(true);
                            setTableView(false);
                            setDetailedReport(false)
                          }}
                        >
                          View {row && row.sessionType ? sessionTypes[row.sessionType] : ""} Report
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            setSelectedSession(row);
                            setOpenPaidReport(true);
                            setTableView(false);
                            setDetailedReport(true)
                          }}
                        >
                          View {row && row.sessionType ? sessionTypes[row.sessionType] : ""} Detailed Report
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox checked={row.hasUserConsent} onChange={(e) => handleUserConsent(e, row)} />
                      </TableCell>
                    </TableRow>}
                  </>
                )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginLeft: 10 , marginTop:10}}> * Only selected reports will be shared </div>
        </>
      )}
      {noDataFound && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/diamond.png"
            alt="Logo"
            className="img-fluid"
            width={180}
            height={180}
          />
          <p style={{ fontSize: 18, color: "gray", marginTop: "20px" }}>
            <i> Reports will appear once a session completes</i>
          </p>
        </div>
      )}

      {openPaidReport && (
        <>
          <div
            style={{
              position: "fixed",
              top: 5,
              marginTop: 70,
              zIndex: 100,
              backgroundColor: "white", // Add background color if needed
              padding: "10px", // Add padding if needed
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "-webkit-fill-available",
            }}
          >
            <Button
              onClick={() => {
                setOpenPaidReport(false);
                setTableView(true);
              }}
              variant="contained"
              startIcon={<ArrowBackIcon />}
            // style={{ flex: 1 }}
            >
              Back
            </Button>
          </div>

          <div ref={targetRef} style={{ padding: "10px" }}>
            {/* Apply page-break-after property to start a new page for each session */}
            <div
              style={{
                pageBreakAfter: "always",
                overflow: "scroll",
                height: "75vh",
              }}
            >
              {selectedSession && (
                <PaidSessionReport
                  selectedSession={selectedSession}
                  showReport={true}
                  showDirectlyDetailed={openDetailsReport}
                />
              )}
            </div>
          </div>
        </>
      )}

      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}
    </div>
  );
};
export default MyReport;
