import React from "react";

const VideoDemo = ({videoUrl}) => {

   return (
    <div style={{height:'550px'}}>
      <video width="100%" height="100%" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoDemo;
