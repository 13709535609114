import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import PlanModal from "./PlanModal";
import { PlanService } from "../../../../services";
import { Chip } from "@mui/material";

const PlansDataTable = ({ allPlansData, refetch }) => {
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [deletedItems, setDeletedItems] = useState({}); // State to track deleted items

  const handleAddPlan = () => {
    setCreateMode(true);
    setOpen(true);
    setIsEditable(true);
  };

  const handleEditPlan = (index) => {
    setIsEditable(true);
  };

  const openPlanModal = (index) => {
    setSelectedPlan(allPlansData[index]);
    setOpen(true);
  };

  const closePlanModal = () => {
    setOpen(false);
    setSelectedPlan(null);
    setIsEditable(false);
  };

  const handleDeleteClick = async (id) => {
    // Toggle the delete state of the clicked item
    setDeletedItems((prevDeletedItems) => ({
      ...prevDeletedItems,
      [id]: !prevDeletedItems[id],
    }));

    // Update the isDeleted value in the API
    await PlanService.updatePlans({ _id: id, isDeleted: !deletedItems[id] });
    refetch();
  };

  useEffect(() => {
    // Update the deletedItems state based on the isDeleted value from the API response
    const updatedDeletedItems = {};
    allPlansData.forEach((item) => {
      updatedDeletedItems[item._id] = item.isDeleted;
    });
    setDeletedItems(updatedDeletedItems);
  }, [allPlansData]);


  return (
    <>
      <div style={{ width: "150px" }}>
        <Button variant="contained" onClick={handleAddPlan}>
          Add
        </Button>
      </div>
      <TableBody style={{ width: "100%" }}>
        <TableRow style={{ width: "100%" }}>
          <TableCell style={{ width: "100px" }}>Plan Type</TableCell>
          <TableCell style={{ width: "90px" }} align="center">
            Duration
          </TableCell>
          <TableCell style={{ width: "200px" }} align="center">
            Message Count
          </TableCell>
          <TableCell style={{ width: "200px" }} align="center">
            Price
          </TableCell>
          <TableCell style={{ width: "220px" }} align="center"></TableCell>
          <TableCell style={{ width: "220px" }} align="center">
            Actions
          </TableCell>
          <TableCell style={{ width: "220px" }} align="center"></TableCell>
        </TableRow>
        {allPlansData.map((item, index) => (
          <TableRow style={{ width: "100%" }} key={item._id}>
            <TableCell style={{ width: "100px" }}>{item?.plansType}</TableCell>
            <TableCell style={{ width: "90px" }} align="center">
              {item?.duration}
            </TableCell>
            <TableCell style={{ width: "200px" }} align="center">
              {item?.messageCount ? `${item?.messageCount} / Day` : "NA"}
            </TableCell>
            <TableCell style={{ width: "200px" }} align="center">
              {item?.price} Rs.
            </TableCell>
            <TableCell style={{ width: "200px" }} align="center">
              <Chip
                label={item.isDeleted ? "Inactive" : "Active"}
                color={item.isDeleted ? "error" : "success"}
              />
            </TableCell>
            <TableCell style={{ width: "200px" }} align="center">
              <Button
                size="small"
                sx={{
                  ml: 2,
                  color: item.isDeleted ? "error.main" : "secondary.main",
                  borderColor: item.isDeleted ? "error.main" : "secondary.main",
                }}
                onClick={() => handleDeleteClick(item._id)}
                aria-label="Disabled-enabled"
                variant="outlined"
              >
                {item.isDeleted ? "Enable" : "Disable"}
              </Button>
            </TableCell>
            <TableCell style={{ width: "220px" }} align="center">
              <Button onClick={() => openPlanModal(index)} variant="contained">
                Edit
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {(createMode || selectedPlan) && (
        <PlanModal
          refetch={refetch}
          open={open}
          closePlanModal={closePlanModal}
          plan={selectedPlan}
          isEditable={isEditable}
          createMode={createMode}
          handleAddPlan={handleAddPlan}
          handleEditPlan={handleEditPlan}
        />
      )}
    </>
  );
};
export default PlansDataTable;