import React, { useState } from "react";
import { Button, Card, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalDisorder from "./ModalDisorder";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { SettingsService } from "../../../../services";
import CustomLoader from "../../../../common/customLoader";

const Disorder = () => {
  const [disorders, setDisorder] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedCategoryDisorders, setSelectedCategoryDisorders] = useState([])
  const [data, setData] = useState("");
  const [openDescription, setOpenDescription] = useState(false);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [disorderData, setDisorderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    getDisorderData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if (selectedCategory && selectedCategory._id) {
      getCategoriesData(selectedCategory._id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const getCategoriesData = async () => {
    const allData = await SettingsService.getAllDisorderDetailsAPI({ categoryId: selectedCategory._id });
    console.log(allData)
    setSelectedCategoryDisorders(allData)
  }

  const getDisorderData = async () => {
    // const res = await SettingsService.getQuestionAnswer();
    // if (res && res.length && res[0].disordersLandingPage) {
    //   setDisorderData(res[0].disordersLandingPage);
    //   setIsLoading(false);
    // }

    const res = await SettingsService.getAllDisorderInfoCategoriesAPI();
    if (res && res.length) {
      setDisorderData(res);
      setIsLoading(false);

    }
  };

  const openBox = (array) => {
    setDisorder(false);
    console.log(array)
    setSelectedCategory(array)
  };

  const goBack = () => {
    setDisorder(true);
    setSelectedCategory(null)
  };

  const goToHome = () => {
    navigate("/");
  };

  const openDisorder = (item) => {
    setData(item.description);
    setOpenDescription(true);
    setTitle(item.name);
  };

  const handleCloseDemo = () => {
    setOpenDescription(false);
  };
  return (
    <>
      {selectedCategory && (
        <div
          style={{
            display: "flex",
            marginTop: 8,
            flexDirection: "column",
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <div>
            <Button
              onClick={() => {
                goBack();
              }}
            >
              <ArrowBackIcon style={{ fontSize: 38, color: "black" }} />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              gap: "22px",
              marginTop: 26,
              width: "100%", // Use full width
              margin: "auto", // Center the container
              paddingTop: 20,
            }}
          >
            <Grid container spacing={2}>
              {selectedCategoryDisorders.map((item) => (
                <Grid
                  key={item.id}
                  item
                  xs={12} // Use full width on small screens
                  sm={6} // Use half width on medium screens
                  md={3} // Use one-third width on large screens
                >
                  <Card
                    sx={{
                      // width: 320, // 100% of the Grid item
                      height: "100px",
                      borderRadius: 5,
                      backgroundColor: "#157feb",
                      color: "white",
                      boxShadow: "4px 10px 8px 4px rgba(0, 0, 0.1, 0.3)",
                      overflow: "hidden",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => openDisorder(item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontSize: 16,
                          padding: 10,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}

      {disorders && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 8,
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <div>
            <Button
              onClick={() => {
                goToHome();
              }}
            >
              <ArrowBackIcon style={{ fontSize: 38, color: "black" }} />
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              gap: "22px",
              marginTop: 26,
              width: "100%",
              margin: "auto",
              paddingTop: 20,
            }}
          >
            <Grid container spacing={2}>
              {disorderData.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      height: "100px",
                      borderRadius: 5,
                      backgroundColor: "#157feb",
                      color: "white",
                      boxShadow: "4px 10px 8px 4px rgba(0, 0, 0.1, 0.3)",
                      overflow: "hidden",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => openBox(item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontSize: 16,
                          padding: 10,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}
      <ModalDisorder
        openDescription={openDescription}
        handleCloseDemo={handleCloseDemo}
        data={data}
        title={title}
      />
    </>
  );
};

export default Disorder;
