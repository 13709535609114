import React, { useState,useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SettingsService } from "../../../../services";
import { showSuccessMessage } from "../../../../managers/utility";

export default function AddDisorderInfoModal({
  openAddModal,
  handleCloseAddModal,
  selectedCategoryInfo,
  getCategoryInfo,
  selectedCategoryId
}) {
  const [disorderName, setDisorderName] = useState("");
  const [description, setDescription] = useState("");

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "align",
  ];

  
  useEffect(() => {
      setDisorderName("");
      setDescription("");
  }, []);


  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      ["blockquote", "list", "bullet", "indent"],
    ],
  };

  const saveChanges = async() => {
    // Logic to save disorder name and description
    const response = await SettingsService.addDisorderDetailsAPI({
        name: disorderName,
        description: description,
        categoryId: selectedCategoryInfo._id,
      });

    if (response) {
      showSuccessMessage("Details Added Successfully.");
      getCategoryInfo(selectedCategoryId)
      setDisorderName("");
      setDescription("");
    }
    handleCloseAddModal();
  };

  return (
    <Modal
      open={openAddModal}
      onClose={handleCloseAddModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxWidth: "900px",
          backgroundColor: "white",
          padding: "50px",
          height: "75%",
        }}
      >
        <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
         <h4>{selectedCategoryInfo.name}</h4>
          <div style={{ marginLeft: "auto" }}>
            <IconButton onClick={handleCloseAddModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <TextField
            label="Name"
            variant="outlined"
            value={disorderName}
            onChange={(e) => setDisorderName(e.target.value)}
            style={{ width: "100%", marginBottom:'10px' }}
          />
        
        <ReactQuill
          theme="snow"
          modules={modules}
          value={description}
          formats={formats}
          onChange={(value) => setDescription(value)}
          style={{ height: "calc(90% - 170px)" }}
        />
        <Button fullWidth variant="contained" style={{ marginTop: 60 }} onClick={saveChanges}>
          Save
        </Button>
      </div>
    </Modal>
  );
}
