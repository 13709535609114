import React, { useState, useEffect } from 'react';
import Plans from './Plans';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import DemoModal from './DemoModal';
import Pricing from './Pricing';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DnsIcon from '@mui/icons-material/Dns';
import { Grid } from '@mui/material';
import { ENTITY_TYPES_ENUM } from '../../AdminConsole/Entity/CorporateDashboard/CorporateTable';
import { sessionTypes } from '../../../common/constants';

const HomeCards = ({ checkLoginBeforeSession }) => {
  const [userLogin, setUserLogin] = useState(false);
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const [open, setOpen] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const OpenDemo = () => {
    setOpenDemo(true);
  };

  const handleCloseDemo = () => {
    setOpenDemo(false);
  };

  const isUserLogin = () => {
    if (userDetails && userDetails._id) {
      setUserLogin(true);
    } else {
      setUserLogin(false);
    }
  };

  useEffect(() => {
    isUserLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const OpenDashboard = value => {
    navigate(`/dashboard/${value}`);
  };
  const OpenCard = () => {
    navigate(`/disorder`);
  };

  const shouldNotBeCorporate = () => {
    if (userDetails?.entityId && userDetails?.entityId?.type === ENTITY_TYPES_ENUM.CORPORATE) return false;
    return true;
  };

  const videoUrl = 'https://aipsychi.org/wp-content/uploads/2024/02/Full_Movie-1.mp4';

  return (
    <>
      <Grid container>
        <Grid item md={6} sm={12} lg={4} xs={12}>
          <Plans
            icon={<AssessmentIcon style={{ fontSize: 38, color: 'black' }} />}
            heading={'Disorders'}
            content={'Read...'}
            buttonName={'disorder'}
            functionName={() => OpenCard()} // Pass the function reference
          />
        </Grid>
        <Grid item md={6} sm={12} lg={4} xs={12}>
          <Plans
            icon={<OndemandVideoIcon style={{ fontSize: 38, color: 'black' }} />}
            heading={'Demo'}
            content={'See how our product works'}
            buttonName={'Start Demo'}
            functionName={OpenDemo}
          />
        </Grid>
        {shouldNotBeCorporate() && (
          <Grid item md={6} sm={12} lg={4} xs={12}>
            <Plans
              icon={<DnsIcon style={{ fontSize: 38, color: 'black' }} />}
              heading={'Plans'}
              content={'Check out all the plans we offer'}
              buttonName={'View Pricing'}
              functionName={handleOpen}
            />
          </Grid>
        )}
        {userLogin && (
          <>
            <Grid item md={6} sm={12} lg={4} xs={12}>
              <Plans
                icon={<LoyaltyIcon style={{ fontSize: 38, color: 'black' }} />}
                heading={'My Subscriptions'}
                content={'Check your subscription.'}
                buttonName={'View'}
                functionName={() => OpenDashboard('subscriptions')} // Pass the function reference
              />
            </Grid>
            <Grid item md={6} sm={12} lg={4} xs={12}>
              <Plans
                icon={<AssessmentIcon style={{ fontSize: 38, color: 'black' }} />}
                heading={'My Reports'}
                content={'View past session reports'}
                buttonName={'View'}
                functionName={() => OpenDashboard('reports')} // Pass the function reference
              />
            </Grid>
            <Grid item md={6} sm={12} lg={4} xs={12}>
              {' '}
            </Grid>

            {process.env.REACT_APP_SHOWFAMILARITYSESSION && (
              <Grid item md={6} sm={12} lg={4} xs={12}>
                <Plans
                  icon={<PlayCircleOutlineIcon style={{ fontSize: 38, color: 'black' }} />}
                  heading={'Familiarization'}
                  content={
                    userDetails?.remainingFamilaritySession >= 0 ? (
                      <div>
                        <span style={{ fontSize: '14px', paddingLeft: '10px' }} className="additional-text">
                          <span>Remaining Sessions: </span>
                          {userDetails?.remainingFamilaritySession > 99 ? '99+' : userDetails?.remainingFamilaritySession}
                        </span>
                      </div>
                    ) : (
                      'login for start the session'
                    )
                  }
                  buttonName={'Start Session'}
                  functionName={() => checkLoginBeforeSession(sessionTypes.FAMILARITY)} // Pass the function reference
                />
              </Grid>
            )}
            <Grid item md={6} sm={12} lg={4} xs={12}>
              <Plans
                icon={<AdUnitsIcon style={{ fontSize: 38, color: 'black' }} />}
                heading={'Trial Session'}
                content={
                  userDetails?.remainingTrialSession >= 0 ? (
                    <div>
                      <span style={{ fontSize: '14px', paddingLeft: '10px' }} className="additional-text">
                        <span>Remaining Sessions: </span>
                        {userDetails?.remainingTrialSession > 99 ? '99+' : userDetails?.remainingTrialSession}
                      </span>
                    </div>
                  ) : (
                    'You can take Trial here'
                  )
                }
                buttonName={'Start Trial'}
                functionName={() => checkLoginBeforeSession(sessionTypes.TRIAL)}
              />
            </Grid>
            <Grid item md={6} sm={12} lg={4} xs={12}>
              <Plans
                icon={<PlayCircleOutlineIcon style={{ fontSize: 38, color: 'black' }} />}
                heading={'Assessments'}
                content={
                  userDetails?.remainingSession >= 0 ? (
                    <div>
                      <span style={{ fontSize: '14px', paddingLeft: '10px' }} className="additional-text">
                        <span>Remaining Sessions: </span>
                        {userDetails?.remainingSession > 99 ? '99+' : userDetails?.remainingSession}
                      </span>
                    </div>
                  ) : (
                    'login for start the session'
                  )
                }
                buttonName={'Start Session'}
                functionName={() => checkLoginBeforeSession(sessionTypes.PAID)} // Pass the function reference
              />
            </Grid>
            <Grid item md={6} sm={12} lg={4} xs={12}>
              <Plans
                icon={<PlayCircleOutlineIcon style={{ fontSize: 38, color: 'black' }} />}
                heading={'GAD'}
                // content={
                //   userDetails?.remainingSession >= 0 ? (
                //     <div>
                //       <span style={{ fontSize: '14px', paddingLeft: '10px' }} className="additional-text">
                //         <span>Remaining Sessions: </span>
                //         {userDetails?.remainingSession > 99 ? '99+' : userDetails?.remainingSession}
                //       </span>
                //     </div>
                //   ) : (
                //     'login for start the session'
                //   )
                // }
                buttonName={'Start Session'}
                functionName={() => checkLoginBeforeSession(sessionTypes.GAD)} // Pass the function reference
              />
            </Grid>
          </>
        )}
      </Grid>
      {/* view Pricing */}
      <Pricing open={open} handleClose={handleClose} />

      {/* Demo */}
      <DemoModal openDemo={openDemo} handleCloseDemo={handleCloseDemo} videoUrl={videoUrl} />
    </>
  );
};

export default HomeCards;
