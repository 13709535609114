import React, { useState, useEffect } from "react";
import { useAuth } from "../../../AuthContext";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { UserProfileService } from "../../../services";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../managers/utility";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import CustomDatePicker from "../../../common/DatePicker";



const ProfileUpdate = () => {
  const { userDetails } = useAuth();

  const [formData, setFormData] = useState({
    name: userDetails.name || "",
    email: userDetails.email || "",
    dateOfBirth: userDetails.dateOfBirth || "",
    gender: userDetails.gender || "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: userDetails.name || "",
      email: userDetails.email || "",
      dateOfBirth: userDetails.dateOfBirth || "",
      gender: userDetails.gender || "",
      mobile: userDetails.mobile
    }));
  }, [userDetails]);

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.dateOfBirth) {
      newErrors.dateOfBirth = "Date of Birth is required";
    }
    if (!formData.gender.trim()) {
      newErrors.gender = "Gender is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const requestData = formData;
        const response = await UserProfileService.AddUserProfile({
          userId: userDetails._id,
          ...requestData,
        });
        if (response) {
          showSuccessMessage("Your profile is successfully updated");
          console.log(response, "response", response.isProfilePending)
          if (!Boolean(response.isProfilePending)) {
            window.location.href = "/"
          }
        } else {
          showErrorMessage(response.message);
        }
      } catch (error) {
        console.error("Error in addUserProfile:", error);
        throw error;
      }
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          paddingTop: "30px",
        }}
      >
        <h2>Update Profile</h2>
        <h6 style={{ color: "gray" }}>
          Following details are required for us to serve you better, Please fill
          to continue.
        </h6>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            disabled
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mobile Number"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <CustomDatePicker className={"width-full"} label={"Date of birth"} name="dateOfBirth" style={{ width: "100%" }} onChange={(v) => setFormData({ ...formData, dateOfBirth: v })} value={formData.dateOfBirth} />
          </div>

          <FormControl fullWidth margin="normal" error={!!errors.gender}>
            <InputLabel>Gender</InputLabel>
            <Select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {errors.gender && <FormHelperText>{errors.gender}</FormHelperText>}
          </FormControl>
          <Button fullWidth type="submit" variant="contained" color="primary">
            Update
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ProfileUpdate;
