import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";


const entityModule = {
    getEntityData,
    addEntityUser,
    getEntityEmployees,
    addEntityEmployee,
    getEntityById,
    addEntityLicense,
    getEntityEmployeeDetails,
    addCouponForEntity,
    getCouponbyEntity,  
    assignedCouponForEntity,
    deleteAssignedCouponForEntity,
    getLicense,
    resetEntityUserPassword,
    sendCustomEmailFromEntity,
    deleteEntity
};
export default entityModule;



async function getEntityData(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/get-entity-data`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function deleteEntity(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/delete-entity`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getEntityById(id) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/get-entity-by-id/${id}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),{}, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getEntityEmployees(entityId) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/get-entity-employees/${entityId}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),{}, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addEntityUser(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/add-entity-data`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function resetEntityUserPassword(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/reset-encrypted-password`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function sendCustomEmailFromEntity(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/send-custom-email-by-entity`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getLicense(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/get-license-by-id`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addEntityLicense(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/add-entity-license`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addEntityEmployee(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/add-entity-employee`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function getEntityEmployeeDetails(userId, type) {
    let url = process.env.REACT_APP_API_URI + `/api/private/user/get-user-by-id/?userId=${userId}&type=${type}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),{}, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function addCouponForEntity(payload) {
    let url = process.env.REACT_APP_API_URI + `/api/private/entity/add-coupon-for-entity`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),payload, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData){
                     return Promise.reject(response);
                 }
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function getCouponbyEntity(payload) {
    let url = process.env.REACT_APP_API_URI + `/api/private/get-coupons-by-entity/?entityId=${payload.entityId}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),payload, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function assignedCouponForEntity(payload) {
    let url = process.env.REACT_APP_API_URI + `/api/private/assignedId-entity-coupon`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(),payload, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
async function deleteAssignedCouponForEntity(payload) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-assignedId-entity-coupon`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(),payload, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}



