import React, { useState } from 'react'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    TextField,
    Typography,
    IconButton,
    Tabs,
    Tab,
    Box,
} from "@mui/material";
import { AuthUserService } from '../../../services';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { showErrorMessage, showSuccessMessage } from '../../../managers/utility';



export default function CorporateOrEmployeeLogin({ loginAs, changeState, forgotPassword, InputAdornment, handleChange, value, onClose, handleToggleVisibility }) {
    const navigate = useNavigate();

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPin] = useState(false);
    const [state] = useState("Login"); //for toggle login or forgot form
    const { setUserDetails } = useAuth();

    const handleLogin = async () => {
        try {
            const response = await AuthUserService.loginCorporateEmployee({
                email: email,
                password: password,
                loginAs: loginAs.toUpperCase(),
            });
            if (response.token && response.userDetails) {
                showSuccessMessage("Login Success");
                localStorage.setItem("token", response.token);
                localStorage.setItem(
                    "userDetails",
                    JSON.stringify({ ...response.userDetails })
                );
                setUserDetails(response.userDetails);
                if (response.userDetails.role === "CORPORATE") {
                    navigate("/corporate");
                    window.location.reload()
                }
                onClose();
            } else {
                showErrorMessage(response.message);
            }
        } catch (error) {
            console.error("Error during login:", error);
            showErrorMessage(error.message);
        }
    };

    return (
        <div
            style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                maxWidth: "450px",
                width: "100%",
            }}
        >
            <div style={{ textAlign: "right" }}>
                <ClearIcon
                    style={{ textAlign: "right" }}
                    color="primary"
                    onClick={onClose}
                />
            </div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        style={{ fontSize: "13px" }}
                        label="Login as Corporate Admin"
                        {...a11yProps(0)}
                    />
                    <Tab
                        style={{ fontSize: "13px" }}
                        label="Login as Employee"
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>

            {/* <CustomTabPanel value={value} index={0}> */}
            <Typography variant="h5" gutterBottom>
                <IconButton
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    onClick={onClose}
                ></IconButton>
            </Typography>

            <TextField
                label={value === 0 ? "Corporate Email" : "Employee Email"}
                type="email"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
            <TextField
                sx={{ mb: 3 }}
                label="Password"
                type={showPin ? "text" : "password"}
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => handleToggleVisibility("password")}
                                edge="end"
                            >
                                {showPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <div
                style={{
                    padding: "0 0 15px 0",
                    color: "#1877f2",
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                    textDecoration: "none",
                }}
                onClick={forgotPassword}
            >
                Forgotten password?
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    style={{ width: "100%", height: "50px", fontSize: "15px" }}
                >
                    Submit
                </Button>
            </div>
            <div
                style={{
                    color: "#1877f2",
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                    textDecoration: "none",
                    textAlign: "right",
                }}
            >
                <div
                    onClick={() => changeState(state === "Login User" ? "Login" : "Login User")}
                    style={{ marginTop: "20px", textAlign: "center" }}
                >
                    Login as User
                </div>
            </div>
        </div>
    )
}
