import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import ReportGmailerrorredTwoToneIcon from '@mui/icons-material/ReportGmailerrorredTwoTone';
import { showErrorMessage } from '../../managers/utility';
import { SESSION_MODE, useAuth } from '../../AuthContext';



export default function ReadyForSessionModal({ open,clickedSessionType,setSessionType, handleClose, ableToResumeSession, showMessage, setSessionStarted, loadingSessionDetails }) {

    const { setSessionMode } = useAuth()

    const [selectedSesstionType, setSelectedSessionType] = useState("");

    const [hasRecordedSessionType, setHasRecordedSessionType] = useState(false);

    const [sessionModeForSelection, setSessionModeForSelection] = useState(SESSION_MODE.SPEECH_MODE)

    const [currentWidth, setCurrentWidth] = useState(0)
    
    
    const handleResize = () => {
        setCurrentWidth(window.innerWidth);
      };

      useEffect(() => {
        handleResize();
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    
    const attemptToRestartSession = () => {
        localStorage.removeItem("sessionId");
        setSessionStarted(true);
    }

    const handleChange = (event) => {
        setSessionModeForSelection(event.target.value);
    };


    const letTheSessionBegin = () => {
    
        setSessionMode(sessionModeForSelection);
        localStorage.setItem('sessionMode', sessionModeForSelection);
    
        if (selectedSesstionType === "START") {
            setSessionStarted(true);
        }
        if (selectedSesstionType === "RESUME") {
            attemptToRestartSession(true);
        }
    }
    
    const [height, setHeight] = useState(210)

    return (
        <Modal
        open={open}
        onClose={() => {
            handleClose();
            setHasRecordedSessionType(false);
            setHeight(210);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        {currentWidth === 600 ? (
            <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "5px",
                height: height,
                transition: "height 0.3s ease",
                p: "40px 30px 40px 30px",
            }}>

            <div style={{ display: "flex" }}>
            <ReportGmailerrorredTwoToneIcon sx={{ fontSize: "50px", width: "50px", mr: 2, color: "#efc526" }} />
            <Typography>Oops! It appears your screen isn't compatible. Please select a resolution of 1920×1080 or a web-compatible resolution for the best viewing experience. We appreciate your cooperation!</Typography>
        </div>
            </Box>
        ) : (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    height: height,
                    transition: "height 0.3s ease",
                    p: "40px 30px 40px 30px",
                }}
            >
                {hasRecordedSessionType ? (
                    <div>
                        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex" }}>
                                <ReportGmailerrorredTwoToneIcon sx={{ fontSize: "50px", width: "50px", mr: 2, color: "#efc526" }} />
                            </div>
                            <FormLabel id="demo-radio-buttons-group-label">Select mode for this session</FormLabel>
                        </div>
                        <FormControl sx={{ marginLeft: 3 }}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={SESSION_MODE.SPEECH_MODE}
                                name="radio-buttons-group"
                                onChange={handleChange}
                            >
                                <FormControlLabel value={SESSION_MODE.SPEECH_MODE} control={<Radio />} label="Listen question and answer in your voice" />
                                <FormControlLabel value={SESSION_MODE.TEXT_MODE} control={<Radio />} label="Read question and type your answers" />
                            </RadioGroup>
                        </FormControl>
                        <Button onClick={letTheSessionBegin} fullWidth sx={{ marginTop: 1 }} variant='contained'>Start Session</Button>
                    </div>
                ) : (
                    <>
                        {loadingSessionDetails ? (
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                    <div style={{ display: "flex" }}>
                                        <ReportGmailerrorredTwoToneIcon sx={{ fontSize: "50px", width: "50px", mr: 2, color: "#efc526" }} />
                                        <Typography>{showMessage}</Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: ["RESUME", "START"].includes(ableToResumeSession) ? "100%" : 0,
                                        marginTop: ["RESUME", "START"].includes(ableToResumeSession) ? "20px" : "",
                                    }}
                                >
                                    {ableToResumeSession === "RESUME" ? (
                                        <>
                                            <Button sx={{ marginTop: "20px" }} variant="contained" onClick={() => { setSelectedSessionType("START"); setHasRecordedSessionType(true); setHeight(260); }}>
                                                Re-continue
                                            </Button>
                                            <Button sx={{ marginTop: "20px" }} variant="outlined" onClick={() => { setSelectedSessionType("RESUME"); setSessionType(clickedSessionType); setHasRecordedSessionType(true); setHeight(260); }}>
                                                New Session
                                            </Button>
                                        </>
                                    ) : ableToResumeSession === "START" ? (
                                        <Button variant="contained" onClick={() => { setSelectedSessionType("START"); setHasRecordedSessionType(true); setHeight(260); }}>
                                            Start
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
            </Box>
        )}
    </Modal>
    )
}
