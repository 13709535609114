import React, { useState, useEffect } from "react";
import PlansDataTable from "./DataTable";
import { PlanService } from "../../../../services";
import CustomLoader from "../../../../common/customLoader";
import { Typography } from "@mui/material";

export default function PlansConfig() {
  const [allPlansData, setAllPlansData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isData, setIsData] = useState(false);

  const getAllPlans = async () => {
    try {
      const allPlans = await PlanService.getAllPlans();

      if (allPlans && allPlans.length > 0) {
        setAllPlansData(allPlans);
        setIsData(true);
      } else {
        setNoDataFound(true)
      }
    } catch (error) {
      console.error("Error fetching plans:", error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}
      {noDataFound && (
        <div style={{ textAlign: "center" }}>
          <Typography>No data found</Typography>
        </div>
      )}
      {isData && (
        <PlansDataTable allPlansData={allPlansData} refetch={getAllPlans} />
      )}
    </>
  );
}
