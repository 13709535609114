import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import settingsModule from "../../../services/settings";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../managers/utility";
import { Button } from "@mui/material";

export default function MobileLLMPrompt() {
  const [mobilePrompt, setMobilePrompt] = useState("");
  const [zoomLevel, setZoomLevel] = useState(85);
  const [llmResponseTimeout, setLlmResponseTimeout] = useState("");

  const updatePromopt = async () => {
    if (!mobilePrompt.includes("$USERQUESTION")) {
      showErrorMessage("The word $USERQUESTION is required to be in prompt");
      return;
    }
    const response = await settingsModule.updateSettings({
      mobilePrompt: mobilePrompt,
    });
    if (response.acknowledged) {
      showSuccessMessage("Prompt Updated Succesfully");
      getCurrentValue();
    } else {
      showErrorMessage("Prompt Value Not Updated.");
    }
  };

  const updateZoomLevel = async () => {
    const response = await settingsModule.updateSettings({
      zoomLevel: zoomLevel,
    });
    if (response.acknowledged) {
      showSuccessMessage("Zoom Level Updated Succesfully");
      getCurrentValue();
    } else {
      showErrorMessage("Zoom Level Not Updated.");
    }
  };

  const llmResponse = async () => {
    if (!llmResponseTimeout) {
      showErrorMessage("PleaseFill the Field");
      return;
    }
    const response = await settingsModule.updateSettings({
      llmResponseTimeout: llmResponseTimeout,
    });
    if (response.acknowledged) {
      showSuccessMessage("LLM Respone Updated Succesfully");
    } else {
      showErrorMessage("LLM Respone Value Not Updated.");
    }
  };

  const getCurrentValue = async () => {
    const setting = await settingsModule.getSettings();
    if (setting && setting._id) {
      setMobilePrompt(setting.mobilePrompt);
      setZoomLevel(setting.zoomLevel ? setting.zoomLevel : 85);
    }
  };

  useEffect(() => {
    getCurrentValue();
  }, []);

  return (
    <div style={{ marginTop: "50px" }}>
      <Typography>Application Zoom Level</Typography>
      <TextField
          sx={{ width: "73%", marginRight: "5px" , marginTop: 1 }}
          id="outlined-basic"
          type="number"
          label=""
          multiline
          variant="outlined"
          value={zoomLevel}
          onChange={(e) => setZoomLevel(e.target.value)}
        />
        <Button
          variant="contained"
          style={{height:38, marginLeft:10 , marginTop: 14}}
          onClick={updateZoomLevel}
        >
          Update
        </Button>
      <Typography style={{marginTop: 14}}>Mobile App LLM Prompt</Typography>
      <div style={{ marginTop: 14, display: "flex", flexDirection: "row", alignItems:'center' }}>
        <TextField
          sx={{ width: "73%", marginRight: "5px" }}
          id="outlined-basic"
          type="number"
          label="Prompt"
          multiline
          variant="outlined"
          value={mobilePrompt}
          onChange={(e) => setMobilePrompt(e.target.value)}
        />
        <Button
          variant="contained"
          style={{height:38, marginLeft:10 }}
          onClick={updatePromopt}
        >
          Update
        </Button>
      </div>
      <Typography style={{ marginTop: "40px" }}>
        LLM Response Timeout
      </Typography>
      <div style={{ marginTop: 14, display: "flex", flexDirection: "row" }}>
        <TextField
          sx={{ width: "73%", marginRight: "5px" }}
          id="outlined-basic"
          type="text"
          label="LLM Response Timeout"
          variant="outlined"
          value={llmResponseTimeout}
          InputLabelProps={{
            shrink:
              llmResponseTimeout !== undefined && llmResponseTimeout !== null, // Conditionally apply shrink based on timerValues.HIGHEST
          }}
          onChange={(e) => setLlmResponseTimeout(e.target.value)}
        />

        <Button
          variant="contained"
          style={{ margin: 10 }}
          onClick={(e) => llmResponse(llmResponseTimeout)}
        >
          Update
        </Button>
      </div>
    </div>
  );
}
