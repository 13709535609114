import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";



const disorderModule = {
   addDisorder,
   deleteDisorder,
   updateDisorder,
   getAllDisorderData
};

export default disorderModule;

async function getAllDisorderData(requestData) {
    let url = process.env.REACT_APP_API_URI + "/api/private/all-disorder"
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function addDisorder(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/add-disorder/`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}



async function deleteDisorder(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-disorder/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.DELETE, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function updateDisorder(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-disorder/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

