import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, Link } from "react-router-dom";
import Home from "./Components/Session/Home";
import Admin from "./Components/AdminConsole/Admin";
import Corporate from "./Components/CorporateConsole/Admin";
import Testing from "./Components/Testing/TesingHome";
import MyReport from "./Components/Dashboard/Events/MyReport";
import MySubscription from "./Components/Dashboard/Events/MySubscription";
import MyAssessment from "./Components/Dashboard/Events/MyAssessment";
import Profile from "./Components/MyProfile/Profile";
import Dashboard from "./Components/Dashboard/Dashboard";
import Header from "./Components/Header/Header";
import Disorder from "./Components/Dashboard/Events/Order/Disorder";
import VerifiedUser from "./Components/IsVerifiedUser/VerifiedUser";
import NotVerifiedUser from "./Components/IsVerifiedUser/NotVerifiedUser";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";
import CustomLoader from "./common/customLoader";
import ProfileUpdate from "./Components/CorporateConsole/CorporateDashboard/isProfileUpdate";

function App() {
    const { userDetails } = useAuth();
    const verifiedRoutes = (userDetails && userDetails._id && userDetails.isVerified && !userDetails.isProfilePending) ? [
        { path: "/testing", element: <Testing /> },
        { path: "/mysubscription", element: <MySubscription /> },
        { path: "/myreport", element: <MyReport /> },
        { path: "/my-assessment", element: <MyAssessment /> },
        { path: "/profile", element: <Profile /> },
        { path: "/dashboard/:tabName", element: <Dashboard /> },
    ] : [];
    const userLoggedOutOrVerified = userDetails === "NOT-SET" || userDetails === "LOGGED_OUT" || (userDetails._id && userDetails.isVerified && !userDetails.isProfilePending);

    return (
        <>
            {userDetails === "NOT-SET" ?
                <CustomLoader />
                :
                <div>
                    <Router>
                        <Header />
                        <div>
                            {userDetails.role === "CORPORATE" ?
                                <Routes>
                                    {/* Use ternary operator to conditionally render routes */}
                                    <>
                                        <Route path="/corporate" element={<Corporate />} />
                                        <Route path="*" element={<Navigate to="/corporate" />} />
                                    </>
                                </Routes>
                                :
                                <Routes>
                                    <Route path="/admin" element={<Admin />} />
                                    <Route path="/disorder" element={<Disorder />} />
                                    {userLoggedOutOrVerified && <Route path="/" element={<Home />} />}
                                    {userDetails && userDetails._id && !userDetails.isVerified && (
                                        <>
                                            <Route path="/pending-user-verification" element={<NotVerifiedUser />} />
                                            <Route path="*" element={<Navigate to="/pending-user-verification" />} />
                                        </>
                                    )}
                                    {userDetails && userDetails._id && userDetails.isProfilePending === true && (
                                        <>
                                            <Route path="/profile-update" element={<ProfileUpdate />} />
                                            <Route path="*" element={<Navigate to="/profile-update" />} />
                                        </>)}
                                    <Route path="/verify-user/:verificationcode" element={<VerifiedUser />} />
                                    {verifiedRoutes.map(route => <Route key={route.path} path={route.path} element={route.element} />)}

                                    {["NOT-SET", "LOGGED_OUT"].includes("userDetails") && <Route path="*" element={<Navigate to="/" />} />}
                                    <Route path="*" element={
                                        <>
                                            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                                                <h1>404 - Page Not Found</h1>
                                                <p>Oops! The page you are looking for does not exist.</p>
                                                <p>It might have been moved or deleted.</p>
                                                <Link to="/">Go Home</Link>
                                            </div>
                                        </>
                                    } />
                                </Routes>

                            }
                        </div>
                        <ToastContainer
                            position="top-center"
                            autoClose={2500}
                            hideProgressBar
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange={false}
                            draggable={false}
                            pauseOnHover={false}
                            theme="colored"
                        />
                    </Router>
                </div >
            }
        </>

    );
}

export default App;
