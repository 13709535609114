import React, { useState, useEffect } from "react";
import { showSuccessMessage } from "../../../../managers/utility";
import { EntityService } from "../../../../services";
import CorporateModal from "./CorporateModal";
import CustomLoader from "../../../../common/customLoader";
import CorporateTable from "./CorporateTable"; // Import the CorporateTable component
import CouponModal from "./CouponModal";
const DataTable = ({ data, openAddModal, getCorporateData, refetch }) => {
  const [editableLicense, setEditableLicense] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isData, setIsData] = useState(false);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false); // State for coupon modal visibility
  const [rowData, setRowData] = useState("");

  useEffect(() => {
    setIsData(true);
    setIsLoading(false);
  }, [data]);

  const handleCheckClick = async (e, data, license) => {
    if (isNaN(license)) {
      return;
    }
    const saved = await EntityService.addEntityLicense({
      entityId: data._id,
      numberOfLicense: parseInt(license),
    });
    if (saved && saved._id) {
      showSuccessMessage("Updates successfully");
      getCorporateData();
      setShowInput(false);
    }
  };

  const handleButtonClick = (e, row) => {
    e.stopPropagation();
    setEditableLicense(row._id);
    setShowInput(true);
  };

  const handleOpenCouponModal = (e, row) => {
    e.stopPropagation();

    setIsCouponModalOpen(true);
    setRowData(row);
  };

  const handleCloseCouponModal = () => {
    setIsCouponModalOpen(false);
  };

  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}
      {isData && (
        <div style={{ marginTop: 0 }}>
          <CorporateTable
          refetch={refetch}
            data={data}
            openAddModal={openAddModal}
            showInput={showInput}
            editableLicense={editableLicense}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleCheckClick={handleCheckClick}
            handleButtonClick={handleButtonClick}
            handleOpenCouponModal={handleOpenCouponModal}
            getCorporateData={getCorporateData}
          />
        </div>
      )}
      <CouponModal
        rowData={rowData}
        isOpen={isCouponModalOpen}
        onClose={handleCloseCouponModal}
        getCorporateData={getCorporateData}
      />{" "}
      {/* Render the CouponModal */}
    </>
  );
};

const App = () => {
  const [apiData, setApiData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getCorporateData();
  }, []);

  const getCorporateData = async () => {
    try {
      const response = await EntityService.getEntityData();
      if (response && response.length >= 0) {
        setApiData(response);
      }
    } catch (error) {
      console.error("Error fetching corporate data:", error);
    }
  };

  const openAddModal = () => {
    setIsModalOpen(true);
  };

  const closeAddModal = () => {
    getCorporateData();
    setIsModalOpen(false);
  };

  return (
    <div>
      <div style={{fontWeight:"600"}}>Entity List</div>
      <DataTable
      refetch={getCorporateData}
        data={apiData}
        openAddModal={openAddModal}
        getCorporateData={getCorporateData}
      />
      <CorporateModal
        isOpen={isModalOpen}
        getCorporateData={getCorporateData}
        onCloseModal={closeAddModal}
      />
    </div>
  );
};
export default App;
