import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Button, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SettingsService } from "../../../../services";
import { showSuccessMessage } from "../../../../managers/utility";

// import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function ModalDisorder({
  openDescription,
  handleCloseDemo,
  selectedDisorderData,
  setIsModalOpen,
  setModalTitle,
  getCategoryInfo,
  categoryName,
  selectedCategoryId,
  title,
  setAllDisorderInformation,
  allDisorderInformation,
  getDisorderData,
}) {
  const [value, setValue] = useState("");
  const [disorderName, setDisorderName] = useState("");
  React.useEffect(() => {
    if (selectedDisorderData && selectedDisorderData?.description) {
      setValue(selectedDisorderData?.description);
    }
    if (selectedDisorderData && selectedDisorderData?.name) {
      setDisorderName(selectedDisorderData?.name);
    }
  }, [selectedDisorderData]);

  const updateData = (data) => {
    setValue(data);
  };

  const updateDisorderName = (e) => {
    setDisorderName(e.target.value);
  };

  const saveChanges = async () => {
    const payload = {
      name: disorderName,
      description: value,
      categoryId: selectedCategoryId._id,
      _id: selectedDisorderData._id
    }
    const response = await SettingsService.updateDisorderDetailsAPI(payload);

    if (response && response.categoryId) {
      setIsModalOpen(false); // Close the modal
      setModalTitle(disorderName); // Update the title
      getCategoryInfo(selectedCategoryId);
      showSuccessMessage(response.message);
    }
  };

  const deleteChanges = async () => {
    console.log('delete disorder')
    const payload = {
      name: disorderName,
      description: value,
      categoryId: selectedDisorderData._id,
    }
    const response = await SettingsService.deleteDisorderDetailsAPI(payload);

    if (response && response.categoryId) {
      setIsModalOpen(false); // Close the modal
      getCategoryInfo(selectedCategoryId);
      showSuccessMessage("Deleted Disorder Details");
    }
  }

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "align", // Add 'align' to enable text alignment options
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }], // Text alignment options
      ["blockquote", "list", "bullet", "indent"],
      // ["link", "image", "color", "background"],
    ],
  };

  return (
    <div>
      <Modal
        open={openDescription}
        onClose={handleCloseDemo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            borderRadius: "8px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            maxWidth: "900px",
            backgroundColor: "white",
            padding: "50px",
            height: "75%",
          }}
        >
          <div style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
            <div style={{ marginLeft: "auto" }}>
              <IconButton onClick={handleCloseDemo}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <TextField
            label="Disorder Name"
            variant="outlined"
            value={disorderName}
            onChange={updateDisorderName}
            style={{ width: "100%", marginBottom: '10px' }}
          />
          <ReactQuill
            theme="snow"
            modules={modules}
            value={value}
            formats={formats}
            onChange={updateData}
            style={{ height: "calc(100% - 170px)" }} // Adjust the height as needed
          />
          <div style={{ display: 'flex' }}>
            <Button variant="contained" style={{ marginTop: 50, marginRight: '10px', width: '50%' }} onClick={() => saveChanges()}>
              Save
            </Button>
            <Button variant="outlined" style={{ marginTop: 50, width: '50%' }} onClick={() => deleteChanges()}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
