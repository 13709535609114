import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import React from 'react'
import ReportGmailerrorredTwoToneIcon from '@mui/icons-material/ReportGmailerrorredTwoTone';
import { showErrorMessage } from '../../managers/utility';



export default function ReadyForSessionModal({ setUseTrial, didClickedOnTrial, open, userDetails, handleClose, ableToResumeSession, showMessage, useTrial, setSessionStarted, loadingSessionDetails }) {


    const attemptToRestartSession = () => {
        if (!userDetails.remainingTrialSession && didClickedOnTrial) {
            showErrorMessage("No Trial Sessions Left in account")
            return
        }
        if (userDetails.remainingTrialSession && didClickedOnTrial) {
            // setUseTrial(true)
        }
        if (!userDetails.remainingSession && !didClickedOnTrial) {
            showErrorMessage("No Sessions Left in account")
            return
        }
        if (userDetails.remainingSession && !didClickedOnTrial) {
            // setUseTrial(false)
        }
        localStorage.removeItem("sessionId");
        setSessionStarted(true);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    height: 230,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    p: "40px 30px 40px 30px",
                }}
            >
                {loadingSessionDetails ?
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box> :
                    <>
                        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex" }}>
                                <ReportGmailerrorredTwoToneIcon sx={{ fontSize: "50px", width: "50px", mr: 2, color: "#efc526" }} />
                                <Typography>
                                    {showMessage}
                                </Typography>
                            </div>

                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: ["RESUME", "START"].includes(ableToResumeSession) ? "100%" : 0,
                                marginTop: ["RESUME", "START"].includes(ableToResumeSession) ? "40px" : "",
                            }}
                        >
                            {ableToResumeSession === "RESUME" ?
                                <>
                                    <Button
                                        variant="contained"
                                        onClick={() => setSessionStarted(true)}
                                    >
                                        Continue
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => attemptToRestartSession()}
                                    >
                                        Restart
                                    </Button>
                                </> : ableToResumeSession === "START" ?
                                    <Button
                                        variant="contained"
                                        onClick={() => setSessionStarted(true)}
                                    >
                                        Start
                                    </Button> : ""}
                        </div>
                    </>}

            </Box>
        </Modal>
    )
}
