import { Button, Typography } from "@mui/material";
import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { showSuccessMessage } from "../../managers/utility";

export default function NotVerifiedUser() {

  const regenerateLink = async () => {
    // const response = await CouponService.getRegenerateLink({
    //   email: userDetails.email,
    // });
    // if (response && response._id) {
    //   showSuccessMessage(
    //     "Verification Link Sent Successfully.Please Check Your Email"
    //   );
    // } else {
    //   showErrorMessage(response.message);
    // }
    showSuccessMessage(
      "Verification Link Sent. Please Check Your Email"
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10%",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        style={{
          fontSize: 26,
          color: "Red",
          marginTop: 20,
          padding: "5px 10px 5px 20px",
        }}
      >
        Your Email Is Not Verified !
      </Typography>
      <Typography variant="h7" component="div" style={{ marginTop: 20 }}>
        If You Want to Regenerate the Verification Link
      </Typography>
      <Typography variant="h7" component="div">
        Click on this Button
      </Typography>
      <Button
        onClick={() => {
          regenerateLink();
        }}
        style={{
          backgroundColor: "green",
          fontSize: 16,
          color: "white",
          marginTop: 20,
          padding: "5px 10px 5px 20px",
        }}
      >
        Regenerate Link
        <KeyboardDoubleArrowRightIcon sx={{ fontSize: 36 }} />
      </Button>
    </div>
  );
}
