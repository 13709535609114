import React, { useState } from "react";
import {
  Button,
  Modal,
  TextField,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useAuth } from "../../../AuthContext";
import AuthUserService from "../../../services/authUser";
import ReCAPTCHA from "react-google-recaptcha-enterprise";


import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../managers/utility";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import UserLogin from "./UserLogin";
import SignUp from "./SignUp";
import ForgetPassword from "./ForgetPassword";
import CorporateOrEmployeeLogin from "./CorporateOrEmployeeLogin";


const LoginForm = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [loginAs, setLoginAs] = useState("Corporate");
  const [showPin, setShowPin] = useState(false);
  const [showNewPassPin, setShowNewPassPin] = useState(false);
  const [showReEnterPassPin, setShowReEnterPassPin] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");
  const [otp, setOtp] = useState("");
  const [state, setState] = useState("Login User"); //for toggle login or forgot form
  const { setUserDetails } = useAuth();



  //for open forgot password form
  const forgotPassword = () => {
    setState("Forget Password");
  };

  const changeState = (state) => {
    if (state === "Login") {
      setState("Login");
      setEmailForgot("");
      setIsOtp("");
      setEmail("");
      setPassword("");
    }
    if (state === "Login User") {
      setState("Login User");
      setEmailForgot("");
      setIsOtp("");
      setEmail("");
      setPassword("");
    }
    if (state === "Forget Otp") {
      getOtp("Forget Otp");
    }
  };

  const getOtp = async (state) => {
    if (!captchaValue) {
      showErrorMessage("Captcha is Required");
      return
    }
    const payload = {
      email: emailForgot,
    };
    try {
      const response = await AuthUserService.GetOtp(payload);
      if (response) {
        showSuccessMessage(response.message);
        setState("Forget Otp");
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      console.error("Error fetching OTP:", error);
      showErrorMessage(error.message);
    }
  };

  const handleToggleVisibility = (field) => {
    if (field === "password") {
      setShowPin((prevShowPin) => !prevShowPin);
    } else if (field === "newPassword") {
      setShowNewPassPin((prevShowNewPassPin) => !prevShowNewPassPin);
    } else if (field === "reEnterPassword") {
      setShowReEnterPassPin(
        (prevShowReEnterPassPin) => !prevShowReEnterPassPin
      );
    }
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setLoginAs("Corporate");
    } else {
      setLoginAs("Employee");
    }
  };

  const registerUser = () => {
    setState("signup");
    setName("");
    setDOB("");
    setGender("");
    setEmail("");
    setPassword("");
    setMobile("");
  };

  const signUp = async () => {
    try {
      const response = await AuthUserService.registerNewUser({
        name: name,
        mobile: mobile,
        dateOfBirth: dob,
        gender: gender,
        email: email,
        password: password,
      });
      if (response && response._id) {
        showSuccessMessage("Sign Up Successfully");
        // localStorage.setItem("userDetails", JSON.stringify(response));

        navigate("/");
        onClose();
      } else if (response.error) {
        showErrorMessage(response.error);
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      console.error("Error during Sign Up", error);
      showErrorMessage(error.message);
    }
  };

  const findUserLocation = () => {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            resolve({ lat, lng }); // Resolve the promise with location data
          },
          error => {
            console.error('Error getting user location:', error);
            reject(error); // Reject the promise with the error
          }
        );
      } else {
        const error = new Error('Geolocation is not supported by this browser.');
        console.error(error.message);
        reject(error); // Reject the promise if geolocation is not supported
      }
    });
  };
  

  const signIn = async () => {
    try {

      const response = await AuthUserService.loginUser({
        email: email,
        password: password,
        // loginAs: loginAs.toUpperCase(),
      });
      if (
        response &&
        response.userDetails &&
        response.userDetails.isVerified === true
      ) {
        showSuccessMessage("Sign In Successfully");
        localStorage.setItem("token", response.token);
        setUserDetails({ ...response.userDetails, type: "USER" });
        localStorage.setItem(
          "userDetails",
          JSON.stringify({ ...response.userDetails, type: "USER" })
        );
        onClose();
      } else if (response && response.userDetails && !response.userDetails.isVerified) {
        localStorage.setItem("token", response.token);
        setUserDetails({ ...response.userDetails, type: "USER" });
        localStorage.setItem(
          "userDetails",
          JSON.stringify({ ...response.userDetails, type: "USER" })
        );
        navigate('/pending-user-verification')
        showErrorMessage(
          "Your Email Is not Verified! Please check your Email."
        );
      } else if (response && response.userDetails && response.userDetails.isProfilePending) {
        localStorage.setItem("token", response.token);
        setUserDetails({ ...response.userDetails, type: "USER" });
        localStorage.setItem(
          "userDetails",
          JSON.stringify({ ...response.userDetails, type: "USER" })
        );
        navigate('/pending-updated')
        showErrorMessage(
          "Your Email Is not Verified! Please check your Email."
        );
      }

      else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  const [captchaValue, setCaptchaValue] = useState("captch")
  const onCaptchaChange = (e) => {
    setCaptchaValue(e)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 0,
        }}
      >
        {/* Login Form start */}
        {state === "Login" ? (
          <CorporateOrEmployeeLogin
            password={password}
            setPassword={setPassword}
            handleToggleVisibility={handleToggleVisibility}
            onClose={onClose}
            value={value}
            handleChange={handleChange}
            InputAdornment={InputAdornment}
            forgotPassword={() => forgotPassword()} // Pass forgotPassword as a function
            changeState={changeState}
            loginAs={loginAs}


          />
        ) :
          state === "Forget Password" ? (
            <div
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                maxWidth: "400px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3
                  style={{
                    color: "#1877f2",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "24px",
                  }}
                >
                  Forgot Password
                </h3>
                <ClearIcon color="primary" onClick={onClose} />
              </Box>
              {/* <CustomTabPanel value={value} index={0}> */}
              <Typography variant="h5" gutterBottom>
                <IconButton
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  onClick={onClose}
                ></IconButton>
              </Typography>

              <TextField
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                value={emailForgot}
                onChange={(e) => setEmailForgot(e.target.value.toLowerCase())}
              />

              <ReCAPTCHA
                sitekey="6LdfcLYpAAAAAIc8lu82Q2jVYOjCfF1AekVeQ3Do"
                onChange={onCaptchaChange}
              />,
              <div style={{}}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => changeState("Forget Otp")}
                  style={{ width: "100%", height: "50px", fontSize: "15px" }}
                >
                  get otp on email
                </Button>
                <div
                  style={{
                    color: "#1877f2",
                    fontSize: "18px",
                    fontWeight: "500",
                    cursor: "pointer",
                    textDecoration: "none",
                    textAlign: "right",
                  }}
                >
                  <hr></hr>
                  <div
                    style={{ textAlign: "center" }}
                    onClick={() => changeState("Login User")}
                  >
                    Go Back
                  </div>
                </div>
              </div>
            </div>
          ) : state === "Forget Otp" ? (
            <ForgetPassword
              emailForgot={emailForgot}
              onClose={() => onClose()}
              // resetPasswword={() => changeState("Forget Otp")}
              changeState={() => {
                changeState("Login User")
              }}
              otp={otp}
              showNewPassPin={showNewPassPin}
              showReEnterPassPin={showReEnterPassPin}
              isOtp={isOtp}
              setIsOtp={setIsOtp}
              setOtp={setOtp}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              reEnterPassword={reEnterPassword}
              setReEnterPassword={setReEnterPassword}
            />
          ) : //forgot form end here
            state === "Login User" ? (
              <UserLogin
                password={password}
                setPassword={setPassword}
                InputAdornment={InputAdornment}
                changeState={() => changeState(state === "Login User" ? "Login" : "Login User")}
                registerUser={registerUser}
                onClose={onClose}
                email={email}
                signIn={signIn}
                showPin={showPin}
                setEmail={setEmail}
                handleToggleVisibility={() => handleToggleVisibility("password")}
                forgotPassword={() => forgotPassword()} // Pass forgotPassword as a function
              />
            ) : state === "signup" ? (
              <SignUp
                setName={setName}
                password={password}
                setPassword={setPassword}
                InputAdornment={InputAdornment}
                changeState={() => changeState("Login User")}
                registerUser={registerUser}
                onClose={onClose}
                email={email}
                signIn={signIn}
                showPin={showPin}
                setEmail={setEmail}
                handleToggleVisibility={() => handleToggleVisibility("password")}
                signUp={signUp}
                setMobile={setMobile}
                mobile={mobile}
                name={name}
                dob={dob}
                gender={gender}
                setDOB={setDOB}
                setGender={setGender}
              />
            ) : (
              "Login User"
            )}
      </Modal>
    </div>
  );
};
export default LoginForm;
