import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Box,
  TableSortLabel,
  Button,
} from '@mui/material';
import { EntityService } from '../../../../services';
import CouponList from './CouponList';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../../../common/confirmationDialog';

export default function DetailsView({ refetch,rowData, setIsTableVisible, apiData }) {
  console.log(apiData, rowData);
  const [couponList, setCouponList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
    getCouponbyEntity(rowData?._id);
  }, [rowData?._id]); // Include rowData._id in the dependency array

  useEffect(() => {
    setSortedData(apiData);
  }, [apiData]);

  const getCouponbyEntity = async id => {
    const payload = {
      entityId: id || '',
    };
    try {
      const response = await EntityService.getCouponbyEntity(payload);
      setCouponList(response);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  const handleSearchChange = event => {
    setSearchText(event.target.value);
    filterData(event.target.value);
  };

  const filterData = text => {
    const filteredData = apiData.filter(row => row.name.toLowerCase().includes(text.toLowerCase()));
    setSortedData(filteredData);
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sorted = [...sortedData].sort((a, b) => {
      const propA = a[property] ?? '';
      const propB = b[property] ?? '';
      return isAsc ? propA.localeCompare(propB) : propB.localeCompare(propA);
    });
    setSortedData(sorted);
  };

  const handleDeleteClick = async () => {
    const data = await EntityService.deleteEntity({ entityId: rowData._id });
    if(data.message === "Entity Deleted"){
      setIsTableVisible(true);
      refetch()
    }
    console.log(data);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
        <TextField label="Search" variant="outlined" size="small" value={searchText} onChange={handleSearchChange} />
      </Box>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Entity Name : {rowData.name}</span>
          <span> Paid Licenses Remaining : {rowData.numberOfLicense}</span>
          <span> Collection Type : {rowData.collectionType}</span>
        </div>
        <div>
        <ConfirmationDialog actionOnConfirm={handleDeleteClick}>
        <Button
          aria-label="Delete"
          color="secondary"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </ConfirmationDialog>
        </div>
      </div>
      <TableContainer component={Paper} style={{ marginTop: 20, marginBottom: 20 }}>
        {sortedData && sortedData.length > 0 ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      Name
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'email'}
                    direction={orderBy === 'email' ? order : 'asc'}
                    onClick={() => handleRequestSort('email')}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      Email
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'mobile'}
                    direction={orderBy === 'mobile' ? order : 'asc'}
                    onClick={() => handleRequestSort('mobile')}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      Mobile
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map(row => (
                <TableRow key={row?._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ cursor: 'pointer' }}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell align="center">{row?.email}</TableCell>
                  <TableCell align="center">{row?.mobile}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div style={{ textAlign: 'center', margin: '10px 0' }}>No User Found</div>
        )}
      </TableContainer>

      {couponList && <CouponList couponList={couponList} />}
    </>
  );
}
