import { Box, Button, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import { sessionTypes } from "../../../common/constants";

export default function DetailsView({ selectedSession, setSelectedSession }) {
  return (
    <>
      <Button
        sx={{ marginBottom: 1 }}
        onClick={() => setSelectedSession(null)}
        variant="contained"
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
      <Grid container>
        <Grid item lg={6}>
          <Paper sx={{
            padding: 2, marginTop: 1, whiteSpace: "pre-line",
            lineHeight: "25px",
            flexWrap: "wrap",
            justifyContent: "center",
            overflow: "auto",
            maxHeight: "250px",
            height: "250px"
          }} elevation={1}>
            <Typography><b>Name</b> : {selectedSession.userName}</Typography>
            <Typography sx={{ mt: 2 }}><b>Date</b> : {moment(selectedSession.createdAt).format("DD-MM-YYYY HH:MM")}</Typography>
            <Typography sx={{ mt: 2 }}> <b> Session Type</b> 
          {(selectedSession && selectedSession.sessionType) ?
            <Chip label={`${sessionTypes[selectedSession.sessionType]} Session`} color="primary" size="small" /> :
              ""} 
              </Typography>

            <Typography sx={{ mt: 2 }}><b>Detected Disorders</b> :</Typography>
            {selectedSession.final.map((d, i) => (
              <Typography>&nbsp;&nbsp;&nbsp;{i + 1} - {d}</Typography>
            ))}
          </Paper>
        </Grid>

        <Grid item lg={6}>
          <Box sx={{ width: "100%", marginLeft: 1, overflow: "scroll", maxHeight: "250px", height: "100%" }}>
            <Paper sx={{ padding: 2, marginTop: 1 }} elevation={1}>
              <b>Given Answers</b>
              <Divider />
              <Grid container sx={{ display: "flex", flex: 1 }}>
                {/* {selectedSession &&
                  selectedSession.answerWithString &&
                  Object.keys(selectedSession?.answerWithString).map((item) => (
                    <Grid item lg={6}>
                      {item} - {selectedSession.answerWithString[item]} <br />
                    </Grid>
                  ))} */}
                {selectedSession &&
                  selectedSession.answers &&
                  selectedSession.answers.map((item) => (
                    <Grid item lg={3}>
                      <Typography fontWeight={600} sx={{ color: item.includes("S") ? "red" : "black" }}>{item}</Typography>
                    </Grid>
                  ))}
              </Grid>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Paper sx={{
            padding: 2,
            marginTop: 1, 
            whiteSpace: "pre-line",
            lineHeight: "25px",
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
            overflow: "scroll",
            maxHeight: "600px"
          }} elevation={3}>
            <b>Session Logs</b>
            <Divider />
            {selectedSession.log}
          </Paper>
        </Grid >
      </Grid >
      <Paper sx={{ padding: 2, marginTop: 1 }} elevation={1}>
        <b>LLM Answers</b>
        <Divider />
        <div
          style={{
            whiteSpace: "pre-line",
            lineHeight: "25px",
            display: "flex",
            flexWrap: "wrap",
            //   justifyContent: "center",
            height: "300px",
            overflow: "auto",
          }}
        >
          {selectedSession.LLMAnswers &&
            Object.keys(selectedSession.LLMAnswers).map((key) => (
              <>
                {key}- {selectedSession.LLMAnswers[key]}
                <br />
              </>
            ))}
        </div>
      </Paper>
    </>
  );
}
