import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import Button from "@mui/material/Button";
import MediaModal from "./Modals/MediaModalCategory";
import { MediaService } from "../../../../services";
import MediaMain from "./Media";

const ThumbnailTableView = ({ data, setIsLoading, refetch }) => {
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [videoList, setVideoList] = useState(false); // Corrected initialization
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataList, setDataList] = useState(null);

  const openMediaModal = () => {
    setIsMediaModalOpen(true);
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    openMediaModal();
  };

  const getOneMediaCategory = async (item) => {
    const res = await MediaService.getOneMediaCategoryByIdAPI(item?._id);
    if (res && res.category && res.category._id) {
      setDataList(res);
      setVideoList(true);
    }
  };
  const handleButtonClick = (event, item) => {
    event.stopPropagation(); // Prevent the row's onClick from firing
    getOneMediaCategory(item);
  };

  return (
    <>
      {!videoList && (
        <>
          <div style={{ textAlign: 'left' }}>
            <Button variant="contained" onClick={openMediaModal}>
              Add
            </Button>
          </div>
          <TableContainer sx={{ mt: 3 }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Thumbnail</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Videos Count</TableCell>
                  <TableCell align="center">Videos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow onClick={() => handleRowClick(item)} key={item.id}>
                    <TableCell>
                      <Avatar
                        style={{
                          width: "150px",
                          height: "90px",
                          cursor: "pointer",
                        }}
                        variant="square"
                        alt={item.title}
                        src={item.thumbnail}
                      />
                    </TableCell>
                    <TableCell style={{ cursor: "pointer" }}>
                      {item.title}
                    </TableCell>
                    <TableCell style={{ cursor: "pointer" }}>
                      {item.numberOfVideos}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={(event) => handleButtonClick(event, item)}
                      >
                        Visit Here
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {videoList && (
        <MediaMain
          setIsLoading={setIsLoading}
          setVideoList={setVideoList}
          dataList={dataList}
          refetch={getOneMediaCategory}
        />
      )}

      <MediaModal
        setIsLoading={setIsLoading}
        isMediaModalOpen={isMediaModalOpen}
        refetch={refetch}
        selectedItem={selectedItem}
        setIsMediaModalOpen={setIsMediaModalOpen}
      />
    </>
  );
};

export default ThumbnailTableView;
