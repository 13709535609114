import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import CRUDOperations from "./Crud/CrudIndex";
import Entity from "./Entity/Main";
import SessionDash from "./SessionsDash/SessionDash";
import MediaControl from "./Media/MediaControl";
import Config from "./Config/Config";
import AdminLogin from "./AdminLogin";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import LandingPage from "./LandingPage/LandingPage";
import UpdateVersion from "./UpdateVersion/UpdateVersion";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EntityFareIcon from "@mui/icons-material/CorporateFare";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import TaskIcon from "@mui/icons-material/Task";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import PlansConfigTabs from "./Config/PlansConfigTabs";
import UserDetails from "./Userdetails/UserDetails";

const drawerWidth = 240;

function ResponsiveDrawer(props) {

  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("CRUD");

  useEffect(() => {
    checkIfAdminIsLoggedIn();
  }, []);

  useEffect(() => {
    checkIfAdminIsLoggedIn();
    // Retrieve the active tab name from local storage
    const storedTab = localStorage.getItem("activeTab");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);


  const checkIfAdminIsLoggedIn = async () => {
    let adminData = localStorage.getItem("isAdminLoggedIn");
    if (adminData && JSON.parse(adminData)._id) setIsAdminLoggedIn(true);
    setLoading(false);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // Store the selected tab name in local storage
    localStorage.setItem("activeTab", tabName);
  };

  let component;

  switch (activeTab) {
    case "Entity":
      component = <Entity />;
      break;
    case "CRUD":
      component = <CRUDOperations />;
      break;
    case "USER_DETAILS":
      component = <UserDetails />;
      break;
    case "SESSION":
      component = <SessionDash />;
      break;
    case "MEDIA":
      component = <MediaControl />;
      break;
    case "CONFIG":
      component = <Config />;
      break;
    case "LANDING":
      component = <LandingPage />;
      break;
    case "UPDATEVERSION":
      component = <UpdateVersion />;
      break;
    case "PLANSCONFIG":
      component = <PlansConfigTabs />;
      break;
    default:
  }


  const adminSideBar = () => (
    <List>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ backgroundColor: activeTab === "CRUD" ? "#cfcfcf" : "" }}
          onClick={() => handleTabClick("CRUD")}
        >
          <BusinessCenterIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Crud Operations" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ backgroundColor: activeTab === "USER_DETAILS" ? "#cfcfcf" : "" }}
          onClick={() => handleTabClick("USER_DETAILS")}
        >
          <BusinessCenterIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="User Details" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ backgroundColor: activeTab === "Entity" ? "#cfcfcf" : "" }}
          onClick={() => handleTabClick("Entity")}
        >
          <EntityFareIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Entity" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ backgroundColor: activeTab === "SESSION" ? "#cfcfcf" : "" }}
          onClick={() => handleTabClick("SESSION")}
        >
          <AssessmentIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Sessions" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ backgroundColor: activeTab === "MEDIA" ? "#cfcfcf" : "" }}
          onClick={() => handleTabClick("MEDIA")}
        >
          <PlayCircleOutlineIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Media" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ backgroundColor: activeTab === "CONFIG" ? "#cfcfcf" : "" }}
          onClick={() => handleTabClick("CONFIG")}
        >
          <PermDataSettingIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Config" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ backgroundColor: activeTab === "LANDING" ? "#cfcfcf" : "" }}
          onClick={() => handleTabClick("LANDING")}
        >
          <TaskIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Landing Page" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            backgroundColor: activeTab === "UPDATEVERSION" ? "#cfcfcf" : "",
          }}
          onClick={() => handleTabClick("UPDATEVERSION")}
        >
          <SystemUpdateIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Update Version" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            backgroundColor: activeTab === "PLANSCONFIG" ? "#cfcfcf" : "",
          }}
          onClick={() => handleTabClick("PLANSCONFIG")}
        >
          <EditRoadIcon sx={{ marginRight: 1 }} />
          <ListItemText primary="Plans Config" />
        </ListItemButton>
      </ListItem>
    </List>
  )


  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      {adminSideBar()}

      <Divider />
    </div>
  );

  return (
    <>
      {isLoading ?
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
        : (
          <>
            {!isAdminLoggedIn ? (
              <AdminLogin setIsAdminLoggedIn={setIsAdminLoggedIn} />
            ) :
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Box
                  component="nav"
                  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 0 }}
                  aria-label="mailbox folders"
                >
                  {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                  <Drawer
                    variant="permanent"
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                  >
                    {drawer}
                  </Drawer>
                </Box>
                <Box
                  component="main"
                  sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                  {component}
                </Box>
              </Box>}
          </>
        )}
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
