import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import SettingsService from "../../../../services/settings";
import { showErrorMessage, showSuccessMessage } from "../../../../managers/utility";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export default function SkippedQuestions() {
  const [skippedQuestionsAnswers, setSkippedQuestionsAnswers] = useState([{ question: "", answers: [""] }]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [isAnswerEdited, setIsAnswerEdited] = useState(false);

  useEffect(() => {
    getQuestionAnswer();
  }, []);

  const getQuestionAnswer = async () => {
    try {
      const res = await SettingsService.getQuestionAnswer();
      if (res?.[0]?.skippedQuestionsAnswers) {
        setSkippedQuestionsAnswers(res[0].skippedQuestionsAnswers);
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  const handleQuestionChange = (index, e) => {
    const updatedSkippedQuestionsAnswers = [...skippedQuestionsAnswers];
    updatedSkippedQuestionsAnswers[index].question = e.target.value.replace("$", "");
    setSkippedQuestionsAnswers(updatedSkippedQuestionsAnswers);
  };

  const handleAnswersChange = (event, index, answerIndex) => {
    const updatedSkippedQuestionsAnswers = [...skippedQuestionsAnswers];
    updatedSkippedQuestionsAnswers[index].answers[answerIndex] = event.target.value;
    setSkippedQuestionsAnswers(updatedSkippedQuestionsAnswers);
    setIsAnswerEdited(true); // Set flag to true when an answer field is edited
  };

  const addAnswerField = (index) => {
    const updatedSkippedQuestionsAnswers = [...skippedQuestionsAnswers];
    updatedSkippedQuestionsAnswers[index].answers.push("");
    setSkippedQuestionsAnswers(updatedSkippedQuestionsAnswers);
  };

  const removeAnswerField = (index, answerIndex) => {
    const updatedSkippedQuestionsAnswers = [...skippedQuestionsAnswers];
    updatedSkippedQuestionsAnswers[index].answers.splice(answerIndex, 1);
    setSkippedQuestionsAnswers(updatedSkippedQuestionsAnswers);
  };

  const addRemoveRow = (action, index) => {
    const updatedSkippedQuestionsAnswers = [...skippedQuestionsAnswers];
    if (action === "ADD") {
      updatedSkippedQuestionsAnswers.splice(index + 1, 0, { question: "", answers: [""] });
    } else if (action === "REMOVE" && updatedSkippedQuestionsAnswers.length > 1) {
      updatedSkippedQuestionsAnswers.splice(index, 1);
    }
    setSkippedQuestionsAnswers(updatedSkippedQuestionsAnswers);
  };

  const handleAddQuestion = () => {
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    setIsModalOpen(false);
    setIsAnswerEdited(false); // Reset the flag after saving
    try {
      const newSkippedQuestionData = {
        _id: { $oid: "6524aee1f523e3c3be4ba56f" },
        useLLM: false,
        skippedQuestionsAnswers: skippedQuestionsAnswers,
      };
      const response = await SettingsService.addQuestionAnswer(newSkippedQuestionData);
      if (response._id) {
        showSuccessMessage("Saved");
      } else {
        showErrorMessage("Failed to add the question");
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsAnswerEdited(false); // Reset the flag when modal is closed
  };

  return (
    <div>
      {skippedQuestionsAnswers.map((qa, index) => (
        <div key={index} style={{ display: "flex", marginTop: "10px" }}>
          <TextField
            label="Question"
            variant="outlined"
            value={qa.question}
            onChange={(e) => handleQuestionChange(index, e)}
          />
          {qa.answers.map((answer, answerIndex) => (
            <div key={answerIndex} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <TextField
                sx={{ ml: 1 }}
                label="Answer"
                variant="outlined"
                value={answer}
                onChange={(e) => handleAnswersChange(e, index, answerIndex)}
              />
              {answerIndex > 0 && (
                <Button variant="outlined" sx={{ mt: 1 }} onClick={() => removeAnswerField(index, answerIndex)}>
                  <RemoveIcon />
                </Button>
              )}
            </div>
          ))}
          <Button variant="outlined" sx={{ ml: 1 }} onClick={() => addAnswerField(index)}>
            <AddIcon />
          </Button>
          <div style={{ marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Button fullWidth size="small" variant="outlined" onClick={() => addRemoveRow("ADD", index)}>{`Add Row`}</Button>
            <Button size="small" variant="outlined" onClick={() => addRemoveRow("REMOVE", index)}>Remove Row</Button>
          </div>
        </div>
      ))}

      <Button sx={{ mt: 3 }} variant="contained" onClick={handleAddQuestion}>
        Save <SaveIcon />
      </Button>

      <Modal
        open={isModalOpen && isAnswerEdited} // Open the modal only if an answer field is edited
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: "absolute", width: 400, bgcolor: "white", boxShadow: 24, p: 4, top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <div>Are you sure you want to edit?</div>
          <TextField
            sx={{ mt: 2 }}
            label="Please Enter edit text"
            variant="outlined"
            value={confirmationText}
            fullWidth
            onChange={(e) => setConfirmationText(e.target.value)}
          />
          <div style={{display:'flex', marginTop:"20px"}}>
          <Button sx={{ mr: 2 }} fullWidth variant="contained" onClick={handleSave}>Yes</Button>
          <Button fullWidth variant="outlined" onClick={handleCloseModal}>No</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
