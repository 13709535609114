import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useAuth } from "../../../AuthContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function MenuListComposition({ setViewLoginModal }) {
  const { userDetails, setResetPasswordModal } = useAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      event &&
      event.target &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      try {

        anchorRef.current.focus();
      } catch {
      }
    }

    prevOpen.current = open;
  }, [open]);

  const OpenDashboard = (value) => {
    navigate(`/dashboard/${value}`);
  };

  return (
    <Stack direction="row" spacing={2}>
      {(userDetails?._id || localStorage.getItem('isAdminLoggedIn')) ? (
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <Button
            ref={anchorRef}
            id="composition-button"
            sx={{ margin: "0 10px 0 10px", backgroundColor: "#ffcf00" }}
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            variant="contained"
            onClick={handleToggle}
          >
            <form className="userprofile ms-0">
              <div className="dropdown">
                <AccountCircleRoundedIcon sx={{ color: "black" }} />
              </div>
            </form>
          </Button>
          <div></div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{ zIndex: 1 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                  marginLeft: "8px",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      sx={{ width: "180px" }}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {/* <MenuItem onClick={() => resetSessionManually()}>
                        Reset
                      </MenuItem> */}
                      <MenuItem onClick={() => OpenDashboard("Profile")}>
                        My account
                      </MenuItem>
                      <MenuItem onClick={() => setResetPasswordModal(true)}>
                        Change Password
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          localStorage.clear();
                          window.location.href = "/"
                          handleClose();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      ) : (
        <Button
          onClick={() => {
            setViewLoginModal(true);
          }}
        >
          Login
        </Button>
      )}
    </Stack>
  );
}
