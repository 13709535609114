import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";


const questionModule = {
    getQuestions,
    getQuestionsTest,
    getAllQuestionsData,
    getAllDisorderData,
    getAllLots,
    updateQuestion,
    addQuestion,
    deleteQuestion,
    getAllSessions,
    getSessionById,
    resetSessionManually,
    rebuildReport
};
export default questionModule


async function getQuestions(requestData) {
    let url = process.env.REACT_APP_API_URI + "/api/private/submit-answers";
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getQuestionsTest(requestData) {
    let url = process.env.REACT_APP_API_URI + "/api/private/submit-answers-test";
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getAllSessions(limitValue, skipValue, req_id, consent = false) {
    let url = process.env.REACT_APP_API_URI + `/api/private/answer-sessions?limitValue=${limitValue}&skipValue=${skipValue}&userId=${req_id}&withconsent=${consent}`;
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getSessionById(sessionId) {
    let url = process.env.REACT_APP_API_URI + `/api/private/session-by-id?sessionId=${sessionId}`;
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function rebuildReport(sessionId) {
    let url = process.env.REACT_APP_API_URI + `/api/private/rebuild-report?sessionId=${sessionId}`;
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function resetSessionManually(userId) {
    let url = process.env.REACT_APP_API_URI + `/api/private/reset-session?userId=${userId}`;
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), {}, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getAllQuestionsData(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/questions?collectionType=${requestData?.collectionType ? requestData?.collectionType : ""}`;
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getAllLots(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/all-lots?collectionType=${requestData?.collectionType ? requestData?.collectionType : ""}`;
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getAllDisorderData(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/all-disorder?collectionType=${requestData?.collectionType ? requestData?.collectionType : ""}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function updateQuestion(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/updateQuestion/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}


async function addQuestion(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/questions`
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function deleteQuestion(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/delete-question/${requestData._id}`
    return httpService(httpConstants.METHOD_TYPE.DELETE, getHeaders(), requestData, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}