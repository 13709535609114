import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import Profile from "../MyProfile/Profile";
import { AuthUserService } from "../../services";
import { useAuth } from "../../AuthContext";
import MySubscription from "./Events/MySubscription";
import VideoDemo from "./Events/VideoDemo";
import MyReport from "./Events/MyReport";
import AllSessions from "./Events/AllSessions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const { userDetails, getUserDetails } = useAuth();
  const [totalMessageCount, setTotalMessageCount] = useState("");
  const [totalConsumedMessage] = useState("");
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [value, setValue] = useState(0);
  const [reload3Times, setReload3Times] = useState(0);
  const { tabName } = useParams();

  useEffect(() => {
    if (reload3Times < 4) {
      getUserDetails()
      setReload3Times(reload3Times + 1)
      getUserSubscriptions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const getUserSubscriptions = async () => {
    if (userDetails && userDetails._id) {
      const subscriptions = await AuthUserService.getUserSubscriptions(
        userDetails._id
      );
      setUserSubscriptions(subscriptions);
      const totalMsgCount = subscriptions.reduce((acc, plan) => {
        const messageCount =
          plan.messageCount && typeof plan.messageCount === "number"
            ? plan.messageCount
            : 0;
        return acc + messageCount;
      }, 0);
      setTotalMessageCount(totalMsgCount);
    }
  };

  const tabNameMapping = {
    profile: 0,
    subscriptions: 1,
    demo: 2,
    reports: 3,
  };

  useEffect(() => {
    setValue(tabNameMapping[tabName.toLocaleLowerCase()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const videoUrl =
    "https://aipsychi.org/wp-content/uploads/2024/02/Full_Movie-1.mp4";

  const [selectedSession, setSelectedSession] = useState(null);
  return (
    <Box
      id="profile-side-options"
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        style={{ minWidth: "20%" }}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Profile" {...a11yProps(0)} />
        <Tab label="My Subscription" {...a11yProps(1)} />
        <Tab label="Demo" {...a11yProps(2)} />
        <Tab label="Report" {...a11yProps(3)} />
      </Tabs>
      <TabPanel style={{width: "100%"}}  value={value} index={0}>
        <Profile />
      </TabPanel>
      <TabPanel style={{width: "100%"}} value={value} index={1}>
        <h5 style={{ fontWeight: "bold" }}>My Subscription</h5>
        {userSubscriptions.length > 0 && (
          <AllSessions
            totalMessageCount={totalMessageCount}
            totalConsumedMessage={totalConsumedMessage}
          />
        )}
        {userSubscriptions.map((subscription) => (
          <MySubscription subscription={subscription} />
        ))}
        {!userSubscriptions.length && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "60vh",
            }}
          >
            <img
              src="/images/diamond.png"
              alt="Logo"
              className="img-fluid"
              width={180}
              height={180}
            />
            <p style={{ fontSize: 18, color: "gray", marginTop: "20px" }}>
              <i>You Don't Have Any Subscription</i>
            </p>
          </div>
        )}
      </TabPanel>
      <TabPanel style={{width: "100%"}} value={value} index={2}>
        <h5 style={{ fontWeight: "bold" }}>Demo</h5>
        <VideoDemo style={{ width: "80%" }} videoUrl={videoUrl} />
      </TabPanel>
      <TabPanel style={{width: "100%"}} value={value} index={3}>
        <MyReport
          selectedSession={selectedSession}
          setSelectedSession={setSelectedSession}
        />
      </TabPanel>
    </Box>
  );
}
