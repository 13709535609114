import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import settingsModule from "../../../../services/settings";
import { showErrorMessage, showSuccessMessage } from "../../../../managers/utility";

export default function LLMAnswerForm() {
  const [questionsAnswers, setQuestionsAnswers] = useState([
    { question: "", answer: "" },
  ]);

  useEffect(() => {
    getLLMQuestionAnswer();
  }, []);

  const getLLMQuestionAnswer = async () => {
    const res = await settingsModule.getLLMQuestionAnswer();
    if (res && res.LLMAnswers && res.LLMAnswers.length) {
      setQuestionsAnswers(res.LLMAnswers);
    }
  };

  const handleQuestionChange = (index, e) => {
    const updatedQuestionsAnswers = [...questionsAnswers];
    updatedQuestionsAnswers[index].question = e.target.value.replace("$", "");
    setQuestionsAnswers(updatedQuestionsAnswers);
  };

  const handleAnswersChange = (event, index) => {
    let tempQA = [...questionsAnswers];
    const selectedIndex = tempQA[index];
    selectedIndex.answer = event.target.value;
    tempQA[index] = selectedIndex;
    setQuestionsAnswers(tempQA);
  };

  const addRemoveRow = (action, index) => {
    if (action === "ADD") {
      setQuestionsAnswers((prevState) => {
        const updatedQuestionsAnswers = [...prevState];
        updatedQuestionsAnswers.splice(index + 1, 0, { question: "", answers: [""] });
        return updatedQuestionsAnswers;
      });
    } else if (action === "REMOVE") {
      if (questionsAnswers.length > 1) {
        setQuestionsAnswers((prevState) => {
          const updatedQuestionsAnswers = [...prevState];
          updatedQuestionsAnswers.splice(index, 1);
          return updatedQuestionsAnswers;
        });
      }
    }
  };
  

  const handleSaveQuestion = async () => {
    const newQuestionData = {
      _id: {
        $oid: "6524aee1f523e3c3be4ba56f",
      },
      useLLM: false,
      LLMAnswers: questionsAnswers,
    };
    try {
      const response = await settingsModule.addLLMQuestionAnswer(newQuestionData);
      if (response._id) {
        showSuccessMessage("Saved")
        // Clear form fields or perform any other necessary actions
      } else {
        showErrorMessage("Failed to add the question");
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  return (
    <div>
    <div style={{width:"100%"}}>
      <Button sx={{ mt: 3 }} variant="contained" onClick={handleSaveQuestion}>
        Save <SaveIcon />
      </Button>
    </div>
      {questionsAnswers.map((qa, index) => (
        <div key={index} style={{ display: "flex", marginTop: "10px", width:"100%"}}>
          <TextField
            label="Question"
            variant="outlined"
            value={qa.question}
            style={{width:"15%"}}
            onChange={(e) => handleQuestionChange(index, e)}
          />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width:"70%"
              }}
            >
              <TextField
                sx={{ ml: 1}}
                label="Answer"
                variant="outlined"
                value={qa.answer}
                style={{width:"100%"}}
                onChange={(e) => handleAnswersChange(e, index)}
              />
         </div>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width:"12%"
            }}
          >
            <Button
              fullWidth
              size="small"
              variant="outlined"
              style={{margin:"2px"}}
              onClick={() => addRemoveRow("ADD", index)}
            >{`Add Row`}</Button>
            <Button
              fullWidth
              size="small"
              variant="outlined"
              onClick={() => addRemoveRow("REMOVE", index)}
            >
              Remove Row
            </Button>
          </div>
        </div>
      ))}

    </div>
  );
}
