import React, { useState } from "react";
import AppVersion from "./AppVersion/AppVersion";
import Ios from "./AppVersion/Ios";
import Backend from "./AppVersion/Backend";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Grid,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import AndroidIcon from "@mui/icons-material/Android";
import StorageIcon from "@mui/icons-material/Storage";
import AppleIcon from "@mui/icons-material/Apple";
import updateVersionService from "../../../services/updateVersionService";
import { showSuccessMessage } from "../../../managers/utility";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoader from "../../../common/customLoader";

export default function UpdateVersion() {
  const [activeTab, setActiveTab] = useState("ANDROID");
  const [name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [type, setType] = useState("");
  const [platform, setPlatform] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [dataTable, setDataTable] = useState(false);
  const [arrayName, setArrayName] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [releaseNote, setReleaseNote] = useState(""); // State to hold the update type

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getTable = async (activeTab) => {
    const response = await updateVersionService.getDataVersion(activeTab);
    if (response) {
      setArrayName(response);
      setDataTable(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let requestData = {
        name: name,
        version: version,
        type: type,
        platform: platform,
        releaseNote: releaseNote,
      };

      const response = await updateVersionService.createVersion(requestData);
      if (response && response.name) {
        showSuccessMessage("Version added Successfully");
        setName("");
        setVersion("");
        setReleaseNote("");
        setType("");
        setPlatform("");
      } else {
        // showErrorMessage("Unexpected error creating a user profile.");
      }
    } catch (error) {
      console.error("Error creating user profile:", error);
    }
    setOpenModal(false);
    getTable(activeTab);
  };

  const toggleForm = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setName("");
    setVersion("");
    setReleaseNote("");
    setType("");
    setPlatform("");
  };

  return (
    <>
     {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}
      <BottomNavigation
        sx={{ mt: 6, mb: 3, borderBottom: "1px solid grey", flex: "0 0 auto" }}
        showLabels
        value={activeTab}
        onChange={handleTabChange}
      >
        <BottomNavigationAction
          value="ANDROID"
          label="ANDROID"
          icon={<AndroidIcon />}
        />
        <BottomNavigationAction value="IOS" label="IOS" icon={<AppleIcon />} />
        <BottomNavigationAction
          value="BACKEND"
          label="BACKEND"
          icon={<StorageIcon />}
        />
      </BottomNavigation>
      <div>
        <Button
          sx={{ flex: 1,ml:1 }}
          variant="contained"
          onClick={toggleForm}
        >
          Add Version
        </Button>
      </div>
      <div style={{ overflowY: "auto", flex: 1 }}>
        {activeTab === "ANDROID" && (
          <AppVersion
            activeTab={activeTab}
            getTable={getTable}
            arrayName={arrayName}
            dataTable={dataTable}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {activeTab === "IOS" && (
          <Ios
            activeTab={activeTab}
            getTable={getTable}
            arrayName={arrayName}
            dataTable={dataTable}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
        {activeTab === "BACKEND" && (
          <Backend
            activeTab={activeTab}
            getTable={getTable}
            arrayName={arrayName}
            dataTable={dataTable}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        )}
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" gutterBottom>
              Add Version
            </Typography>
            <Button
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="update-name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="update-version"
                label="Version"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="update-type"
                label="Type"
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <MenuItem value="Forced">Forced</MenuItem>
                <MenuItem value="Normal">Normal</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="update-type"
                label="Platform"
                select
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <MenuItem value="ANDROID">ANDROID</MenuItem>
                <MenuItem value="IOS">IOS</MenuItem>
                <MenuItem value="BACKEND">BACKEND</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                id="outlined-basic-age"
                aria-label="minimum height"
                rowsMin={3}
                placeholder="Release Note"
                value={releaseNote}
                onChange={(e) => setReleaseNote(e.target.value)}
                fullWidth
                style={{
                  marginTop: 16,
                  width: "100%",
                  resize: "none",
                  padding: 10,
                  border: "1px solid #ccc",
                  borderRadius: 4,
                }}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            style={{ marginTop: 16 , marginBottom:16}}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
}
