import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import moment from "moment/moment";
import CustomDatePicker from "../../../../common/DatePicker";

import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../managers/utility";
import { CouponService } from "../../../../services";
import { Card, CardContent } from "@mui/material";
export default function CouponModal({
  open,
  closeCouponModal,
  Coupon,
  isEditable,
  handleAddCoupon,
  handleEditCoupon,
  getAllCoupons,
  createMode,
}) {
  const [data, setData] = useState({ ...Coupon });

  useEffect(() => {
    setData({ ...Coupon });
  }, [Coupon]);

  useEffect(() => {}, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnChangeDate = (value) => {
    setData((prevData) => ({
      ...prevData,
      expiryDate: value, // Assuming 'expiryDate' is the key to update in your state
    }));
  };

  const addCoupons = async () => {
    try {
      const response = await CouponService.addCoupons({ ...data });
      if (response && response._id) {
        getAllCoupons();
        showSuccessMessage("Coupon Added Successfully");
        closeCouponModal();
      } else {
        showErrorMessage("Unexpected error adding Coupon");
      }
    } catch (error) {
      throw new Error("Unexpected error. Please try again later.");
    }
    setData("");
  };

  const editCoupons = async () => {
    const response = await CouponService.updateCoupons({ ...data });
    if (response && response._id) {
      getAllCoupons();
      showSuccessMessage("Coupon Updated Successfully");
      closeCouponModal();
    } else {
      showErrorMessage("Unexpected error Updating Coupon");
    }
    setData("");
  };

  const formattedExpiryDate = data?.expiryDate
    ? data.expiryDate.slice(0, 10)
    : ""; // Extracting the date part from the ISO string

  const closeCouponModalData = () => {
    closeCouponModal();
    setData("");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeCouponModalData}
        aria-labelledby="modal-modal-title-Coupons"
        aria-describedby="modal-modal-description-Coupons"
      >
        <Box
          sx={{
            display: "flex",
            maxHeight: 500,
            position: "absolute",
            top: "50%",
            // width: "100%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            borderRadius: "8px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              width: "60%",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                borderBottom: "3px dotted cfcfcf",
              }}
            >
              <Typography
                id="modal-modal-title-lots"
                variant="h6"
                component="h2"
              >
                Coupons
              </Typography>
              <Button
                onClick={
                  isEditable
                    ? createMode
                      ? addCoupons
                      : editCoupons
                    : handleEditCoupon
                }
              >
                {isEditable ? "Save" : "Edit"}
              </Button>
              <Button
                edge="end"
                color="inherit"
                aria-label="close"
                onClick={closeCouponModalData}
              >
                <CloseIcon />
              </Button>
            </div>
            <TextField
              label="Name"
              variant="outlined"
              disabled={!isEditable}
              onChange={handleOnChange}
              name="name"
              fullWidth
              margin="normal"
              sx={{ mt: 2 }}
              value={data?.name}
            />
            <div style={{ display: "flex" }}>
              <TextField
                label="Coupon Code"
                variant="outlined"
                disabled={!isEditable}
                onChange={handleOnChange}
                name="couponCode"
                fullWidth
                margin="normal"
                sx={{ mt: 2 }}
                value={data?.couponCode}
              />
              <TextField
                label="Discount"
                variant="outlined"
                disabled={!isEditable}
                onChange={handleOnChange}
                name="discount"
                fullWidth
                margin="normal"
                sx={{ mt: 2, ml: 1 }}
                value={data?.discount}
              />
            </div>
            <div style={{ display: "flex" }}>
               <CustomDatePicker
                label="Expiry Date"
                disabled={!isEditable}
                type="date"
                name="expiryDate"
                fullWidth
                value={formattedExpiryDate ? formattedExpiryDate : ""}
                onChange={(date) => {
                  handleOnChangeDate(date);
                }}
                style={{ marginTop: "14px" }}
             />
            </div>
          </Box>
          <div style={{ margin: 10, width: "40%" }}>
            <Card
              sx={{
                width: "300px",
                borderRadius: 2,
                minHeight: "100%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0.1, 0.3)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography gutterBottom variant="h9" component="div">
                  <b> Name: </b>
                  {data.name}
                </Typography>
                <Typography
                  color="green"
                  component="div"
                  style={{ marginTop: 20 }}
                >
                  <b>Coupon Code:</b> {data.couponCode}
                </Typography>
                <Typography
                  color="text.secondary"
                  component="div"
                  style={{ marginTop: 20 }}
                >
                  <b>Discount:</b> {data.discount}%
                </Typography>
                <div style={{ marginTop: 20, marginBottom: 10 }}>
                  <Typography
                    style={{ fontSize: 16 }}
                    color="text.secondary"
                    component="div"
                  >
                    <b>Expiry Date: </b>
                    {data?.expiryDate
                      ? moment(data?.expiryDate).format("Do MMMM YYYY")
                      : ""}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
        </Box>
      </Modal>
    </>
  );
}
