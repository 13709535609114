import React, { useEffect, useState } from "react";
import { Modal, TextField, Button, IconButton, Typography } from "@mui/material";
import { EntityService } from "../../../../services";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../../../managers/utility";
import CloseIcon from "@mui/icons-material/Close";

const CouponModal = ({ rowData, isOpen, onClose, getCorporateData }) => {
  const [personCount, setPersonCount] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountError, setDiscountError] = useState(null); // State for discount error handling
  const [personCountError, setPersonCountError] = useState(null); // State for person count error handling

  const handleSaveCoupon = async () => {
    try {
      // Validate coupon name, discount, and INR inputs
      if (!isValidNumber(discount)) {
        setDiscountError("Please enter a valid number for discount.");
        throw new Error("Invalid discount value.");
      } else {
        setDiscountError(null);
      }

      if (!isValidNumber(personCount)) {
        setPersonCountError("Please enter a valid number for person count.");
        throw new Error("Invalid person count value.");
      } else {
        setPersonCountError(null);
      }

      const payload = {
        name: rowData?.name,
        entityId: rowData?._id,
        discount: Number(discount), // Convert to number if needed
        personCount: Number(personCount), // Convert to number if needed
      };
      const res = await EntityService.addCouponForEntity(payload);
      if (res) {
        showSuccessMessage("Updates successfully");
        getCorporateData();
        onClose();
      }
    } catch (error) {
      showErrorMessage(error.message); // Show error message to the user
    }
  };

  const isValidNumber = (value) => {
    // Regular expression to check if the value is a valid number (including decimals)
    return /^[0-9]+(\.[0-9]+)?$/.test(value);
  };

  useEffect(() => {
    setPersonCount("");
    setDiscount("");
    setPersonCountError(null); // Reset person count error state
    setDiscountError(null); // Reset discount error state
  }, [rowData, isOpen]); // Reset error state when rowData or isOpen changes

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#fff",
          padding: 20,
          width: 400,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "self-end",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Add Coupon
          </Typography>
          <div>
            {" "}
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
        <TextField
          label="Discount"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          fullWidth
          margin="normal"
          type="text" // Set input type to text
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
          error={!isValidNumber(discount)} // Set error state based on validation
        />
        {discountError && <p style={{ color: "red" }}>{discountError}</p>}{" "}
        {/* Display discount error message */}
        <TextField
          label="Person Count"
          value={personCount}
          onChange={(e) => setPersonCount(e.target.value)}
          fullWidth
          margin="normal"
          type="text" // Set input type to text
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Allow only numeric input
          error={!isValidNumber(personCount)} // Set error state based on validation
        />
        {personCountError && <p style={{ color: "red" }}>{personCountError}</p>}{" "}
        {/* Display person count error message */}
        <Button
          style={{ marginTop: 14 }}
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSaveCoupon}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default CouponModal;
