import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { PlanService } from '../../../services';
import { showErrorMessage } from '../../../managers/utility';
import { useAuth } from '../../../AuthContext';
import PromoCode from './PlansData/PromoCode';

const Pricing = ({ open, handleClose }) => {
  const { userDetails, setViewLoginModal } = useAuth();
  const [plansData, setPlansData] = useState([]);
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [couponData, setCouponData] = useState(false);

  const getAllPlans = async () => {
    try {
      const allPlans = await PlanService.getAllPlans({ isDeleted: false });
      setPlansData(allPlans);
    } catch (error) {
      console.error('Error fetching plans:');
    }
  };

  const handleButtonClick = plan => {
    if (userDetails && userDetails._id) {
      handleClose();
      setOpenCheckOut(true);
      setCouponData(plan); // Store the selected plan in couponData
    } else {
      showErrorMessage('Please Login First');
      setViewLoginModal(true);
      handleClose();
    }
  };

  useEffect(() => {
    getAllPlans();
  }, [userDetails]);

  return (
    <>
      {open === true && (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '900px',
              backgroundColor: 'white',
              padding: '50px',
              borderRadius: '8px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h4" gutterBottom>
                  Pricing Information
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Choose the plan that best fits your needs:
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '20px',
                width: '100%',
              }}
            >
              {plansData.map((item, index) => (
                <div key={index} style={{ margin: 10, width: '100%' }}>
                  <Card
                    sx={{
                      width: '100%',
                      borderRadius: 2,
                      minHeight: '100%',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0.1, 0.3)',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ backgroundColor: item.color, height: '8px' }}></div>
                    <CardContent sx={{ flex: 1 }}>
                      <Typography gutterBottom variant="h9" component="div" sx={{ fontWeight: 'bold' }}>
                        {item.plansType}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.duration} Month
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                        ₹{item.price} Plan
                      </Typography>
                      <Typography style={{ fontSize: 12 }} color="text.secondary">
                        {item.plansTitle}
                      </Typography>
                      {item.plansDetails.map((listItem, i) => (
                        <div style={{ display: 'flex' }}>
                          <div>•</div>
                          <div
                            style={{
                              textAlign: 'left',
                              paddingLeft: '10px',
                            }}
                          >
                            {listItem}
                          </div>
                        </div>
                      ))}
                      <Typography align="center" sx={{ marginTop: 1, fontSize: 12 }}>
                        *Conversation words limit is 50 words / conversation
                      </Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                      <Button
                        onClick={() => handleButtonClick(item)}
                        className="btn btn-outline-primary text-primary text-uppercase"
                        type="button"
                        id="switchButton"
                        size="medium"
                        sx={{
                          fontWeight: '700',
                          fontSize: 12,
                          width: '100%',
                          backgroundColor: 'white',
                          color: '#157feb',
                          border: '1px solid blue',
                          borderRadius: 0.8,
                          '&:hover': {
                            backgroundColor: 'white',
                            color: 'white',
                            boxShadow: '2px 4px 4px rgba(0, 0, 0.1, 0.3)',
                          },
                        }}
                      >
                        BUY NOW
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              ))}
            </div>

            <Typography variant="h6" fontWeight={600} align="center" sx={{ marginTop: 1 }}>
              Please contact us at care@aipsychi.org for a custom quote.
            </Typography>
          </div>
        </Modal>
      )}
      {openCheckOut === true && (
        <PromoCode
          plansData={plansData}
          setPlansData={setPlansData}
          couponData={couponData}
          setCouponData={setCouponData}
          setOpenCheckOut={setOpenCheckOut}
          openCheckOut={openCheckOut}
        />
      )}
    </>
  );
};
export default Pricing;
