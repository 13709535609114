import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";

const mediaModule = {
  getAllMediaCategories,
  getAllMedia,
  addMedia,
  updateMedia,
  deleteMedia,
  addMediaCategory,
  updateMediaCategory,
  deleteMediaCategory,
  getOneMediaCategoryByIdAPI,
  UpdateAllMediaFromCategory
};

export default mediaModule;

// Media

async function getAllMedia(requestData) {
  let url = process.env.REACT_APP_API_URI + "/api/private/get-media";
  return httpService(
    httpConstants.METHOD_TYPE.GET,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function addMedia(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/create-media`;
  return httpService(
    httpConstants.METHOD_TYPE.POST,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function updateMedia(requestData) {
  let url =
    process.env.REACT_APP_API_URI +
    `/api/private/update-media/${requestData._id}`;
  return httpService(
    httpConstants.METHOD_TYPE.PUT,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function deleteMedia(requestData) {
  let url =
    process.env.REACT_APP_API_URI +
    `/api/private/delete-media/${requestData._id}`;
  return httpService(
    httpConstants.METHOD_TYPE.DELETE,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

// Media Categories

async function getAllMediaCategories(requestData) {
  let url = process.env.REACT_APP_API_URI + "/api/private/get-media-category";
  return httpService(
    httpConstants.METHOD_TYPE.GET,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function addMediaCategory(requestData) {
  let url =
    process.env.REACT_APP_API_URI + `/api/private/create-media-category/`;
  return httpService(
    httpConstants.METHOD_TYPE.POST,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function updateMediaCategory(requestData) {
  let url =
    process.env.REACT_APP_API_URI +
    `/api/private/update-media-category/${requestData._id}`;
  return httpService(
    httpConstants.METHOD_TYPE.PUT,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function deleteMediaCategory(requestData) {
  let url =
    process.env.REACT_APP_API_URI +
    `/api/private/delete-media-category/${requestData._id}`;
  return httpService(
    httpConstants.METHOD_TYPE.DELETE,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
async function getOneMediaCategoryByIdAPI(id) {
  let url =
    process.env.REACT_APP_API_URI +
    `/api/private/get-one-category-by-Id/${id}`;
  return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(), {}, url)
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function UpdateAllMediaFromCategory(id , videos) {
  let url =
    process.env.REACT_APP_API_URI +
    `/api/private/update-media-by-category/${id}`;
  return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(), videos, url)
    .then((response) => {
      if (
        !response.success ||
        response.responseCode !== 200 ||
        !response.responseData
      )
        return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
