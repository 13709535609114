import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  TextField,
} from "@mui/material";
import MediaModal from "./Modals/MediaModal";
import { MediaService } from "../../../../services";
import { showSuccessMessage } from "../../../../managers/utility";

const ThumbnailTableView = ({ dataList, refetch, setVideoList, setIsLoading }) => {
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [videos, setVideos] = useState([]);
  const [startBulkOrderUpdate, setStartBulkOrderUpdate] = useState(false);

  const startBulkUpdate = async () => {
    setIsLoading(true)
    const result = await MediaService.UpdateAllMediaFromCategory(dataList.category._id, videos);
    if (result && result.updated) {
      showSuccessMessage("Updated Order")
      refetch(dataList.category)
    }
    setStartBulkOrderUpdate(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (dataList && dataList.videos && dataList.videos.length)
      setVideos(dataList.videos)
  }, [dataList])

  const openMediaModal = (item) => {
    setSelectedItem(item);
    setIsMediaModalOpen(true);
  };

  const goToBack = () => {
    setVideoList(false);
  };

  const startOrderChange = (itemId, changeType) => {
    setStartBulkOrderUpdate(true)
    let selectedVideo = videos.find((item) => item._id === itemId);
    if (selectedVideo) {
      let currentVideos = [...videos];
      let indexOfVideo = currentVideos.indexOf(selectedVideo);
      selectedVideo.order = changeType === "INCREMENT" ? selectedVideo.order + 1 : selectedVideo.order > 0 ? selectedVideo.order - 1 : selectedVideo.order;
      currentVideos.splice(indexOfVideo, 1, selectedVideo);
      setVideos(currentVideos)
    }
  }

  const setItemValueManually = (itemId, newValue) => {
    let updatedVideos = videos.map(item => {
      if (item._id === itemId) {
        return { ...item, order: newValue };
      }
      return item;
    });
    setVideos(updatedVideos);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}>
        <Button variant="contained" onClick={goToBack}>
          Back
        </Button>
        {startBulkOrderUpdate ?
          <Button variant="contained" onClick={() => startBulkUpdate()} sx={{ marginRight: 3 }}>
            Update Order
          </Button> :
          <Button variant="contained" onClick={() => setIsMediaModalOpen(true)} sx={{ marginRight: 3 }}>
            Add
          </Button>}
      </div>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Thumbnail</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Category</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos.map((item) => (
              <TableRow sx={{ ":hover": { backgroundColor: "#e1e1e1" } }} key={item._id}>
                <TableCell>
                  <Button size="small" onClick={() => startOrderChange(item._id, "DECREMENT")}>{'<'}</Button>
                  <TextField sx={{width:"80px"}} value={item.order} onChange={(event) => setItemValueManually(item._id, event.target.value)} />
                  <Button size="small" onClick={() => startOrderChange(item._id, "INCREMENT")}>{'>'}</Button>
                </TableCell>
                <TableCell sx={{ cursor: "pointer" }} onClick={() => openMediaModal(item)}>
                  <Avatar style={{ width: "90px", height: "50px" }} variant="square" alt={item.title} src={dataList.category.thumbnail} />
                </TableCell>
                <TableCell sx={{ cursor: "pointer" }} onClick={() => openMediaModal(item)}>{item.title}</TableCell>
                <TableCell sx={{ cursor: "pointer" }} onClick={() => openMediaModal(item)}>{dataList.category.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MediaModal
        isMediaModalOpen={isMediaModalOpen}
        selectedItem={selectedItem}
        refetch={refetch}
        setIsMediaModalOpen={setIsMediaModalOpen}
        dataList={dataList}
      />
    </>
  );
};

export default ThumbnailTableView;