import { Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { QuestionsService } from '../../../services'
import CustomLoader from '../../../common/customLoader'
import moment from 'moment'
import PaidSessionReport from '../../Session/PaidSessionReport/PaidSessionReport'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ReportListModal({ open, close, selectedUser }) {

    const [sessions, setSessions] = useState([]);
    const [showReport, setShowReport] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getUserSessions()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser])


    const getUserSessions = async () => {
        if (selectedUser && selectedUser._id) {
            const sessions = await QuestionsService.getAllSessions(10, 0, selectedUser._id, true);
            setSessions(sessions.data)
        }
        setIsLoading(false)
    }


    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    borderRadius: "8px",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "85%",
                    backgroundColor: "white",
                    padding: "50px",
                    height: "80%",
                    overflow: "scroll"
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", width: '100%', marginBottom: "20px" }}>

                    {showReport ? <Button onClick={() => {
                        setShowReport(false);
                    }
                    }>
                        <ArrowBackIcon /> &nbsp; Back
                    </Button> : <div></div>}
                    <Button onClick={() => {
                        setShowReport(false);
                        close()
                    }
                    }>
                        X &nbsp;
                    </Button>
                </div>
                {showReport ?
                    <>
                        <PaidSessionReport selectedSession={selectedSession} showReport={showReport} />
                    </> :
                    <>
                        {sessions.length ?
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>-</TableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {sessions.map((eachSession) => (
                                            <TableRow>
                                                <TableCell>{eachSession?.userId?.name}</TableCell>
                                                <TableCell>{moment(eachSession?.updatedAt).format("DD-MM-YYYY HH:MM")}</TableCell>
                                                <TableCell><Button onClick={() => {
                                                    setShowReport(true);
                                                    setSelectedSession(eachSession)
                                                }} variant='contained'>View</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer >
                            :

                            <>
                                {
                                    isLoading
                                        ?
                                        <CustomLoader />
                                        :
                                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>No Session Found</div>
                                }
                            </>
                        }
                    </>}
            </div>

        </Modal>
    )
}
