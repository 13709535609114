import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { SettingsService } from "../../../../services";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../managers/utility";

export default function AddCategoryModal({
  openAddCategoryModal,
  handleCloseAddCategoryModal,
  getDisorderData,
}) {
  const [disorderName, setDisorderName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setDisorderName("");
  }, []);

  const handleSaveCategory = async () => {
    if (!disorderName) {
      setError("Please fill in the field");
      return;
    }

    try {
      const res = await SettingsService.addDisorderInfoCateroryAPI({
        name: disorderName,
      });

      if (res && res._id) {
        getDisorderData();
        setDisorderName("");
        showSuccessMessage("Disorder Category Added Successfully.");
        handleCloseAddCategoryModal();
      } else {
        showErrorMessage("An error occurred while deleting category");
      }
    } catch (error) {
      showErrorMessage("An error occurred while adding category");
    }
  };

  const handleInputChange = (e) => {
    setDisorderName(e.target.value);
    setError(""); // Clear error message when input changes
  };

  return (
    <Modal open={openAddCategoryModal} onClose={() => {
      handleCloseAddCategoryModal();
      setDisorderName("");
    }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          borderRadius: "8px",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxWidth: "700px",
          backgroundColor: "white",
          padding: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography variant="h5">Add Category</Typography>
          <IconButton onClick={()=>{
            handleCloseAddCategoryModal();
            setDisorderName();
          }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <textarea
          rows={4} // Adjust the number of rows as needed
          placeholder="Enter Category name"
          value={disorderName}
          onChange={handleInputChange}
          style={{
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 4,
            padding: 8,
            // marginBottom: 8
          }}
        />
        {error && (
          <Typography variant="body5" color="error" style={{ marginBottom: "20px" }}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSaveCategory}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
