import React, { useState, useEffect } from "react";
import ThumbnailTableView from "./TableView";
import CustomLoader from "../../../../common/customLoader";
import { Typography } from "@mui/material";

const MediaMain = ({ dataList, refetch, setVideoList }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isData, setIsData] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      if (dataList && dataList.videos && dataList.videos.length > 0) {
        setIsData(true);
      } else {
        setIsData(false);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [dataList]);

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      ) : isData ? (
        <ThumbnailTableView
          dataList={dataList}
          setVideoList={setVideoList}
          refetch={refetch}
          setIsLoading={setIsLoading}
        />
      ) : (
        <div style={{ textAlign: "center" }}>
          <Typography>No data found</Typography>
        </div>
      )}
    </>
  );
};

export default MediaMain;
