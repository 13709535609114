import React, { useState } from 'react';
import { Modal, TextField, Button, Paper } from '@mui/material';
import { useAuth } from '../../AuthContext';
import { UserProfileService } from '../../services';
import { showErrorMessage, showSuccessMessage } from '../../managers/utility';

export default function ChangePasswordModal() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const { userDetails } = useAuth()

    const { resetPasswordModal, setResetPasswordModal } = useAuth()

    const handleClose = () => {
        setResetPasswordModal(false);
    };

    const handleChangePassword = async () => {
        // Implement your logic to change password here
        const resetPassword = await UserProfileService.changePassword({
            role: userDetails.role,
            userId: userDetails._id,
            oldPassword: currentPassword,
            newPassword: newPassword
        })
        if (resetPassword && resetPassword.message && resetPassword.message === "Password updated successfully") {
            showSuccessMessage(resetPassword.message)
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
            handleClose();
        } else {
            showErrorMessage(resetPassword.message)
        }
    };


    const body = (
        <div style={{ display: "flex", width: "100%", marginTop: "10%", alignItems: "center", justifyContent: "center" }}>
            <Paper sx={{ padding: "30px" }}>
                <h2 id="simple-modal-title">Change Password</h2>
                <TextField
                    label="Current Password"
                    type="password"
                    fullWidth
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <TextField
                    sx={{ marginTop: 2 }}
                    label="New Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                    sx={{ marginTop: 2 }}

                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
                <Button
                    sx={{ marginTop: 2 }}

                    variant="contained" color="primary" onClick={handleChangePassword}>
                    Change Password
                </Button>
            </Paper>
        </div>
    );

    return (
        <div>
            <Modal
                open={resetPasswordModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
