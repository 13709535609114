import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";


const lot =  {
  addLot,
  updateLot,
  deleteLot,
};

export default lot;

async function addLot(requestData) {
  let url = process.env.REACT_APP_API_URI + `/api/private/add-lot`;
  return httpService(
    httpConstants.METHOD_TYPE.POST,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
       if (!response.success || response.responseCode !== 200 || !response.responseData) return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function deleteLot(requestData) {
  let url =
    process.env.REACT_APP_API_URI + `/api/private/delete-lot/${requestData._id}`;
  return httpService(
    httpConstants.METHOD_TYPE.DELETE,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
       if (!response.success || response.responseCode !== 200 || !response.responseData) return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}

async function updateLot(requestData) {
  let url =
    process.env.REACT_APP_API_URI + `/api/private/update-lot/${requestData._id}`;
  return httpService(
    httpConstants.METHOD_TYPE.PUT,
    getHeaders(),
    requestData,
    url
  )
    .then((response) => {
       if (!response.success || response.responseCode !== 200 || !response.responseData) return Promise.reject(response);
      return Promise.resolve(response.responseData);
    })
    .catch(function (err) {
      return Promise.reject(err);
    });
}
