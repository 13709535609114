import React, { useState } from 'react';
import { Paper, TextField, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AuthUserService } from '../../services';
import { showErrorMessage, showSuccessMessage } from '../../managers/utility';
import { useAuth } from '../../AuthContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  paper: {
    width: 500,
    textAlign: 'center',
  },
}));

const Login = ({ setIsAdminLoggedIn }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { setUserDetails } = useAuth();

  const handleLogin = async () => {
    const response = await AuthUserService.signInAdmin({
      email: email,
      password: password,
    });
    if (response.userDetails && response.userDetails._id && response.token) {
      localStorage.setItem('token', response.token);
      setIsAdminLoggedIn(true);
      showSuccessMessage(response.message);
      localStorage.setItem('userDetails', JSON.stringify({ ...response.userDetails, type: 'SUPER_ADMIN' }));
      localStorage.setItem('isAdminLoggedIn', JSON.stringify(response.userDetails));
      setUserDetails(response.userDetails);
    } else {
      showErrorMessage(response.message);
    }
  };

  return (
    <div className={classes.root}>
      <Paper sx={{ padding: 10 }} className={classes.paper} elevation={3}>
        <Typography variant="h5" gutterBottom>
          Login
        </Typography>
        <TextField
          sx={{ mb: 5 }}
          label="Email"
          type="email"
          fullWidth
          className={classes.inputField}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          sx={{ mb: 5 }}
          label="Password"
          type="password"
          fullWidth
          className={classes.inputField}
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleLogin();
            }
          }}
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </Paper>
    </div>
  );
};

export default Login;
