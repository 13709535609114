import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { showSuccessMessage } from "../../../../managers/utility";
import updateVersionService from "../../../../services/updateVersionService";
import CustomLoader from "../../../../common/customLoader";
import moment from "moment/moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import UpdateVersionModal from "./UpdateVersionModal";

export default function AppVersion({
  arrayName,
  dataTable,
  getTable,
  setIsLoading,
  isLoading,
}) {
  const [setReleaseNote] = useState(""); // State to hold the update type
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to hold the selected item for update
  const [updateName, setUpdateName] = useState(""); // State to hold the update name
  const [updateVersion, setUpdateVersion] = useState(""); // State to hold the update version
  const [updateType, setUpdateType] = useState(""); // State to hold the update type

  useEffect(() => {
    getTable("ANDROID");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setUpdateName(item.name);
    setUpdateVersion(item.version);
    setUpdateType(item.type);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateVersion = async () => {
    setIsLoading(true);
    try {
      const requestData = {
        _id: selectedItem._id,
        name: updateName,
        version: updateVersion,
        type: updateType,
      };

      const response = await updateVersionService.UpdateVersion(requestData);
      if (response && response.name) {
        showSuccessMessage("Version updated successfully");
        handleCloseModal();
        setUpdateName("");
        setUpdateType("");
        setUpdateVersion("");
        setReleaseNote("");
      } else {
        // showErrorMessage("Unexpected error updating version.");
      }
    } catch (error) {
      console.error("Error updating version:", error);
      // showErrorMessage(error.message);
    }
    setIsLoading(false);
    setOpenModal(false);
    getTable("ANDROID");
  };

  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}

      <div onClick={handleUpdateVersion}></div>

      {dataTable && (
        <div>
          {arrayName.map((item) => (
            <Accordion
              key={item._id}
              style={{
                margin: "20px 10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginRight: 10

                  }}
                >
                  <div style={{ width: "18%" }}>
                    <Typography>{item.name}</Typography>
                  </div>
                  <div>
                    <strong>Version :</strong> {item.version}
                  </div>
                  <div>
                    <strong>Type :</strong> {item.type}
                  </div>
                  <div>
                    <strong>Release Date & Time : </strong>
                    {item?.createdAt
                      ? moment(item.createdAt).format("DD MMM YYYY")
                      : "NA"}
                  </div>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                <div>
                  <strong>Release Note :</strong> {item.releaseNote}
                </div>
                <div style={{ textAlign: 'end' }}>
                  <Button
                    sx={{ mt: 2, flex: 1 }}
                    variant="contained"
                    onClick={() => handleOpenModal(item)}
                  >
                    Update Version
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}

      {/* Modal for updating version */}
      <UpdateVersionModal
        open={openModal}
        onClose={handleCloseModal}
        title="Update Android Version"
        selectedItem={selectedItem}
        getTable={() => getTable("ANDROID")}
      />
    </>
  );
}
