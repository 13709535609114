import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import dayjs from 'dayjs';

export default function CustomDatePicker({ label, value, onChange }) {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}>
            <DatePicker
                sx={{ width: "100%" }}
                value={dayjs(value)}
                fullWidth
                label={label}
                onChange={(date) => {
                    onChange(dayjs(date).format("YYYY-MM-DD"))
                }}
                renderInput={(props) => <TextField {...props} fullWidth />}
            />
        </LocalizationProvider>
    );
}
