import React from 'react'
import './customLoader.css'

export default function CustomLoader() {
    return (
        <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop:"15%" }}>
            <section class="dots-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </section>
        </div>
    )
}
