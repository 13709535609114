import React from "react";
import Modal from "@mui/material/Modal";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoDemo from "./VideoDemo";
const DemoModal = ({ openDemo, handleCloseDemo, videoUrl }) => {
  return (
    <Modal
      open={openDemo}
      onClose={handleCloseDemo}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          maxWidth: "900px",
          backgroundColor: "white",
          padding: "50px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Typography variant="h4" gutterBottom>
              Demo
            </Typography>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <IconButton onClick={handleCloseDemo}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <VideoDemo videoUrl={videoUrl} />
      </div>
    </Modal>
  );
};

export default DemoModal;
