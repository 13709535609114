import React from "react";
import Modal from "@mui/material/Modal";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QuillViewer from "./viewer";
export default function ModalDisorder({
  openDescription,
  handleCloseDemo,
  data,
  title,
}) {
  return (
    <div>
      <Modal
        open={openDescription}
        onClose={handleCloseDemo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            borderRadius : "8px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            maxWidth: "900px",
            backgroundColor: "white",
            padding: "50px",
            height: "80%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography variant="h5" gutterBottom>
                {title}
              </Typography>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <IconButton onClick={handleCloseDemo}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div style={{height:'100%',paddingBottom:"20px", paddingTop:"20px"}}>
            <QuillViewer content={data} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
