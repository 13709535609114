import React, { useEffect, useState, useRef } from "react";
import Typewriter from "typewriter-effect";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { QuestionsService } from "../../services";
import { useTimer } from "react-timer-hook";
import { showErrorMessage, showSuccessMessage } from "../../managers/utility";
import "../../App.css";
import { useAuth } from "../../AuthContext";
import "../../App.css";
import { Button, Typography } from "@mui/material";
import PauseModal from "./PauseModal";
import SessionReportTable from "./FreeSessionReport.js/SessionReportTable";
import { AudioPlayer } from "react-audio-play";
import PaidSessionReport from "../Session/PaidSessionReport/PaidSessionReport";
import { sessionTypes } from "../../common/constants";

export default function Session({
  useLLM,
  inputMode,
  recievedSettings,
  sessionType,
}) {
  const { userDetails } = useAuth();
  const [question, setQuestion] = useState("");
  const [instructions, setInstructions] = useState("");
  const [apiData, setAPIData] = useState({});
  const [currentLot, setCurrentLot] = useState(0);
  const [answers, setAnswers] = useState(null);
  const [submitInProcess, setSubmitInProcess] = useState(false);
  const [listenerState, setListenerState] = useState("NOT_STARTED");
  const [controlsVisible, setControlsVisible] = useState(true);
  const [final, setFinal] = useState([]);
  const [sessionPaused, setSessionPaused] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [noFinalData, setNofianlData] = useState(false);
  //Timer Duration
  // eslint-disable-next-line no-unused-vars
  const [timerDuration, setTimerDuration] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [audioElement, setAudioElement] = useState("");
  // eslint-disable-next-line no-unused-vars

  const [patientAnswer, setPatientAnswer] = useState("");
  const [patientAnswerBox, setPatientAnswerBox] = useState(false);
  const inputRef = useRef(null);
  const [progressBar, setProgressBar] = useState(0);


  useEffect(() => {
    let audioElement = document.getElementsByTagName("audio")[0];
    setAudioElement(audioElement);
  }, []);


  const {
    seconds,
    restart,
    isRunning: isTimerRunning,
  } = MyTimer({ expiryTimestamp: Date.now() + 0 * 1000 });

  useEffect(() => {
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDetails._id && question === "" && recievedSettings) getQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, recievedSettings]);

  useEffect(() => {
    setProgressBar((prev) => {
      let newPercentage = (100 / 11) * (parseInt(currentLot) + 1);
      if (prev.countPerLot > 4) newPercentage += 100 / 22;
      return {
        prevLot: currentLot,
        percentage: newPercentage,
        countPerLot: prev.currentLot === currentLot ? prev.countPerLot + 1 : 0,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLot]);

  useEffect(() => {
    if (!isTimerRunning && listenerState === "STARTED") {
      setListenerState("ENDED");
      SpeechRecognition.stopListening();
      submitQuestion();
      resetTranscript();
      if (!sessionPaused) {
        setQuestion("");
        setPatientAnswer("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerRunning]);

  const getUserDetails = async () => {
    // await EntityService.getCorporateEmployeeDetails(userDetails._id, userDetails.type);
  };

  const readInstructions = async (question) => {
    //Reading Instruction is removed from requirement
    let restartDuration = 0;
    restart(Date.now() + restartDuration);
    setTimeout(() => {
      setQuestion(question.text);
      nowReadQuestion();
    }, restartDuration + 1000);
  };

  const nowReadQuestion = async () => {
    let currentQuestionDuration = 1;
    setAudioElement((prev) => {
      const player = document.getElementsByTagName("audio")[0];
      player.src = prev;

      player.addEventListener("loadedmetadata", () => {
        // Access the duration property
        const duration = player.duration;
        currentQuestionDuration = duration;
        currentQuestionDuration = currentQuestionDuration * 1000;
        setTimerDuration((prevTimer) => {
          let gapAfterQuestionSpeaks = 0;
          // Gap after question
          let restartDuration =
            Date.now() +
            gapAfterQuestionSpeaks +
            currentQuestionDuration +
            prevTimer * 1000;

          setTimeout(() => {
            setSessionPaused((prev) => {
              if (!prev) {
                restart(restartDuration);
                setControlsVisible(true);
                startListening();
              }
              return prev;
            });
          }, gapAfterQuestionSpeaks + currentQuestionDuration);
          return prevTimer;
        });
        // player.play();
      });
    });
  };

  useEffect(() => {
    enableFieldAndFocus();
  }, [patientAnswerBox]);

  const startListening = () => {
    setControlsVisible(true);
    setListenerState("STARTED");
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  const {
    transcript,
    browserSupportsSpeechRecognition,
    listening,
    resetTranscript,
  } = useSpeechRecognition();

  useEffect(() => {
    setPatientAnswer(transcript);
  }, [transcript]);

  const getQuestions = async () => {
    let sId = localStorage.getItem("sessionId");
    const data = await QuestionsService.getQuestions({
      sessionId: sId,
      refetch: true,
      userId: userDetails._id,
      userType: userDetails.role || "USER",
      sessionType: sessionType,
    });

    if (data.question) {
      try {
        setAudioElement(
          `https://demoplayground4093662547.z20.web.core.windows.net/us-en/Code${data.question.code}.mp3`
        );
      } catch (e) {
        showErrorMessage(e);
      }
      setTimerDuration(
        data.question.timer === "HIGH"
          ? 35
          : data.question.timer === "MEDIUM"
            ? 25
            : data.question.timer === "LOW"
              ? 12
              : 5
      );
      setInstructions(data.question.instructions);
      readInstructions(data.question);
      setAPIData(data);
    } else {
      if (data.message) showErrorMessage(data.message);
    }
    if (data.sessionId) {
      localStorage.setItem("sessionId", data.sessionId);
    }
  };

  const submitQuestion = async (skip) => {
    if (sessionPaused) return;
    setSubmitInProcess(true);
    resetTranscript();
    let sId = localStorage.getItem("sessionId");
    if (!sId) {
    }

    let translatedText;
    const reqData = {
      currentQuestionCode: apiData.question?.code,
      textResponse: translatedText ? translatedText : patientAnswer,
      sessionId: sId,
      answers: answers,
      lot: currentLot,
      useLLM: useLLM,
      userId: userDetails._id,
      sessionType: sessionType,
    };
    setPatientAnswerBox(false);
    const data = await QuestionsService.getQuestions(reqData);
    if (data.question) {
      resetTranscript();
      setControlsVisible(true);
      setPatientAnswer("");
      try {
        setAudioElement(
          `https://demoplayground4093662547.z20.web.core.windows.net/us-en/Code${data.question.code}.mp3`
        );
      } catch (e) {
        showErrorMessage(e);
      }
      setTimerDuration(
        data.question.timer === "HIGH"
          ? 35
          : data.question.timer === "MEDIUM"
            ? 25
            : data.question.timer === "LOW"
              ? 12
              : 5
      );
      setInstructions(data.question.instructions);
      setAPIData(data);
      setAnswers(data.answers);
      setSubmitInProcess(false);
      setCurrentLot(data.lot);
      readInstructions(data.question);
    } else {
      if (data.final) {
        setFinal(data.final);
        setSubmitInProcess(false);
        setNofianlData(false);
      } else {
        setNofianlData(true);
      }
      if (data.message) showErrorMessage(data.message);
    }

    if (data.totalCount && data.totalCountRemaining) {
      const progressBarValue = ((data.totalCount - data.totalCountRemaining) / data.totalCount) * 100;
      setProgressBar(progressBarValue);
    }
    if (data.message !== "All Lots are completed") {
      submitQuestion();
    }

    if (data.message === "All Lots are completed") {
      restart(Date.now());
      setCompleted(true);
      setInstructions("");
      showSuccessMessage("Thank you");
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  function createBoldText(instructions) {
    return <div dangerouslySetInnerHTML={{ __html: instructions }} />;
  }

  const enableFieldAndFocus = () => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 500);
  };

  const editButtonHandler = () => {
    setPatientAnswerBox(!patientAnswerBox);
    restart(Date.now + 1);
    SpeechRecognition.stopListening();
    setListenerState("ENDED");
  };

  const skipButtonHandler = () => {
    if (apiData.question.code === "201") {
      showErrorMessage("Answer Is Required Please Type");
      SpeechRecognition.stopListening();
      setListenerState("ENDED");
      restart(Date.now + 1);
      setPatientAnswerBox(true);
      setSubmitInProcess(false);
      return;
    }
    SpeechRecognition.stopListening();
    setListenerState("ENDED");
    setQuestion("");
    resetTranscript();
    setPatientAnswer("");
    submitQuestion(true);
    restart(Date.now + 1);
  };
  const submitQuestionForcefully = () => {
    SpeechRecognition.stopListening();
    setListenerState("ENDED");
    submitQuestion();
    setQuestion("");
    resetTranscript();
    setPatientAnswer("");
  };

  const sessionPauseHandler = () => {
    setSessionPaused(true);
    restart(Date.now());
    SpeechRecognition.stopListening();
  };

  return (
    <>
      <div id="startSession1">
        <AudioPlayer
          style={{ display: "none" }}
          src={
            "https://demoplayground4093662547.z20.web.core.windows.net/us-en/Code001.mp3"
          }
        />
        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow-sm ">
                <div className="card-body p-4" id="submit-form">
                  {!completed ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Button
                          onClick={sessionPauseHandler}
                          variant="contained"
                        >
                          Pause
                        </Button>
                        <PauseModal
                          closeModal={() => {
                            setControlsVisible(true);
                            setSessionPaused(false);
                            setPatientAnswerBox(!patientAnswerBox);
                          }}
                          showModal={sessionPaused}
                        />
                      </div>
                      <div className="" style={{ minHeight: "76px" }}>
                        {instructions === "NULL" ? (
                          ""
                        ) : (
                          <>
                            <h5 className="mb-2 text-info">Instruction</h5>
                            <p
                              className="border border-info p-2 rounded"
                              id="chat1"
                            >
                              <div>{createBoldText(instructions)}</div>
                            </p>
                          </>
                        )}
                      </div>
                      <div className="">
                        <h5 className="mb-2 text-warning">Question</h5>
                        <div id="typewriter">
                          <p
                            id="text"
                            className="border border-warning p-2 rounded"
                          >
                            <Typewriter
                              onInit={(typewriter) => {
                                if (question !== "") {
                                  typewriter.typeString(question).start();
                                }
                              }}
                              key={question}
                              options={{
                                delay: 8,
                                cursor: "",
                              }}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-10">
                          <div className="">
                            <h5 className="mb-2 text-primary">
                              Answer
                              <small>
                                <span id="speakshow">
                                  (Try to Speak out your response)
                                  {listening ? (
                                    <span>
                                      <i class="fa fa-circle text-danger Blink"></i>
                                      Listening
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>

                                <span id="textshow" style={{ display: "none" }}>
                                  (Type your response below)
                                </span>
                              </small>
                            </h5>
                            <div
                              style={{ minHeight: "50px" }}
                              className="form-control border-primary p-2 rounded mb-0 bg-white"
                              id="chat3"
                              readOnly
                            >
                              <textarea
                                onChange={(e) =>
                                  setPatientAnswer(e.target.value)
                                }
                                disabled={
                                  inputMode === "VOICE" && !patientAnswerBox
                                }
                                ref={inputRef}
                                cols="120"
                                style={{ maxWidth: "100%" }}
                                rows={3}
                                className="patient-answer-box"
                                value={patientAnswer}
                              />
                            </div>
                            <div className="py-2 text-end">
                              <button
                                type="button"
                                className={`btn btn-success btn-sm`}
                                id="loader"
                                style={{
                                  display: submitInProcess ? "block" : "none",
                                }}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Processing...
                              </button>
                              <div
                                className={`animated ${controlsVisible ? "expanded" : ""
                                  }`}
                              >
                                <button
                                  type="button"
                                  style={{ marginRight: 3 }}
                                  className="btn btn-primary btn-sm"
                                  id="editButton"
                                  onClick={editButtonHandler}
                                >
                                  Edit
                                </button>
                                <button
                                  style={{ marginRight: 3 }}
                                  onClick={() => submitQuestionForcefully()}
                                  // disabled={isTimerRunning}
                                  type="button"
                                  className="btn btn-success btn-sm"
                                  id="submit-button"
                                >
                                  Submit
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={skipButtonHandler}
                                >
                                  Skip
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <h5 className="mb-2 text-dark">Timer</h5>
                          <div
                            className="text-center shadow-sm pb-2 border-dark border rounded"
                            style={{ minHeight: "96px" }}
                          >
                            <div id="timer" className="fw-bold lead">
                              {seconds}
                            </div>
                            <div className="d-flex align-items-center justify-content-evenly pt-1">
                              <button
                                id="startButton"
                                style={{
                                  display:
                                    listenerState === "ENDED" &&
                                      patientAnswer === ""
                                      ? "block"
                                      : "none",
                                }}
                                className="py-0 btn btn-sm btn-primary"
                              >
                                <i className="fa-solid fa-play"></i>
                              </button>
                              <button
                                id="stopButton"
                                className="py-0 btn btn-sm btn-danger"
                                style={{
                                  display:
                                    listenerState === "STARTED"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <i className="fa-solid fa-stop"></i>
                              </button>
                              <button
                                id="addTimeButton"
                                className="py-0 btn btn-sm btn-dark"
                                style={{ display: "none" }}
                              >
                                <i className="fa-solid fa-stopwatch-20"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Typography align="center">
                        Your Session has been completed. Thank you
                      </Typography>
                    </>
                  )}
                </div>
              </div>
              <div className="card shadow-sm ">
                <div className="card-body">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-md-12">
                      <div className="row mb-2">
                        <div className="col-12">
                          {(!completed && progressBar > 0)&& (
                            <div className="progress" style={{ height: "4px" }}>
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                aria-valuenow="16"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: `${progressBar}%` }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      {completed && sessionType === sessionTypes.TRIAL ? (
                        <div style={{ marginTop: "10px" }}>
                          <SessionReportTable detectedDisorders={final} />
                        </div>
                      ) : completed && sessionType !== sessionTypes.TRIAL ? (
                        <PaidSessionReport
                          noFinalData={noFinalData}
                          final={final}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function MyTimer({ expiryTimestamp }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("Timer Ended"),
  });

  return {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  };
}
