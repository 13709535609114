import React, { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PlansConfig from "./PlansConfig/PlansConfig";
import Coupon from "./Coupons/Coupon";
import DiscountIcon from "@mui/icons-material/Discount";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
const PlansConfigTabs = () => {
  const [activeTab, setActiveTab] = useState("PlansConfig");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <BottomNavigation
        sx={{ mt: 6, mb: 3, borderBottom: "1px solid grey", flex: "0 0 auto" }}
        showLabels
        value={activeTab}
        onChange={handleTabChange}
      >
        <BottomNavigationAction
          value="PlansConfig"
          label="PlansConfig"
          icon={<NoteAltIcon />}
        />
        <BottomNavigationAction
          value="Coupon"
          label="Coupon"
          icon={<DiscountIcon />}
        />
      </BottomNavigation>
      <div style={{ overflowY: "auto", flex: 1 }}>
        {activeTab === "PlansConfig" && <PlansConfig activeTab={activeTab} />}
        {activeTab === "Coupon" && <Coupon activeTab={activeTab} />}
      </div>
    </>
  );
};

export default PlansConfigTabs;