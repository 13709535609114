import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { MediaService } from "../../../../../services";
import { showSuccessMessage } from "../../../../../managers/utility";

const MediaModal = ({
  isMediaModalOpen,
  refetch,
  selectedItem,
  setIsMediaModalOpen,
}) => {
  const [inputData, setInputData] = useState({
    thumbnail: "",
    title: "",
    url: "",
  });


  useEffect(() => {
    if (selectedItem) {
      setInputData(selectedItem);
    } else {
      setInputData({
        thumbnail: "",
        title: "",
        url: "",
      });
    }
  }, [selectedItem]);



  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const requestData = { ...inputData };
      const res = selectedItem
        ? await MediaService.updateMediaCategory(requestData)
        : await MediaService.addMediaCategory(requestData);

      if (res._id) {
        showSuccessMessage(
          selectedItem ? "Updated Successfully" : "Saved Successfully"
        );
        setIsMediaModalOpen(false);
        refetch();
      }
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle error here
    }
  };

  const handleClose = () => {
    setIsMediaModalOpen(false);
    setInputData({
      thumbnail: "",
      title: "",
      url: "",
    });
  };

  return (
    <Modal
      BackdropProps={{
        onClick: null, // Disable backdrop click
      }}
      open={isMediaModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title-lots"
      aria-describedby="modal-modal-description-lots"
    >
      <Box
        sx={{
          maxHeight: 500,
          overflow: "scroll",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 900,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "3px dotted cfcfcf",
          }}
        >
          <Typography id="modal-modal-title-lots" variant="h6" component="h2">
            Add
          </Typography>
          <Button onClick={handleSubmit}>Save</Button>
          <Button
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </div>

        <TextField
          label="Category Thumbnail URL"
          variant="outlined"
          value={inputData.thumbnail}
          onChange={handleChange}
          name="thumbnail"
          fullWidth
          margin="normal"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Videos Thumbnail URL"
          variant="outlined"
          value={inputData.thumbnailForVideo}
          onChange={handleChange}
          name="thumbnailForVideo"
          fullWidth
          margin="normal"
          sx={{ mt: 2 }}
        />

        <TextField
          label="Title"
          variant="outlined"
          value={inputData.title}
          onChange={handleChange}
          name="title"
          fullWidth
          margin="normal"
          sx={{ mt: 2 }}
        />
        <TextField
          label="URL"
          variant="outlined"
          value={inputData.url}
          onChange={handleChange}
          name="url"
          fullWidth
          margin="normal"
          sx={{ mt: 2 }}
        />
      </Box>
    </Modal>
  );
};

export default MediaModal;
