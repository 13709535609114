import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";



// updateVersionService.js
const updateVersionService = {
    createVersion,
    getDataVersion,
    UpdateVersion
};

export default updateVersionService;

async function createVersion(requestData) {
    let url = process.env.REACT_APP_API_URI + "/api/private/create-update-version"
    return httpService(httpConstants.METHOD_TYPE.POST, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function getDataVersion(activeTab) {
    let url = process.env.REACT_APP_API_URI + `/api/private/get-update-version?platform=${activeTab}`
    return httpService(httpConstants.METHOD_TYPE.GET, getHeaders(),{}, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

async function UpdateVersion(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/update-app-version`
    return httpService(httpConstants.METHOD_TYPE.PUT, getHeaders(),requestData, url)
        .then(
            response => {
                 if (!response.success || response.responseCode !== 200 || !response.responseData)
                    return Promise.reject(response);
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}
