import { httpConstants } from "../constants";
import { httpService } from "../utility/httpService";
import { getHeaders } from "./baseFunctions";

const answerSessionServiceModule = {
    updateUserConsent,
};

export default answerSessionServiceModule;

async function updateUserConsent(requestData) {
    let url = process.env.REACT_APP_API_URI + `/api/private/session-share-user-consent`;
    return httpService(
        httpConstants.METHOD_TYPE.POST,
        getHeaders(),
        requestData,
        url
    )
        .then((response) => {
            if (
                !response.success ||
                response.responseCode !== 200 ||
                !response.responseData
            )
                return Promise.reject(response);
            return Promise.resolve(response.responseData);
        })
        .catch(function (err) {
            return Promise.reject(err);
        });
}
