import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { EntityService } from "../../../services";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../managers/utility";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../AuthContext";
import CustomDatePicker from "../../../common/DatePicker";

const CorporateModal = ({ isOpen, onClose, corporateId, getCorporateData }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState(null);

  const { userDetails, getUserDetails } = useAuth();

  useEffect(() => {
    getLicense();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLicense = async () => {
    const licenses = await EntityService.getLicense({
      entityId: userDetails._id,
    });
    setLicenses(licenses);
  };

  const handleAddCorporateEmployee = async () => {
    let isValid = true;

    if (!selectedLicense && userDetails.type === "CORPORATE") {
      showErrorMessage("Please Select a license to assign");
      return;
    }

    // Validate email
    if (!email.trim()) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate mobile number
    if (mobileNumber && !isValidMobileNumber(mobileNumber)) {
      setMobileNumberError("Invalid mobile number format");
      isValid = false;
    } else {
      setMobileNumberError("");
    }

    if (isValid) {
      try {
        const response = await EntityService.addEntityEmployee({
          name: name,
          email: email,
          mobileNumber: mobileNumber,
          entityId: corporateId,
          dateOfBirth: dob,
          gender: gender,
          licenseId: selectedLicense,
        });
        if (response._id) {
          showSuccessMessage("User Added Successfully");
          getUserDetails();
          setName("");
          setEmail("");
          setGender("");
          setDOB("");
          setMobileNumber("");
          onClose();
        } else {
          showErrorMessage(
            response.error || response.message || "Unable To Add"
          );
        }
      } catch (error) {
        console.error("Error adding corporate user:", error);
        showErrorMessage(error.message);
      }
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^[0-9]{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  // const handleCheckClick = async (e, data, license) => {
  //   if (isNaN(license)) {
  //     return;
  //   }
  //   const saved = await EntityService.addEntityLicense({
  //     entityId: data._id || "",
  //     numberOfLicense: parseInt(license),
  //   });
  //   if (saved && saved._id) {
  //     showSuccessMessage("Updates successfully");
  //   }
  // };

  console.log(dob, "dob");
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      BackdropProps={{
        onClick: onClose,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Add Member
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          name="name"
        />
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          margin="normal"
          name="email"
          error={!!emailError}
          helperText={emailError}
        />
        <TextField
          label="Mobile Number"
          type="text"
          fullWidth
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          margin="normal"
          name="mobileNumber"
          error={!!mobileNumberError}
          helperText={mobileNumberError}
          inputProps={{
            pattern: "[0-9]{10}", // Specify the pattern for 10-digit numeric input
            maxLength: 10, // Set the maximum length to 10 characters
          }}
        />

        <CustomDatePicker
          label="Date Of Birth"
          type="date"
          name="dateOfBirth"
          fullWidth
          value={dob ? dob : ""}
          onChange={(v) => {
            setDOB(v);
          }}
          style={{ marginTop: "14px" }}
        />

        <TextField
          id="outlined-basic-gender"
          label="Gender"
          variant="outlined"
          select
          name="gender"
          margin="normal"
          value={gender || ""}
          fullWidth
          onChange={(e) => setGender(e.target.value)}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>

        {userDetails.type === "CORPORATE" && (
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabel>Licenses</InputLabel>
            <Select
              name="selectedLicense"
              // value={formData}
              label="Licenses"
              onChange={(e) => setSelectedLicense(e.target.value)}
            >
              {licenses.map((license) => (
                <MenuItem key={license._id} value={license._id}>
                  {license.planId.plansType} - {license.planId.sessionCount}{" "}
                  Sessions
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCorporateEmployee}
          fullWidth
          style={{ marginTop: 10 }}
        >
          Add
        </Button>
      </Box>
    </Modal>
  );
};

export default CorporateModal;
