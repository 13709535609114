import { Button } from '@mui/material';
import React from 'react';
import { sessionTypes } from '../../../common/constants';
import { useNavigate } from 'react-router-dom';

export default function ReportDetailedData({
  description,
  selectedDisorder,
  sessionData,
  selectedSession,
  isModalOpen,
  isTrialModeOn,
  handleDisorderClick,
  DisorderModalComponent,
  setIsModalOpen,
  setDescription,
  setSelectedDisorder,
}) {
  const navigate = useNavigate();
  return (
    <div id="brief-report-area-to-print">
      <div
        style={{
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <img
          alt="filler"
          src="/images/aipsychi.png"
          className="img-fluid"
          style={{
            // width: '20px',
            height: '38px',
            marginTop: '20px',
          }}
        />
      </div>
      {/* <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 5, marginBottom: 5 }}>This report is for assisting mental health professional</div> */}
      Dear {sessionData?.userId?.name}, Based on Your Answers, following are the detected tendencies for various disorders,
      <div
        style={{
          color: 'green',
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        Note: There may be instances where your answers were inconclusive, or a question was skipped. In such cases, informed inferences
        were made with prudence.
      </div>
      <span>Tendencies</span>
      <br />
      {selectedSession && selectedSession.sessionType && selectedSession.sessionType === sessionTypes.PAID && (
        <span>
          To gain further insight into the specifics of a disorder for which you're being assessed to have{' '}
          {selectedSession && selectedSession.isFreeSession ? 'indicators' : 'tendencies'},
          <div>
            <b>Please click the relevant disorder below.</b>
          </div>
        </span>
      )}
      <ul style={{ marginLeft: 15 }}>
        {sessionData.final.map((disorder, index) => {
          if (!disorder.includes('SEC')) {
            if (isTrialModeOn(disorder))
              return (
                <div>
                  <Button
                    variant="outlined"
                    sx={{
                      mt: 1,
                      mb: 1,
                      pointerEvents: 'none',
                      cursor: 'default !important',
                    }}
                  >
                    {disorder}
                  </Button>
                </div>
              );
            else
              return (
                <div>
                  <Button
                    variant="outlined"
                    sx={{
                      mt: 1,
                      mb: 1,
                    }}
                    onClick={() => handleDisorderClick(disorder)}
                  >
                    {disorder}
                  </Button>
                </div>
              );
          } else return '';
        })}
      </ul>
      <div style={{ marginTop: 16 }}>
        <b>Disclaimer</b>: AI-Based Mental Health Assessment
        <br />
        <br />
        This assessment of tendencies is powered by artificial intelligence (AI) and is for informational purposes only. It does not replace
        professional medical advice, diagnosis, or treatment. The results should not be considered a definitive diagnosis, and individuals
        are encouraged to consult qualified healthcare professionals for personalized evaluation and guidance. For limitations and user
        responsibilities, please read your report&nbsp;
        <a
          style={{
            color: 'blue',
            cursor: 'pointer',
            marginLeft: '2px',
          }}
          onClick={() => navigate('/dashboard/reports')}
          href="/dashboard/reports"
        >
          Here
        </a>
        <br />
        <br />
        You are entitled to our self-help app, available at no cost for the specified duration in your plan. Download the app from the&nbsp;
        <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.aipsychi">
          Play Store
        </a>
        &nbsp; or&nbsp;
        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/in/app/aipsychi/id6478376096">
          App Store
        </a>
        &nbsp; for a healthier life.
      </div>
      <DisorderModalComponent
        disorder={selectedDisorder}
        isOpen={isModalOpen}
        description={description}
        onClose={() => {
          setIsModalOpen(false);
          setDescription(null);
          setSelectedDisorder(null);
        }}
      />
    </div>
  );
}
