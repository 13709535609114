import React, { useState, useEffect } from "react";
import {
  Button,
  MenuItem,
  TextField,
  Modal,
  Box,
  Typography,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../../../managers/utility";
import updateVersionService from "../../../../services/updateVersionService";
import CloseIcon from "@mui/icons-material/Close";

export default function UpdateVersionModal({
  open,
  onClose,
  title,
  selectedItem,
  getTable,
}) {
  const [updateName, setUpdateName] = useState("");
  const [updateVersion, setUpdateVersion] = useState("");
  const [updateType, setUpdateType] = useState("");
  const [updatePlatform, setUpdatePlatform] = useState("");
  const [releaseNote, setReleaseNote] = useState("");

  // Update state when selectedItem props change
  useEffect(() => {
    if (selectedItem) {
      setUpdateName(selectedItem.name || "");
      setUpdateVersion(selectedItem.version || "");
      setUpdateType(selectedItem.type || "");
      setUpdatePlatform(selectedItem.platform || "");
      setReleaseNote(selectedItem.releaseNote || "");
    }
  }, [selectedItem]);

  const handleUpdateVersion = async () => {
    try {
      const requestData = {
        _id: selectedItem._id,
        name: updateName,
        version: updateVersion,
        type: updateType,
        releaseNote: releaseNote,
        platform:updatePlatform
      };

      const response = await updateVersionService.UpdateVersion(requestData);
      if (response && response.name) {
        showSuccessMessage("Version updated successfully");
        onClose();
        getTable(); // Assuming this function fetches the updated data
      } else {
        showErrorMessage("Unexpected error updating version.");
      }
    } catch (error) {
      console.error("Error updating version:", error);
    }
    setUpdateName("");
    setUpdateVersion("");
    setUpdateType("");
    setUpdatePlatform("");
    setReleaseNote("");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
         <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" gutterBottom>
             {title}
            </Typography>
            <Button
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="update-name"
              label="Name"
              value={updateName}
              onChange={(e) => setUpdateName(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="update-version"
              label="Version"
              value={updateVersion}
              onChange={(e) => setUpdateVersion(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="update-type"
              label="Type"
              select
              value={updateType}
              onChange={(e) => setUpdateType(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              <MenuItem value="Forced">Forced</MenuItem>
              <MenuItem value="Normal">Normal</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
              <TextField
                id="update-type"
                label="Platform"
                select
                value={updatePlatform}
                onChange={(e) => setUpdatePlatform(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <MenuItem value="ANDROID">ANDROID</MenuItem>
                <MenuItem value="IOS">IOS</MenuItem>
                <MenuItem value="BACKEND">BACKEND</MenuItem>
              </TextField>
            </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              id="outlined-basic-age"
              aria-label="minimum height"
              rowsMin={3}
              placeholder="Release Note"
              value={releaseNote}
              onChange={(e) => setReleaseNote(e.target.value)}
              fullWidth
              style={{
                marginTop: 16,
                width: "100%",
                resize: "none",
                padding: 10,
                border: "1px solid #ccc",
                borderRadius: 4,
              }}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={handleUpdateVersion}
          fullWidth
          style={{ marginTop: 16,marginBottom:16 }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
}
