import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Box,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { useForm } from "react-hook-form";
import CustomDatePicker from "../../../common/DatePicker";


export default function SignUp({
  setPassword,
  setName,
  setGender,
  gender,
  setDOB,
  dob,
  onClose,
  showPin,
  setEmail,
  handleToggleVisibility,
  signUp,
  changeState,
  setMobile,
  mobile,
  name,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [isHovered, setHovered] = useState(false);

  const onSubmit = (data) => {
    // Your sign-up logic here
    signUp(data);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const boxShadow = isHovered ? "0 0 10px rgba(0, 0, 0, 0.3)" : "none";

  return (
    <form
      style={{
        background: "#fff",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "600px",
        width: "100%",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            color: "#1877f2",
            textAlign: "center",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          Sign Up
        </h3>
        <ClearIcon color="primary" onClick={onClose} />
      </Box>

      <Typography variant="h5" gutterBottom>
        <IconButton
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={onClose}
        ></IconButton>
      </Typography>

      <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20 }}>
        <Grid item xs={12} md={6} padding={0}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            {...register("name", { required: "Name is required" })}
            value={name}
            error={errors.name}
            helperText={errors.name?.message}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} padding={0}>
          <TextField
            id="outlined-basic-gender"
            label="Gender"
            variant="outlined"
            select
            name="gender"
            error={Boolean(errors.gender)}
            helperText={errors.gender?.message || " "}
            value={gender || ""}
            fullWidth
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                { display: "none" },
              "& input[type=number]": { MozAppearance: "textfield" },
            }}
            label="Mobile"
            type="number"
            error={errors.mobile}
            helperText={errors.mobile?.message}
            fullWidth
            {...register("mobile", {
              required: "Mobile is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Invalid mobile number",
              },
            })}
            value={mobile}
            onChange={(e) => {
              const cleanedMobile = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
              const truncatedMobile = cleanedMobile.slice(0, 10); // Limit to 10 digits
              setMobile(truncatedMobile);
              setValue("mobile", truncatedMobile);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email"
            type="email"
            error={errors.email}
            helperText={errors.email?.message}
            fullWidth
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            })}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <CustomDatePicker label="Date of Birth" placeholder="Date of Birth" onChange={(v) => setDOB(v)} value={dob} />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ mb: 3 }}
            label="Password"
            error={errors.password}
            helperText={errors.password?.message}
            type={showPin ? "text" : "password"}
            fullWidth
            {...register("password", {
              required: "Password is required",
              pattern: {
                value:
                  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 
                  // Faulty Regex Above 
                  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                message:
                  "Invalid password. It must have at least 8 characters including 1 number and 1 special character.",
              },
            })}
            onChange={(e) => {
              setPassword(e.target.value);
              setValue("password", e.target.value); // Update 'password' value in react-hook-form
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleToggleVisibility} edge="end">
                  {showPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: "100%", height: "50px", fontSize: "15px" }}
          >
            Sign Up
          </Button>
        </Grid>
        <Grid item xs={4} md={4}></Grid>
        <Grid item xs={4} md={4}>
          <div
            style={{
              color: "#1877f2",
              fontSize: "18px",
              fontWeight: "500",
              cursor: "pointer",
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              transition: "box-shadow 0.3s ease-in-out",
              boxShadow: boxShadow,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={changeState}
          >
            Sign In
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
