import React, { useState, useEffect } from "react";
import CustomLoader from "../../../../common/customLoader";
import { Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import moment from "moment/moment";
import CouponModal from "./CouponModal";
import { CouponService } from "../../../../services";

export default function Coupon() {
  const [allCouponData, setAllCouponData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isData, setIsData] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [deletedItems, setDeletedItems] = useState({}); // State to track deleted items

  const handleAddCoupon = () => {
    setCreateMode(true);
    setOpen(true);
    setIsEditable(true);
  };

  const handleEditCoupon = (index) => {
    setIsEditable(true);
  };

  const openCouponModal = (index) => {
    setSelectedCoupon(allCouponData[index]);
    setOpen(true);
  };

  useEffect(() => {}, [selectedCoupon]);

  const closeCouponModal = () => {
    setOpen(false);
    setSelectedCoupon(null);
    setIsEditable(false);
  };

  const handleDeleteClick = async (id) => {
    // Toggle the delete state of the clicked item
    setDeletedItems((prevDeletedItems) => ({
      ...prevDeletedItems,
      [id]: !prevDeletedItems[id],
    }));
    let requestData = {
      _id: id,
      isDeleted: !deletedItems[id],
    };
    await CouponService.deleteoupons(requestData);
    getAllCoupons();
  };

  const getAllCoupons = async () => {
    try {
      const allCoupons = await CouponService.getCoupons();
      if (allCoupons && allCoupons.length > 0) {
        setAllCouponData(allCoupons);
        setIsData(true);
        setDataNotFound(false);
      } else {
        setIsData(false);
        setDataNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching Coupons:", error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllCoupons();
  }, []);

  return (
    <>
      {isLoading && (
        <div style={{ textAlign: "center" }}>
          <CustomLoader />
        </div>
      )}
      {dataNotFound && (
        <>
          <div style={{ width: "150px" }}>
            <Button variant="contained" onClick={handleAddCoupon}>
              Add
            </Button>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography>No data found</Typography>
          </div>
        </>
      )}

      {isData && (
        <>
          <div style={{ width: "150px" }}>
            <Button variant="contained" onClick={handleAddCoupon}>
              Add
            </Button>
          </div>
          <TableBody style={{ width: "100%" }}>
            <TableRow style={{ width: "100%" }}>
              <TableCell style={{ width: "160px" }}>Name</TableCell>
              <TableCell style={{ width: "150px" }}>Coupon Code</TableCell>
              <TableCell style={{ width: "90px" }} align="center">
                Discount
              </TableCell>
              <TableCell style={{ width: "200px" }} align="center">
                Expiry Date
              </TableCell>
              <TableCell style={{ width: "200px" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ width: "160px" }} align="center">
                Updated At
              </TableCell>

              <TableCell style={{ width: "220px" }} align="center">
                Actions
              </TableCell>
            </TableRow>
            {allCouponData.map((item, index) => (
              <TableRow style={{ width: "100%" }} key={item._id}>
                <TableCell style={{ width: "160px" }}>{item?.name}</TableCell>
                <TableCell style={{ width: "150px" }}>
                  {item?.couponCode}
                </TableCell>
                <TableCell style={{ width: "90px" }} align="center">
                  {item?.discount}
                </TableCell>
                <TableCell style={{ width: "200px" }} align="center">
                  {moment(item?.expiryDate).format("Do MMMM YYYY")}
                </TableCell>
                <TableCell style={{ width: "160px" }} align="center">
                  {moment(item?.createdAt).format("Do MMMM YYYY")}
                </TableCell>
                <TableCell style={{ width: "200px" }} align="center">
                  {moment(item?.updateAt).format("Do MMMM YYYY")}
                </TableCell>
                <TableCell style={{ width: "220px" }} align="center">
                  <Button
                    onClick={() => openCouponModal(index)}
                    variant="contained"
                  >
                    Edit
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    onClick={() => handleDeleteClick(item._id)}
                    aria-label="Disabled-enabled"
                    color="secondary"
                    variant="outlined"
                  >
                    {deletedItems[item._id] ? "Enabled" : "Disabled"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
      {(createMode || selectedCoupon) && (
        <CouponModal
          open={open}
          closeCouponModal={closeCouponModal}
          Coupon={selectedCoupon}
          isEditable={isEditable}
          createMode={createMode}
          handleAddCoupon={handleAddCoupon}
          handleEditCoupon={handleEditCoupon}
          setSelectedCoupon={setSelectedCoupon}
          getAllCoupons={getAllCoupons}
        />
      )}
    </>
  );
}