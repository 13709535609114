import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledBackdrop = styled(Backdrop)`
backdrop-filter: blur(3px);
`;

export default function AlertDialogSlide({ showModal, closeModal }) {


  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        BackdropComponent={StyledBackdrop} // Use the styled backdrop
        BackdropProps={{
          sx: { backgroundColor: 'rgba(255, 255, 255, 0.5)' }, // Adjust the background color and opacity
        }}
        maxWidth={"lg"}
      >
        <DialogTitle>{"Paused"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", padding :"0 30px 0 30px"}}>

            <img alt="Back" style={{width:"100px" , marginBottom:"20px"}} src="/images/paused.png" />
            <div>Your session has been paused. <br></br> Click Resume to continue session</div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeModal}>Resume</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


