import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

const Plans = ({ icon, heading, content, buttonName, functionName }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div 
      style={{ margin: "20px 8px 8px 8px" }}
      >
        <Card
          sx={{
            borderRadius: 5,
            boxShadow: "0px 10px 8px 0px rgba(0, 0, 0.1, 0.3)",
            overflow: "hidden",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          <CardMedia
            sx={{
              height: 60,
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
            }}
          >
            <IconButton>{icon}</IconButton>
          </CardMedia>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography gutterBottom sx={{fontSize:14, fontWeight: "bold" }}>
              {heading}
            </Typography>
            <Typography
              color="text.secondary"
              style={{ textAlign: "center",fontSize:13,  }}
            >
              {content}
            </Typography>
          </div>
          <CardActions style={{ justifyContent: "center" }}>
            <Button
              size="small"
              sx={{
                fontWeight: "600",
                fontSize: 10,
                width: "100%",
                marginBottom:1,
                marginTop:1,
                backgroundColor: "orange",
                color: "white",
                "&:hover": {
                  backgroundColor: "darkorange",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0.1, 0.3)",
                },
              }}
              onClick={functionName} // Call the function on button click
            >
              {buttonName}
            </Button>
          </CardActions>
        </Card>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* Your modal content goes here */}
        <div>
        </div>
      </Modal>
    </div>
  );
};

export default Plans;
